<template>
  <!-- Modal -->
  <div id="modal" class="modal show" tabindex="-1" role="dialog">
    <!-- Modal: Nowe EDI -->
    <div v-if="modals[0]" class="modal-dialog">
      <div class="modal-content">
        <div class="card px-2 py-1">
          <div class="card-header pb-1" :class="isDarkMode ? `bg-black text-light` : `bg-white text-dark`">
            <div class="d-flex align-items-center justify-content-between">
              <h5 class="my-auto">Nowe EDI</h5>
              <span class="my-auto">
                <i class="material-symbols-outlined text-lg cursor-pointer" @click="closeModal()">close</i>
              </span>
            </div>
          </div>
          <div class="card-body pt-1" :class="isDarkMode ? `bg-black` : `bg-white`">
            <div class="row mb-2">
              <div class="col-12">
                <label class="form-label ms-0 text-xs">Nazwa</label>
                <input
                  v-model="ediImport.name"
                  class="w-100 px-3 py-2 text-sm"
                  :class="isDarkMode ? 'text-white' : 'bg-light text-dark'"
                  style="border: none; border-radius: 10px"
                  placeholder="Nazwa"
                />
              </div>
            </div>

            <div class="row mb-2">
              <div class="col-12">
                <label class="form-label ms-0 text-xs">
                  Slug <span class="text-xxs">(potrzebny, kiedy EDI jest pobierany z zewnętrznego kontrolera)</span>
                </label>
                <input
                  v-model="ediImport.slugId"
                  class="w-100 px-3 py-2 text-sm"
                  :class="isDarkMode ? 'text-white' : 'bg-light text-dark'"
                  style="border: none; border-radius: 10px"
                  placeholder="Slug"
                />
              </div>
            </div>

            <div class="row mb-2">
              <div class="col-12">
                <label class="form-label ms-0 text-xs">Typ importu</label>
                <select
                  v-model="ediImport.ediTypeImport"
                  class="w-100 p-2 text-sm"
                  :class="isDarkMode ? 'text-white' : 'bg-light text-dark'"
                  style="border: none; border-radius: 10px"
                >
                  <option value="">Wybierz...</option>
                  <option v-for="item in importTypes" :key="item" :value="`/edi_type_imports/${ item.id }`">{{ item.name }}</option>
                </select>
              </div>
            </div>

            <div class="row mb-2">
              <div class="col-12">
                <label class="form-label ms-0 text-xs">Typ exportu</label>
                <select
                  v-model="ediImport.ediTypeExport"
                  class="w-100 p-2 text-sm"
                  :class="isDarkMode ? 'text-white' : 'bg-light text-dark'"
                  style="border: none; border-radius: 10px"
                >
                  <option value="">Wybierz...</option>
                  <option v-for="item in exportTypes" :key="item" :value="`/edi_type_exports/${ item.id }`">{{ item.name }}</option>
                </select>
              </div>
            </div>
            
            <div class="d-flex justify-content-end mt-3">
              <material-button
                :color="color"
                variant="gradient"
                @click="postImport()"
              >
                Stwórz
              </material-button>

              <material-button
                class="ms-3"
                color="light"
                variant="gradient"
                @click="closeModal()"
              >
                Anuluj
              </material-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal: Edycja EDI -->
    <div v-if="modals[1]" class="modal-dialog">
      <div class="modal-content">
        <div class="card px-2 py-1">
          <div class="card-header pb-1" :class="isDarkMode ? `bg-black text-light` : `bg-white text-dark`">
            <div class="d-flex align-items-center justify-content-between">
              <h5 class="my-auto">Edycja EDI</h5>
              <span class="my-auto">
                <i class="material-symbols-outlined text-lg cursor-pointer" @click="closeModal()">close</i>
              </span>
            </div>
          </div>
          <div class="card-body pt-1" :class="isDarkMode ? `bg-black` : `bg-white`">
            <div class="row mb-2">
              <div class="col-12">
                <label class="form-label ms-0 text-xs">Nazwa</label>
                <input
                  v-model="ediImport.name"
                  class="w-100 px-3 py-2 text-sm"
                  :class="isDarkMode ? 'text-white' : 'bg-light text-dark'"
                  style="border: none; border-radius: 10px"
                  placeholder="Nazwa"
                />
              </div>
            </div>

            <div class="row mb-2">
              <div class="col-12">
                <label class="form-label ms-0 text-xs">
                  Slug <span class="text-xxs">(potrzebny, kiedy EDI jest pobierany z zewnętrznego kontrolera)</span>
                </label>
                <input
                  v-model="ediImport.slugId"
                  class="w-100 px-3 py-2 text-sm"
                  :class="isDarkMode ? 'text-white' : 'bg-light text-dark'"
                  style="border: none; border-radius: 10px"
                  placeholder="Slug"
                />
              </div>
            </div>

            <div class="row mb-2">
              <div class="col-12">
                <label class="form-label ms-0 text-xs">Typ importu</label>
                <select
                  v-model="ediImport.ediTypeImport"
                  class="w-100 p-2 text-sm"
                  :class="isDarkMode ? 'text-white' : 'bg-light text-dark'"
                  style="border: none; border-radius: 10px"
                >
                  <option value="">Wybierz...</option>
                  <option v-for="item in importTypes" :key="item" :value="`/edi_type_imports/${ item.id }`">{{ item.name }}</option>
                </select>
              </div>
            </div>

            <div class="row mb-2">
              <div class="col-12">
                <label class="form-label ms-0 text-xs">Typ exportu</label>
                <select
                  v-model="ediImport.ediTypeExport"
                  class="w-100 p-2 text-sm"
                  :class="isDarkMode ? 'text-white' : 'bg-light text-dark'"
                  style="border: none; border-radius: 10px"
                >
                  <option value="">Wybierz...</option>
                  <option v-for="item in exportTypes" :key="item" :value="`/edi_type_exports/${ item.id }`">{{ item.name }}</option>
                </select>
              </div>
            </div>
            
            <div class="d-flex justify-content-end mt-3">
              <material-button
                :color="color"
                variant="gradient"
                @click="patchImport()"
              >
                Zapisz
              </material-button>

              <material-button
                class="ms-3"
                color="light"
                variant="gradient"
                @click="closeModal()"
              >
                Anuluj
              </material-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal: Podgląd EDI -->
    <div v-if="modals[2]" class="modal-dialog">
      <div class="modal-content">
        <div class="card px-2 py-1">
          <div class="card-header pb-1" :class="isDarkMode ? `bg-black text-light` : `bg-white text-dark`">
            <div class="d-flex align-items-center justify-content-between">
              <h5 class="my-auto">{{ ediImport.name }}</h5>
              <span class="my-auto">
                <i class="material-symbols-outlined text-lg cursor-pointer" @click="closeModal()">close</i>
              </span>
            </div>
          </div>
          <div class="card-body pt-1" :class="isDarkMode ? 'bg-black' : 'bg-white'">
            <div class="row mb-1">
              <div class="col-12">
                <label class="form-label ms-0 mb-0 font-weight-bold text-sm">
                  Import URL: <span class="font-weight-normal ms-2 cursor-pointer" @click="copyText(`${ getGlobalURL() }import/${ ediImport.id }`)">{{ `${ getGlobalURL() }import/${ ediImport.id }` }}</span>
                </label>
              </div>
            </div>

            <div class="row mb-1">
              <div class="col-12">
                <label class="form-label ms-0 mb-0 font-weight-bold text-sm">
                  Export URL: <span class="font-weight-normal ms-2 cursor-pointer" @click="copyText(`${ getGlobalURL() }export/${ ediImport.id }`)">{{ `${ getGlobalURL() }export/${ ediImport.id }` }}</span>
                </label>
              </div>
            </div>

            <div class="row mb-1">
              <div class="col-12">
                <label class="form-label ms-0 mb-0 font-weight-bold text-sm">
                  Import/Export URL: <span class="font-weight-normal ms-2 cursor-pointer" @click="copyText(`${ getGlobalURL() }import_export/${ ediImport.id }`)">{{ `${ getGlobalURL() }import_export/${ ediImport.id }` }}</span>
                </label>
              </div>
            </div>

            <div class="row mb-1">
              <div class="col-12">
                <label class="form-label ms-0 mb-0 font-weight-bold text-sm">
                  Typ importu: <span class="font-weight-normal ms-2">{{ ediImport.ediImportType ? ediImport.ediImportType.name : "Brak" }}</span>
                </label>
              </div>
            </div>

            <div class="row mb-1">
              <div class="col-12">
                <label class="form-label ms-0 mb-0 font-weight-bold text-sm">
                  Data importu: <span class="font-weight-normal ms-2">{{ ediImport.importAt ? ediImport.importAt : "Brak" }}</span>
                </label>
              </div>
            </div>

            <div class="row mb-1">
              <div class="col-12">
                <label class="form-label ms-0 mb-0 font-weight-bold text-sm">
                  Typ exportu: <span class="font-weight-normal ms-2">{{ ediImport.ediExportType ? ediImport.ediExportType.name : "Brak" }}</span>
                </label>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <label class="form-label ms-0 mb-0 font-weight-bold text-sm">
                  Data exportu: <span class="font-weight-normal ms-2">{{ ediImport.exportAt ? ediImport.exportAt : "Brak" }}</span>
                </label>
              </div>
            </div>

            <div class="d-flex justify-content-end mt-3">
              <material-button
                :color="color"
                variant="gradient"
                @click="getEdiImport(ediImport.id, 1)"
              >
                Edycja
              </material-button>

              <material-button
                class="ms-3"
                color="light"
                variant="gradient"
                @click="closeModal()"
              >
                Anuluj
              </material-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal: Import -->
    <div v-if="modals[3]" class="modal-dialog">
      <div class="modal-content">
        <div class="card px-2 py-1 mb-3">
          <div class="card-header pb-1" :class="isDarkMode ? `bg-black text-light` : `bg-white text-dark`">
            <div class="d-flex align-items-center justify-content-between">
              <h5 class="my-auto">Import</h5>
              <span class="my-auto">
                <i class="material-symbols-outlined text-lg cursor-pointer" @click="closeModal()">close</i>
              </span>
            </div>
          </div>
          <div class="card-body pt-1" :class="isDarkMode ? `bg-black` : `bg-white`">
            <span class="text-sm">
              Import: <strong>{{ ediImport.name }}</strong>
            </span>

            <div v-if="ediImport.ediImportType.customUrl" class="w-100 mt-2 mb-1">
              <input
                v-model="customUrl"
                class="w-100 px-3 py-2 text-sm"
                :class="isDarkMode ? 'text-white' : 'bg-light text-dark'"
                style="border: none; border-radius: 10px"
                placeholder="Custom URL"
              />
            </div>

            <div class="d-flex align-items-center justify-content-between mt-3">
              <div class="d-flex align-items-center">
                <i v-if="state && state === 'done'" class="material-symbols-outlined text-success text-md">check</i>
                <i v-if="state && state === 'error'" class="material-symbols-outlined text-danger text-md">close</i>
                <div v-if="state && state === 'load'" class="loader"></div>
              </div>

              <div class="d-flex align-items-center justify-content-end">
                <material-button
                  v-if="!state || state === 'error' || state === 'done'"
                  :color="color"
                  variant="gradient"
                  @click="getImport(ediImport.id)"
                >
                  Import
                </material-button>
                
                <material-button
                  class="ms-3"
                  color="light"
                  variant="gradient"
                  @click="closeModal()"
                >
                  Anuluj
                </material-button>
              </div>
            </div>
          </div>
        </div>

        <div class="card px-2 py-1">
          <div class="card-header pb-1" :class="isDarkMode ? `bg-black text-light` : `bg-white text-dark`">
            <div class="d-flex align-items-center justify-content-between">
              <h5 class="my-auto">Logi</h5>
              <span class="my-auto">
                <i class="material-symbols-outlined text-lg cursor-pointer" @click="getLogs(ediImport.id)">replay</i>
              </span>
            </div>
          </div>
          <div class="card-body pt-1" :class="isDarkMode ? 'bg-black' : 'bg-white'">
            <div v-if="logs.length === 0" class="w-100 text-sm">Brak logów</div>
            <div v-if="logs.length > 0" class="w-100 overflow-auto" style="max-height: 500px">
              <p
                v-for="(item, index) in logs"
                :key="item"
                class="my-1 text-sm"
                :class="item.type === 'Error' ? 'text-danger' : ''"
              >
                {{ (index + 1) + ". " + JSON.stringify(item, null, 2) }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal: Export -->
    <div v-if="modals[4]" class="modal-dialog">
      <div class="modal-content">
        <div class="card px-2 py-1 mb-3">
          <div class="card-header pb-1" :class="isDarkMode ? `bg-black text-light` : `bg-white text-dark`">
            <div class="d-flex align-items-center justify-content-between">
              <h5 class="my-auto">Export</h5>
              <span class="my-auto">
                <i class="material-symbols-outlined text-lg cursor-pointer" @click="closeModal()">close</i>
              </span>
            </div>
          </div>
          <div class="card-body pt-1" :class="isDarkMode ? `bg-black` : `bg-white`">
            <span class="text-sm ">
              Import: <strong>{{ ediImport.name }}</strong>
            </span>

            <div v-if="ediImport.ediExportType.customUrl" class="w-100 mt-2 mb-1">
              <input
                v-model="customUrl"
                class="w-100 px-3 py-2 text-sm"
                :class="isDarkMode ? 'text-white' : 'bg-light text-dark'"
                style="border: none; border-radius: 10px"
                placeholder="Custom URL"
              />
            </div>

            <material-button
              class="w-100 mt-2 px-3 py-2"
              :color="color"
              variant="gradient"
              @click="checkExport(ediImport.id)"
            >
              Sprawdź export
            </material-button>

            <div v-if="check && response.length > 0" class="w-100 my-3 px-2 text-sm overflow-auto" style="max-height: 500px">
              <ol>
                <li v-for="item in response" :key="item">{{ item }}</li>
              </ol>
            </div>

            <div class="d-flex align-items-center justify-content-between mt-3">
              <div class="d-flex align-items-center">
                <i v-if="state && state === 'done'" class="material-symbols-outlined text-success text-md">check</i>
                <i v-if="state && state === 'error'" class="material-symbols-outlined text-danger text-md">close</i>
                <div v-if="state && state === 'load'" class="loader"></div>
              </div>

              <div class="d-flex align-items-center justify-content-end">
                <material-button
                  v-if="!state || state === 'error' || state === 'done'"
                  :color="color"
                  variant="gradient"
                  @click="getExport(ediImport.id)"
                >
                  Export
                </material-button>
                
                <material-button
                  class="ms-3"
                  color="light"
                  variant="gradient"
                  @click="closeModal()"
                >
                  Anuluj
                </material-button>
              </div>
            </div>
          </div>
        </div>

        <div class="card px-2 py-1">
          <div class="card-header pb-1" :class="isDarkMode ? `bg-black text-light` : `bg-white text-dark`">
            <div class="d-flex align-items-center justify-content-between">
              <h5 class="my-auto">Logi</h5>
              <span class="my-auto">
                <i class="material-symbols-outlined text-lg cursor-pointer" @click="getLogs(ediImport.id)">replay</i>
              </span>
            </div>
          </div>
          <div class="card-body pt-1" :class="isDarkMode ? 'bg-black' : 'bg-white'">
            <div v-if="logs.length === 0" class="w-100 text-sm">Brak logów</div>
            <div v-if="logs.length > 0" class="w-100 overflow-auto" style="max-height: 500px">
              <p
                v-for="(item, index) in logs"
                :key="item"
                class="my-1 text-sm"
                :class="item.type === 'Error' ? 'text-danger' : ''"
              >
                {{ (index + 1) + ". " + JSON.stringify(item, null, 2) }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal: Import/Export -->
    <div v-if="modals[5]" class="modal-dialog">
      <div class="modal-content">
        <div class="card px-2 py-1 mb-3">
          <div class="card-header pb-1" :class="isDarkMode ? `bg-black text-light` : `bg-white text-dark`">
            <div class="d-flex align-items-center justify-content-between">
              <h5 class="my-auto">Import/Export</h5>
              <span class="my-auto">
                <i class="material-symbols-outlined text-lg cursor-pointer" @click="closeModal()">close</i>
              </span>
            </div>
          </div>
          <div class="card-body pt-1" :class="isDarkMode ? `bg-black` : `bg-white`">
            <span class="text-sm ">
              Import: <strong>{{ ediImport.name }}</strong>
            </span>

            <div v-if="ediImport.ediImportType.customUrl || ediImport.ediExportType.customUrl" class="w-100 mt-2 mb-1">
              <input
                v-model="customUrl"
                class="w-100 px-3 py-2 text-sm"
                :class="isDarkMode ? 'text-white' : 'bg-light text-dark'"
                style="border: none; border-radius: 10px"
                placeholder="Custom URL"
              />
            </div>

            <div class="d-flex align-items-center justify-content-between mt-3">
              <div class="d-flex align-items-center">
                <i v-if="state && state === 'done'" class="material-symbols-outlined text-success text-md">check</i>
                <i v-if="state && state === 'error'" class="material-symbols-outlined text-danger text-md">close</i>
                <div v-if="state && state === 'load'" class="loader"></div>
              </div>

              <div class="d-flex align-items-center justify-content-end">
                <material-button
                  v-if="!state || state === 'error' || state === 'done'"
                  :color="color"
                  variant="gradient"
                  @click="getImportExport(ediImport.id)"
                >
                  Wykonaj import/export
                </material-button>
                
                <material-button
                  class="ms-3"
                  color="light"
                  variant="gradient"
                  @click="closeModal()"
                >
                  Anuluj
                </material-button>
              </div>
            </div>
          </div>
        </div>

        <div class="card px-2 py-1">
          <div class="card-header pb-1" :class="isDarkMode ? `bg-black text-light` : `bg-white text-dark`">
            <div class="d-flex align-items-center justify-content-between">
              <h5 class="my-auto">Logi</h5>
              <span class="my-auto">
                <i class="material-symbols-outlined text-lg cursor-pointer" @click="getLogs(ediImport.id)">replay</i>
              </span>
            </div>
          </div>
          <div class="card-body pt-1" :class="isDarkMode ? 'bg-black' : 'bg-white'">
            <div v-if="logs.length === 0" class="w-100 text-sm">Brak logów</div>
            <div v-if="logs.length > 0" class="w-100 overflow-auto" style="max-height: 500px">
              <p
                v-for="(item, index) in logs"
                :key="item"
                class="my-1 text-sm"
                :class="item.type === 'Error' ? 'text-danger' : ''"
              >
                {{ (index + 1) + ". " + JSON.stringify(item, null, 2) }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal: Logi -->
    <div v-if="modals[6]" class="modal-dialog">
      <div class="modal-content">
        <div class="card px-2 py-1">
          <div class="card-header pb-1" :class="isDarkMode ? `bg-black text-light` : `bg-white text-dark`">
            <div class="d-flex align-items-center justify-content-between">
              <h5 class="my-auto">Logi</h5>
              <span class="my-auto">
                <i class="material-symbols-outlined text-lg cursor-pointer" @click="closeModal()">close</i>
              </span>
            </div>
          </div>
          <div class="card-body pt-1" :class="isDarkMode ? 'bg-black' : 'bg-white'">
            <div v-if="logs.length === 0" class="w-100 text-sm">Brak logów</div>
            <div v-if="logs.length > 0" class="w-100 overflow-auto" style="max-height: 500px">
              <p
                v-for="(item, index) in logs"
                :key="item"
                class="my-1 text-sm"
                :class="item.type === 'Error' ? 'text-danger' : ''"
              >
                {{ (index + 1) + ". " + JSON.stringify(item, null, 2) }}
              </p>
            </div>
            
            <div class="d-flex align-items-center justify-content-end mt-3">
              <material-button
                color="light"
                variant="gradient"
                @click="closeModal()"
              >
                Anuluj
              </material-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-if="me" class="container-fluid">
    <div class="d-flex justify-content-end mb-4">
      <!-- Przycisk: Nowe EDI -->
      <material-button
        class="d-flex align-items-center"
        :color="color"
        variant="gradient"
        @click="createImport()"
      >
        + &nbsp; Nowe EDI
      </material-button>
    </div>

    <div class="row">
      <div class="col-12 mb-4">
        <div class="card">
          <div class="card-header pb-1">
            <h5>EDI</h5>
          </div>
          <div class="card-body p-0">
            <div class="table-responsive">
              <div class="dataTable-wrapper dataTable-loading no-footer searchable fixed-height fixed-columns">
                <div class="dataTable-container">
                  <table class="table table-flush dataTable-table">
                    <thead class="thead-light">
                      <!-- Kolumny -->
                      <tr>
                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder" style="width: 5%">ID</th>
                        <th class="ps-0 text-uppercase text-secondary text-xxs font-weight-bolder">Nazwa</th>
                        <th class="ps-0 text-uppercase text-secondary text-xxs font-weight-bolder">Typ importu</th>
                        <th class="ps-0 text-uppercase text-secondary text-xxs font-weight-bolder">Data importu</th>
                        <th class="ps-0 text-uppercase text-secondary text-xxs font-weight-bolder">Typ exportu</th>
                        <th class="ps-0 text-uppercase text-secondary text-xxs font-weight-bolder">Data exportu</th>
                        <th class="ps-0 text-uppercase text-secondary text-xxs font-weight-bolder">Ilość wierszy</th>
                        <th class="ps-0 text-uppercase text-secondary text-xxs font-weight-bolder" style="width: 5%"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in ediImports" :key="item">
                        <!-- Tabela: ID -->
                        <td>
                          <p class="m-0 text-xs">#{{ item.id }}</p>
                        </td>

                        <!-- Tabela: Nazwa -->
                        <td class="ps-0">
                          <p class="m-0 text-xs">{{ item.name }}</p>
                        </td>

                        <!-- Tabela: Typ importu -->
                        <td class="ps-0">
                          <p class="m-0 text-xs">{{ item.ediTypeImport ? item.ediTypeImport.name : "Brak" }}</p>
                        </td>

                        <!-- Tabela: Data importu -->
                        <td class="ps-0">
                          <p class="m-0 text-xs">{{ item.importAt ? item.importAt : "Brak" }}</p>
                        </td>

                        <!-- Tabela: Typ exportu -->
                        <td class="ps-0">
                          <p class="m-0 text-xs">{{ item.ediTypeExport ? item.ediTypeExport.name : "Brak" }}</p>
                        </td>

                        <!-- Tabela: Data exportu -->
                        <td class="ps-0">
                          <p class="m-0 text-xs">{{ item.exportAt ? item.exportAt : "Brak" }}</p>
                        </td>

                        <!-- Tabela: Ilość wierszy -->
                        <td class="ps-0">
                          <p class="m-0 text-xs">{{ item.quantity }}</p>
                        </td>

                        <!-- Tabela: Menu -->
                        <td class="text-xs text-end">
                          <a
                            :class="[color]"
                            data-bs-toggle="dropdown"
                          >
                            <i class="material-icons cursor-pointer">more_vert</i>
                          </a>
                          <ul
                            class="p-2 dropdown-menu dropdown-menu-end me-sm-n0 z-index-3 shadow"
                          >
                          <li class="cursor-pointer" @click="getEdiImport(item.id, 2)">
                              <a class="px-2 dropdown-item border-radius-md">
                                <div class="mx-0 px-0 py-1 d-flex">
                                  <div class="d-flex flex-column justify-content-center">
                                    <h6 class="my-auto text-sm font-weight-normal" style="color: #344767 !important">
                                      Podgląd
                                    </h6>
                                  </div>
                                </div>
                              </a>
                            </li>
                            <li class="cursor-pointer" @click="getEdiImport(item.id, 1)">
                              <a class="px-2 dropdown-item border-radius-md">
                                <div class="mx-0 px-0 py-1 d-flex">
                                  <div class="d-flex flex-column justify-content-center">
                                    <h6 class="my-auto text-sm font-weight-normal" style="color: #344767 !important">
                                      Edycja
                                    </h6>
                                  </div>
                                </div>
                              </a>
                            </li>
                            <hr class="horizontal my-1 bg-light">
                            <li class="cursor-pointer" @click="getEdiImport(item.id, 6)">
                              <a class="px-2 dropdown-item border-radius-md">
                                <div class="mx-0 px-0 py-1 d-flex">
                                  <div class="d-flex flex-column justify-content-center">
                                    <h6 class="my-auto text-sm font-weight-normal" style="color: #344767 !important">
                                      Logi
                                    </h6>
                                  </div>
                                </div>
                              </a>
                            </li>
                            <hr class="horizontal my-1 bg-light">
                            <li v-if="item.ediTypeImport" class="cursor-pointer" @click="getEdiImport(item.id, 3)">
                              <a class="px-2 dropdown-item border-radius-md">
                                <div class="mx-0 px-0 py-1 d-flex">
                                  <div class="d-flex flex-column justify-content-center">
                                    <h6 class="my-auto text-sm font-weight-normal" style="color: #344767 !important">
                                      Import
                                    </h6>
                                  </div>
                                </div>
                              </a>
                            </li>
                            <li v-if="item.ediTypeExport" class="cursor-pointer" @click="getEdiImport(item.id, 4)">
                              <a class="px-2 dropdown-item border-radius-md">
                                <div class="mx-0 px-0 py-1 d-flex">
                                  <div class="d-flex flex-column justify-content-center">
                                    <h6 class="my-auto text-sm font-weight-normal" style="color: #344767 !important">
                                      Export
                                    </h6>
                                  </div>
                                </div>
                              </a>
                            </li>
                            <li v-if="item.ediTypeImport || item.ediTypeExport" class="cursor-pointer" @click="getEdiImport(item.id, 5)">
                              <a class="px-2 dropdown-item border-radius-md">
                                <div class="mx-0 px-0 py-1 d-flex">
                                  <div class="d-flex flex-column justify-content-center">
                                    <h6 class="my-auto text-sm font-weight-normal" style="color: #344767 !important">
                                      Import/Export
                                    </h6>
                                  </div>
                                </div>
                              </a>
                            </li>
                            <hr class="horizontal my-1 bg-light">
                            <li class="cursor-pointer" @click="deleteImport(item.id)">
                              <a class="px-2 dropdown-item border-radius-md">
                                <div class="mx-0 px-0 py-1 d-flex">
                                  <div class="d-flex flex-column justify-content-center">
                                    <h6 class="my-auto text-sm font-weight-normal" style="color: #344767 !important">
                                      Usuń
                                    </h6>
                                  </div>
                                </div>
                              </a>
                            </li>
                          </ul>
                        </td>
                      </tr>

                      <!-- Brak importów -->
                      <tr :class="ediImports.length > 0 ? 'd-none' : ''">
                        <td class="dataTables-empty text-sm pb-2" colspan="8">Nie znaleziono importów</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Snackbar -->
    <div class="position-fixed ms-1 bottom-2 left-1 z-index-2">
      <material-snackbar
        v-if="snackbar"
        description="Operacja została wykonana pomyślnie!"
        title="snackbar"
        color="success"
        :close-handler="closeSnackbar"
      />
    </div>
  </div>
</template>

<script>
import MaterialSnackbar from "@/components/MaterialSnackbar.vue"
import MaterialButton from "@/components/MaterialButton.vue"
import UserDataService from "@/services/UserDataService"
import ImportDataService from "@/services/ImportDataService"
import EdiTypeImportDataService from "@/services/EdiTypeImportDataService"
import EdiTypeExportDataService from "@/services/EdiTypeExportDataService"
import EdiLogDataService from "@/services/EdiLogDataService"
import { mapState } from "vuex"

export default {
  name: "Edi",
  components: {
    MaterialSnackbar,
    MaterialButton,
  },
  data() {
    return {
      me: null, // Obiekt obecnie zalogowanego użytkownika

      ediImport: null, // Obiekt importowanego pliku
      ediImports: [], // Tablica wszystkich importów

      importTypes: [], // Tablica przechowująca typy importu
      exportTypes: [], // Tablica przechowująca typy exportu

      customUrl: "", // Zmienna przechowująca customowy URL
      state: null, // Zmienna sprawdzająca stan operacji
      check: false, // Zmienna sprawdzająca czy import został sprawdzony
      response: [], // Tablica zawierająca odpowiedzi sprawdzenia eksportu
      logs: [], // Tablica zawierająca logi

      modals: [], // Tablica obecnych modali

      snackbar: null, // Zmienna odpowiedzialna za pokazywanie się powiadomienia
    }
  },
  computed: {
    ...mapState(["isDarkMode", "color"])
  },
  created() {
    this.getMe()
  },
  methods: {
    // Funkcja pobierająca dane zalogowanego użytkownika
    getMe() {
      UserDataService.me()
      .then(res => {
        this.getUser(res.data.id)
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja pobierająca dane zalogowanego użytkownika
    getUser(id) {
      UserDataService.get(id)
      .then(res => {
        if (res.data.deleted) {
          this.$logout()
        }
        else {
          this.me = res.data
          this.getEdiImports()
        }
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja pobierająca wybrany import
    getEdiImport(id, modal) {
      ImportDataService.get(id)
      .then(res => {
        this.ediImport = res.data

        this.ediImport.ediImportType = this.ediImport.ediTypeImport
        this.ediImport.ediExportType = this.ediImport.ediTypeExport

        this.ediImport.ediTypeImport = this.ediImport.ediTypeImport ? `/edi_type_imports/${ this.ediImport.ediTypeImport.id }` : ""
        this.ediImport.ediTypeExport = this.ediImport.ediTypeExport ? `/edi_type_exports/${ this.ediImport.ediTypeExport.id }` : ""

        this.customUrl = ""
        this.state = null
        this.check = false
        this.response = []
        
        this.getImportTypes()
        this.getExportTypes()

        this.logs = []
        // this.getLogs(res.data.id)
        
        this.openModal(modal)
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja tworząca obiekt importu
    createImport() {
      this.ediImport = null
      this.ediImport = {
        name: "",
        slugId: "",
        ediTypeImport: "",
        ediTypeExport: "",
      }

      this.getImportTypes()
      this.getExportTypes()

      this.openModal(0)
    },

    // Funkcja tworząca nowy import
    postImport() {
      ImportDataService.post(
        {
          name: this.ediImport.name,
          slugId: this.ediImport.slugId,
          ediTypeImport: this.ediImport.ediTypeImport ? this.ediImport.ediTypeImport : null,
          ediTypeExport: this.ediImport.ediTypeExport ? this.ediImport.ediTypeExport : null,
        }
      )
      .then(res => {
        console.log(res.data)
        this.getEdiImports()
        this.closeModal()
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja aktualizująca wybrany import
    patchImport() {
      ImportDataService.patch(this.ediImport.id, this.ediImport,
        {
          headers: { "Content-Type": "application/merge-patch+json" }
        }
      )
      .then(res => {
        console.log(res.data)
        this.ediImport = null
        this.getEdiImports()
        this.closeModal()
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja wykonująca import wybranego importu
    getImport(id) {
      this.state = "load"
      ImportDataService.import(`${ id }?${ this.customUrl }`)
      .then(res => {
        console.log(res.data)
        this.state = "done"
        this.getEdiImports()
      })
      .catch(error => {
        console.log(error)
        this.state = "error"
      })
    },

    // Funkcja sprawdzająca export wybranego importu
    checkExport(id) {
      ImportDataService.export(`${ id }?${ this.customUrl }`)
      .then(res => {
        this.check = true
        this.response = res.data["hydra:member"]
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja wykonująca export wybranego importu
    getExport(id) {
      this.state = "load"
      ImportDataService.export(`${ id }?exec=1${ this.customUrl }`)
      .then(res => {
        console.log(res.data)
        this.state = "done"
        this.response = []
        this.check = false
        this.getEdiImports()
      })
      .catch(error => {
        console.log(error)
        this.state = "error"
      })
    },

    // Funkcja wykonująca import/export wybranego importu
    getImportExport(id) {
      this.state = "load"
      ImportDataService.import_export(`${ id }?exec=1${ this.customUrl }`)
      .then(res => {
        console.log(res.data)
        this.state = "done"
        this.getEdiImports()
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja pobierająca z API logi dla danego EDI
    getLogs(id) {
      EdiLogDataService.getAll(`?itemsPerPage=1000&page=1&ediImport.id=${ id }&order[createdAt]=desc`)
      .then(res => {
        this.logs = res.data["hydra:member"]
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja usuwająca wybrany import
    deleteImport(id) {
      ImportDataService.delete(id)
      .then(res => {
        console.log(res.data)
        this.getEdiImports()
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja pobierająca wszystkie zaimportowane pliki
    getEdiImports() {
      ImportDataService.getAll("")
      .then(res => {
        this.ediImports = res.data["hydra:member"]
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja pobierająca wszystkie dostępne typy importu
    getImportTypes() {
      EdiTypeImportDataService.getAll("")
      .then(res => {
        this.importTypes = res.data["hydra:member"]
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja pobierająca wszystkie dostępne typy exportu
    getExportTypes() {
      EdiTypeExportDataService.getAll("")
      .then(res => {
        this.exportTypes = res.data["hydra:member"]
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja kopiująca wybrany tekst po kliknięciu
    copyText(text) {
      navigator.clipboard.writeText(text)
    },

    // Funkcja pobierająca zmienną środowiskową
    getGlobalURL() {
      return process.env.VUE_APP_API_URL
    },

    // Funkcja otwierająca okno
    openModal(x) {
      for (let i = 0; i < this.modals.length; i++) {
        this.modals[i] = false
      }

      document.getElementById("modal").classList.add("d-block")
      this.modals[x] = true
    },

    // Funkcja zamykająca okno
    closeModal() {
      document.getElementById("modal").classList.remove("d-block")
      for (let i = 0; i < this.modals.length; i++) {
        this.modals[i] = false
      }
    },

    // Funkcja zamykająca powiadomienie
    closeSnackbar() {
      this.snackbar = null
    },
  }
}
</script>

<style scoped>
* {
  opacity: 1 !important;
}

.modal {
  background: rgb(0, 0, 0, 0.5);
  z-index: 10000 !important;
}

.modal-content {
  background: none;
  border: none;
}

select, input {
  background: none;
  border: none;
  outline: none;
  border-bottom: 1px solid;
}

select option {
  color: black !important;
}

.loader {
  border: 2px solid #f3f3f3;
  border-radius: 50%;
  border-top: 2px solid #3498db;
  width: 18px;
  height: 18px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>