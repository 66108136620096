import http from "@/http-common"

class StageHistoryDataService {
    getAll(url) {
        return http.get(`stage_histories${ url }`)
    }

    get(id) {
        return http.get(`stage_histories/${ id }`)
    }

    post(data) {
        return http.post("stage_histories", data)
    }

    patch(id, data, headers) {
        return http.patch(`stage_histories/${ id }`, data, headers)
    }

    delete(id) {
        return http.delete(`stage_histories/${ id }`)
    }
}

export default new StageHistoryDataService()