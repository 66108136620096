import http from "@/http-common"

class PriceDataService {
    getAll(url) {
        return http.get(`prices${ url }`)
    }

    get(id) {
        return http.get(`prices/${ id }`)
    }

    post(data) {
        return http.post("prices", data)
    }

    patch(id, data, headers) {
        return http.patch(`prices/${ id }`, data, headers)
    }

    delete(id) {
        return http.delete(`prices/${ id }`)
    }
}

export default new PriceDataService()