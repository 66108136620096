<template>
  <div
    class="input-group"
    :class="`input-group-${ variant } ${ getStatus(error, success) }`"
  >
    <label class="d-flex" :class="variant === 'static' ? '' : 'form-label'">
      {{ label }} <i v-if="isRequired" class="material-icons text-sm ms-1 text-warning">star</i>
    </label>
    <input
      :id="id"
      :type="type"
      class="form-control"
      :class="getClasses(size) && isDarkMode === false ? 'text-dark' : 'text-white'"
      :name="name"
      :value="value"
      :min="min"
      :max="max"
      :maxlength="maxlength"
      :placeholder="placeholder"
      :isRequired="isRequired"
      :disabled="disabled"
    />
  </div>
</template>

<script>
import setMaterialInput from "@/assets/js/material-input.js"
import { mapState } from "vuex"

export default {
  name: "MaterialInput",
  props: {
    variant: {
      type: String,
      default: "outline"
    },
    label: {
      type: String,
      default: ""
    },
    min: {
      type: String,
      default: ""
    },
    max: {
      type: String,
      default: ""
    },
    maxlength: {
      type: String,
      default: ""
    },
    size: {
      type: String,
      default: "default"
    },
    success: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: ""
    },
    id: {
      type: String,
      required: true
    },
    value: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: ""
    },
    type: {
      type: String,
      default: "text"
    },
    isRequired: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState(["isDarkMode"])
  },
  mounted() {
    setMaterialInput()
  },
  methods: {
    getClasses: (size) => {
      let sizeValue

      sizeValue = size ? `form-control-${size}` : null

      return sizeValue
    },

    getStatus: (error, success) => {
      let isValidValue

      if (success) {
        isValidValue = "is-valid"
      }
      else if (error) {
        isValidValue = "is-invalid"
      }
      else {
        isValidValue = null
      }

      return isValidValue
    }
  }
}
</script>

<style scoped>
.input-group.input-group-outline.is-focused .form-label + .form-control, .input-group.input-group-outline.is-filled .form-label + .form-control {
  border-color: #d2d6da !important;
}
</style>