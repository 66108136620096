<template>
  <!-- Modal -->
  <div id="modal" class="modal show" tabindex="-1" role="dialog">
    <!-- Modal: Nowe wydarzenie -->
    <div v-if="modals[0]" class="modal-dialog">
      <div class="modal-content">
        <div class="card px-2 py-1">
          <div class="card-header pb-1" :class="isDarkMode ? 'bg-black text-light' : 'bg-white text-dark'">
            <div class="d-flex align-items-center justify-content-between">
              <h5 class="my-auto">Nowe wydarzenie</h5>
              <span class="my-auto">
                <i class="material-symbols-outlined text-lg cursor-pointer" @click="closeModal()">close</i>
              </span>
            </div>
          </div>
          <div class="card-body pt-1 text-sm" :class="isDarkMode ? 'bg-black' : 'bg-white'">
            <!-- Typ -->
            <div class="row mb-2">
              <div class="col-md-3 my-auto">
                <label class="form-label ms-0">Typ</label>
              </div>
              <div class="col-md-9">
                <select
                  class="px-0 py-1 text-sm"
                  :class="isDarkMode ? 'text-white' : 'text-dark'"
                  :style="isDarkMode ? 'border-color: #8b8e98 !important' : 'border-color: #d2d2d2 !important'"
                >
                  <option value="event" selected>Wydarzenie</option>
                </select>
              </div>
            </div>

            <!-- Nazwa -->
            <div class="row mb-2">
              <div class="col-md-3 my-auto">
                <label class="d-flex align-items-center form-label ms-0">
                  Nazwa
                  <i class="material-icons text-sm ms-1 text-warning">star</i>
                </label>
              </div>
              <div class="col-md-9">
                <div class="input-group input-group-static">
                  <input
                    v-model="event.title"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                    placeholder="Nazwa wydarzenia"
                  />
                </div>
              </div>
            </div>
            
            <!-- Opis -->
            <div class="row mb-2">
              <div class="col-md-3 my-auto">
                <label class="form-label ms-0">Opis</label>
              </div>
              <div class="col-md-9">
                <div class="input-group input-group-static">
                  <input
                    v-model="event.description"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                    placeholder="Opis wydarzenia"
                  />
                </div>
              </div>
            </div>

            <!-- Załączniki -->
            <div class="row mb-2">
              <div class="col-md-3">
                <label class="form-label mt-1 ms-0 cursor-pointer">
                  Załączniki [ + ]
                  <input type="file" class="d-none" @change="createAttachment(event.attachments, $event)">
                </label>
              </div>
              <div class="col-md-9">
                <div v-for="(item, index) in event.attachments" :key="item" class="text-sm mt-1 mb-2 d-flex align-items-center">
                  {{ item.name }} <span class="ms-2 text-xxs">({{ item.size }} KB)</span>
                  <i class="material-icons ms-auto cursor-pointer" @click="event.attachments.splice(index, 1)">cancel</i>
                </div>
              </div>
            </div>
            
            <!-- Całodniowe -->
            <div class="row mb-2">
              <div class="col-md-3 my-auto">
                <label class="form-label ms-0">Całodniowe</label>
              </div>
              <div class="col-md-3">
                <div class="form-check form-switch d-flex">
                  <input
                    id="allDay"
                    v-model="event.allDay"
                    class="form-check-input"
                    :class="$attrs.class"
                    type="checkbox"
                    name="allDay"
                  />
                  <label class="form-check-label ms-3" :for="id">
                    <slot />
                  </label>
                </div>
              </div>
            </div>

            <!-- Początek -->
            <div class="row mb-2">
              <div class="col-md-3 my-auto">
                <label class="d-flex align-items-center form-label ms-0">
                  Początek
                  <i class="material-icons text-sm ms-1 text-warning">star</i>
                </label>
              </div>
              <div class="col-md-9">
                <input
                  v-model="event.startDate"
                  :type="event.allDay ? 'date' : 'datetime-local'"
                  class="w-100 pb-1 text-sm"
                  :class="isDarkMode ? '' : 'text-dark'"
                  :style="isDarkMode ? 'border-color: #777777 !important; font-weight: bold; filter: invert(1)' : 'border-color: #d2d2d2 !important'"
                  :value="event.allDay ? moment(event.startDate).format('YYYY-MM-DD') : moment(event.startDate).format('YYYY-MM-DD HH:mm')"
                  @change="setEndDate()"
                />
              </div>
            </div>

            <!-- Koniec -->
            <div class="row mb-2">
              <div class="col-md-3 my-auto">
                <label class="d-flex align-items-center form-label ms-0">
                  Koniec
                  <i class="material-icons text-sm ms-1 text-warning">star</i>
                </label>
              </div>
              <div class="col-md-9">
                <input
                  v-model="event.endDate"
                  :type="event.allDay ? 'date' : 'datetime-local'"
                  class="w-100 pb-1 text-sm"
                  :class="isDarkMode ? '' : 'text-dark'"
                  :style="isDarkMode ? 'border-color: #777777 !important; font-weight: bold; filter: invert(1)' : 'border-color: #d2d2d2 !important'"
                  :value="event.allDay ? moment(event.endDate).format('YYYY-MM-DD') : moment(event.endDate).format('YYYY-MM-DD HH:mm')"
                />
              </div>
            </div>

            <!-- Powtarzaj -->
            <div class="row mb-2">
              <div class="col-md-3 my-auto">
                <label class="form-label ms-0">Powtarzaj</label>
              </div>
              <div class="col-md-9">
                <select
                  v-model="event.repeat"
                  class="px-0 py-1 text-sm"
                  :class="isDarkMode ? 'text-white' : 'text-dark'"
                  :style="isDarkMode ? 'border-color: #8b8e98 !important' : 'border-color: #d2d2d2 !important'"
                >
                  <option value="never">Nigdy</option>
                  <option value="daily">Codziennie</option>
                  <option value="weekly">Co tydzień</option>
                  <option value="biweekly">Co dwa tygodnie</option>
                  <option value="monthly">Co miesiąc</option>
                  <option value="yearly">Co rok</option>
                </select>
              </div>
            </div>

            <!-- Data końcowa -->
            <div v-if="event.repeat !== 'never'" class="row mb-2">
              <div class="col-md-3 my-auto">
                <label class="form-label ms-0">Data końcowa</label>
              </div>
              <div class="col-md-9">
                <input
                  v-model="event.endRecur"
                  type="datetime-local"
                  class="w-100 pb-1 text-sm"
                  :class="isDarkMode ? '' : 'text-dark'"
                  :style="isDarkMode ? 'border-color: #777777 !important; font-weight: bold; filter: invert(1)' : 'border-color: #d2d2d2 !important'"
                />
              </div>
            </div>

            <!-- Kalendarz -->
            <div class="row mb-2">
              <div class="col-md-3 my-auto">
                <label class="d-flex align-items-center form-label ms-0">
                  Kalendarz
                  <i class="material-icons text-sm ms-1 text-warning">star</i>
                </label>
              </div>
              <div class="col-md-9">
                <select
                  v-model="event.calendar"
                  class="px-0 py-1 text-sm"
                  :class="isDarkMode ? 'text-white' : 'text-dark'"
                  :style="isDarkMode ? 'border-color: #8b8e98 !important' : 'border-color: #d2d2d2 !important'"
                  @change="event.members = []; getEventUsers($event.target.value)"
                >
                  <option value="">Wybierz...</option>
                  <option v-for="item in availableCalendars" :key="item" :value="item.id" :class="item.id === 0 ? 'd-none' : ''">{{ item.name }}</option>
                </select>
              </div>
            </div>

            <!-- Osoby powiązane -->
            <div class="row">
              <div class="col-md-3">
                <label class="form-label mt-1 ms-0">Osoby powiązane</label>
              </div>
              <div class="col-md-9">
                <SimpleTypeahead
                  ref="eventUsersAC"
                  placeholder="Wyszukaj..."
                  :items="eventUsersAC"
                  :min-input-length="0"
                  class="w-100 text-sm pb-1 my-auto"
                  style="background: none; border: none; outline: none; border-bottom: 1px solid #eeeeee"
                  :class="isDarkMode ? 'text-white' : 'text-dark'"
                  @select-item="selectEventUser"
                >
                </SimpleTypeahead>
                <p v-for="(item, index) in event.members" :key="item" class="text-sm mt-3 mb-0 d-flex align-items-center">
                  {{ item.fullName }}
                  <i class="material-icons ms-auto cursor-pointer" @click="eventUsers.push(item); eventUsersAC.push(item.fullName); event.members.splice(index, 1)">delete</i>
                </p>
              </div>
            </div>

            <!-- Powiadom -->
            <div class="row mb-2" :class="event.members.length === 0 ? 'mt-2' : 'mt-3'">
              <div class="col-md-3 my-auto">
                <label class="form-label ms-0">Powiadom</label>
              </div>
              <div class="col-md-9">
                <select
                  v-model="event.reminder"
                  class="px-0 py-1 text-sm"
                  :class="isDarkMode ? 'text-white' : 'text-dark'"
                  :style="isDarkMode ? 'border-color: #8b8e98 !important' : 'border-color: #d2d2d2 !important'"
                >
                  <option value="">Brak</option>
                  <option value="10 minutes">10 min. przed</option>
                  <option value="30 minutes">30 min. przed</option>
                  <option value="1 hours">1 godz. przed</option>
                  <option value="2 hours">2 godz. przed</option>
                  <option value="1 days">1 dzień przed</option>
                  <option value="5 days">5 dni przed</option>
                </select>
              </div>
            </div>

            <!-- Blokada -->
            <div class="d-flex align-items-center">
              <label class="form-label me-4 ms-0">Zablokuj edycję innym użytkownikom</label>
              <material-switch @change="event.blocked = $event.target.checked" />
            </div>

            <p v-if="event.error !== ''" class="w-100 mt-2 text-sm font-weight-bold text-end text-danger">{{ event.error }}</p>

            <div class="d-flex justify-content-end mt-3">
              <material-button
                :color="color"
                variant="gradient"
                @click="validationPostEvent()"
              >
                Dodaj
              </material-button>

              <material-button
                class="ms-3"
                color="light"
                variant="gradient"
                @click="closeModal()"
              >
                Anuluj
              </material-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal: Edycja wydarzenia -->
    <div v-if="modals[1]" class="modal-dialog">
      <div class="modal-content">
        <div class="card px-2 py-1">
          <div class="card-header pb-1" :class="isDarkMode ? 'bg-black text-light' : 'bg-white text-dark'">
            <div class="d-flex align-items-center justify-content-between">
              <h5 class="my-auto">Edycja wydarzenia</h5>
              <span class="my-auto">
                <i class="material-symbols-outlined text-lg cursor-pointer" @click="closeModal()">close</i>
              </span>
            </div>
          </div>
          <div class="card-body pt-1 text-sm" :class="isDarkMode ? 'bg-black' : 'bg-white'">
            <!-- Typ -->
            <div class="row mb-2">
              <div class="col-md-3 my-auto">
                <label class="form-label ms-0">Typ</label>
              </div>
              <div class="col-md-9">
                <select
                  class="px-0 py-1 text-sm"
                  :class="isDarkMode ? 'text-white' : 'text-dark'"
                  :style="isDarkMode ? 'border-color: #8b8e98 !important' : 'border-color: #d2d2d2 !important'"
                >
                  <option value="event" selected>Wydarzenie</option>
                </select>
              </div>
            </div>

            <!-- Nazwa -->
            <div class="row mb-2">
              <div class="col-md-3 my-auto">
                <label class="d-flex align-items-center form-label ms-0">
                  Nazwa
                  <i class="material-icons text-sm ms-1 text-warning">star</i>
                </label>
              </div>
              <div class="col-md-9">
                <div class="input-group input-group-static">
                  <input
                    v-model="event.title"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                    placeholder="Nazwa wydarzenia"
                  />
                </div>
              </div>
            </div>
            
            <!-- Opis -->
            <div class="row mb-2">
              <div class="col-md-3 my-auto">
                <label class="form-label ms-0">Opis</label>
              </div>
              <div class="col-md-9">
                <div class="input-group input-group-static">
                  <input
                    v-model="event.description"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                    placeholder="Opis wydarzenia"
                  />
                </div>
              </div>
            </div>

            <!-- Załączniki -->
            <div class="row mb-2">
              <div class="col-md-3">
                <label class="form-label mt-1 ms-0 cursor-pointer">
                  Załączniki [ + ]
                  <input type="file" class="d-none" @change="createAttachment(event.newAttachments, $event)">
                </label>
              </div>
              <div class="col-md-9">
                <div v-for="(item, index) in event.attachments" :key="item" class="text-sm mt-1 mb-2 d-flex align-items-center">
                  <a :href="'data:' + item.type + ';base64,' + item.blob" :download="item.name">{{ item.name }}</a> <span class="ms-2 text-xxs">({{ item.size }} KB)</span>
                  <i class="material-icons ms-auto cursor-pointer" @click="event.removedAttachments.push(item.id); event.attachments.splice(index, 1)">cancel</i>
                </div>

                <div v-for="(item, index) in event.newAttachments" :key="item" class="text-sm mt-1 mb-2 d-flex align-items-center">
                  {{ item.name }} <span class="ms-2 text-xxs">({{ item.size }} KB)</span>
                  <i class="material-icons ms-auto cursor-pointer" @click="event.newAttachments.splice(index, 1)">cancel</i>
                </div>
              </div>
            </div>
            
            <!-- Całodniowe -->
            <div class="row mb-2">
              <div class="col-md-3 my-auto">
                <label class="form-label ms-0">Całodniowe</label>
              </div>
              <div class="col-md-3">
                <div class="form-check form-switch d-flex">
                  <input
                    id="allDay"
                    v-model="event.allDay"
                    class="form-check-input"
                    :class="$attrs.class"
                    type="checkbox"
                    name="allDay"
                  />
                  <label class="form-check-label ms-3" :for="id">
                    <slot />
                  </label>
                </div>
              </div>
            </div>

            <!-- Początek -->
            <div class="row mb-2">
              <div class="col-md-3 my-auto">
                <label class="d-flex align-items-center form-label ms-0">
                  Początek
                  <i class="material-icons text-sm ms-1 text-warning">star</i>
                </label>
              </div>
              <div class="col-md-9">
                <input
                  v-model="event.startDate"
                  :type="event.allDay ? 'date' : 'datetime-local'"
                  class="w-100 pb-1 text-sm"
                  :class="isDarkMode ? '' : 'text-dark'"
                  :style="isDarkMode ? 'border-color: #777777 !important; font-weight: bold; filter: invert(1)' : 'border-color: #d2d2d2 !important'"
                  :value="event.allDay ? moment(event.startDate).format('YYYY-MM-DD') : moment(event.startDate).format('YYYY-MM-DD HH:mm')"
                  @change="setEndDate()"
                />
              </div>
            </div>

            <!-- Koniec -->
            <div class="row mb-2">
              <div class="col-md-3 my-auto">
                <label class="d-flex align-items-center form-label ms-0">
                  Koniec
                  <i class="material-icons text-sm ms-1 text-warning">star</i>
                </label>
              </div>
              <div class="col-md-9">
                <input
                  v-model="event.endDate"
                  :type="event.allDay ? 'date' : 'datetime-local'"
                  class="w-100 pb-1 text-sm"
                  :class="isDarkMode ? '' : 'text-dark'"
                  :style="isDarkMode ? 'border-color: #777777 !important; font-weight: bold; filter: invert(1)' : 'border-color: #d2d2d2 !important'"
                  :value="event.allDay ? moment(event.endDate).format('YYYY-MM-DD') : moment(event.endDate).format('YYYY-MM-DD HH:mm')"
                />
              </div>
            </div>

            <!-- Powtarzaj -->
            <div class="row mb-2">
              <div class="col-md-3 my-auto">
                <label class="form-label ms-0">Powtarzaj</label>
              </div>
              <div class="col-md-9">
                <select
                  v-model="event.repeat"
                  class="px-0 py-1 text-sm"
                  :class="isDarkMode ? 'text-white' : 'text-dark'"
                  :style="isDarkMode ? 'border-color: #8b8e98 !important' : 'border-color: #d2d2d2 !important'"
                >
                  <option value="never">Nigdy</option>
                  <option value="daily">Codziennie</option>
                  <option value="weekly">Co tydzień</option>
                  <option value="biweekly">Co dwa tygodnie</option>
                  <option value="monthly">Co miesiąc</option>
                  <option value="yearly">Co rok</option>
                </select>
              </div>
            </div>

            <!-- Data końcowa -->
            <div v-if="event.repeat !== 'never'" class="row mb-2">
              <div class="col-md-3 my-auto">
                <label class="form-label ms-0">Data końcowa</label>
              </div>
              <div class="col-md-9">
                <input
                  v-model="event.endRecur"
                  type="datetime-local"
                  class="w-100 pb-1 text-sm"
                  :class="isDarkMode ? '' : 'text-dark'"
                  :style="isDarkMode ? 'border-color: #777777 !important; font-weight: bold; filter: invert(1)' : 'border-color: #d2d2d2 !important'"
                />
              </div>
            </div>

            <!-- Kalendarz -->
            <div class="row mb-2">
              <div class="col-md-3 my-auto">
                <label class="d-flex align-items-center form-label ms-0">
                  Kalendarz
                  <i class="material-icons text-sm ms-1 text-warning">star</i>
                </label>
              </div>
              <div class="col-md-9">
                <select
                  v-model="event.calendar.id"
                  class="px-0 py-1 text-sm"
                  :class="isDarkMode ? 'text-white' : 'text-dark'"
                  :style="isDarkMode ? 'border-color: #8b8e98 !important' : 'border-color: #d2d2d2 !important'"
                  @change="event.members = []; getEventUsers($event.target.value)"
                >
                  <option value="">Wybierz...</option>
                  <option v-for="item in availableCalendars" :key="item" :value="item.id" :class="item.id === 0 ? 'd-none' : ''">{{ item.name }}</option>
                </select>
              </div>
            </div>

            <!-- Osoby powiązane -->
            <div class="row">
              <div class="col-md-3">
                <label class="form-label mt-1 ms-0">Osoby powiązane</label>
              </div>
              <div class="col-md-9">
                <SimpleTypeahead
                  ref="eventUsersAC"
                  placeholder="Wyszukaj..."
                  :items="eventUsersAC"
                  :min-input-length="0"
                  class="w-100 text-sm pb-1 my-auto"
                  style="background: none; border: none; outline: none; border-bottom: 1px solid #eeeeee"
                  :class="isDarkMode ? 'text-white' : 'text-dark'"
                  @select-item="selectEventUser"
                >
                </SimpleTypeahead>
                <p v-for="(item, index) in event.members" :key="item" class="text-sm mt-3 mb-0 d-flex align-items-center">
                  {{ item.fullName }}
                  <i class="material-icons ms-auto cursor-pointer" @click="eventUsers.push(item); eventUsersAC.push(item.fullName); event.members.splice(index, 1)">delete</i>
                </p>
              </div>
            </div>

            <!-- Powiadom -->
            <div class="row mb-2" :class="event.members.length === 0 ? 'mt-2' : 'mt-3'">
              <div class="col-md-3 my-auto">
                <label class="form-label ms-0">Powiadom</label>
              </div>
              <div class="col-md-9">
                <select
                  v-model="event.reminder"
                  class="px-0 py-1 text-sm"
                  :class="isDarkMode ? 'text-white' : 'text-dark'"
                  :style="isDarkMode ? 'border-color: #8b8e98 !important' : 'border-color: #d2d2d2 !important'"
                >
                  <option value="">Brak</option>
                  <option value="10 minutes">10 min. przed</option>
                  <option value="30 minutes">30 min. przed</option>
                  <option value="1 hours">1 godz. przed</option>
                  <option value="2 hours">2 godz. przed</option>
                  <option value="1 days">1 dzień przed</option>
                  <option value="5 days">5 dni przed</option>
                </select>
              </div>
            </div>

            <!-- Blokada -->
            <div class="d-flex align-items-center">
              <label class="form-label me-4 ms-0">Zablokuj edycję innym użytkownikom</label>
              <material-switch :checked="event.blocked" @change="event.blocked = $event.target.checked" />
            </div>

            <p v-if="event.error !== ''" class="w-100 mt-2 text-sm font-weight-bold text-end text-danger">{{ event.error }}</p>

            <div class="d-flex align-items-center justify-content-between mt-3">
              <span class="text-danger my-auto text-sm cursor-pointer" @click="openModal(3)">
                Usuń wydarzenie
              </span>

              <div class="d-flex align-items-center">
                <material-button
                  :color="color"
                  variant="gradient"
                  @click="validationPatchEvent()"
                >
                  Zapisz
                </material-button>

                <material-button
                  class="ms-3"
                  color="light"
                  variant="gradient"
                  @click="closeModal()"
                >
                  Anuluj
                </material-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal: Podgląd wydarzenia -->
    <div v-if="modals[2]" class="modal-dialog">
      <div class="modal-content">
        <div class="card px-2 py-1">
          <div class="card-header pb-0" :class="isDarkMode ? 'bg-black text-light' : 'bg-white text-dark'">
            <div class="d-flex align-items-center justify-content-between">
              <h5 class="my-auto">{{ event.title }}</h5>
              <span class="my-auto">
                <i class="material-symbols-outlined text-lg cursor-pointer" @click="closeModal()">close</i>
              </span>
            </div>

            <p class="mb-0 text-sm">
              {{ moment(event.startDate).format(event.allDay ? "dddd, DD MMMM" : "dddd, DD MMMM HH:mm") }} do {{ moment(event.endDate).format(event.allDay ? "dddd, DD MMMM" : "dddd, DD MMMM HH:mm") }}
            </p>
          </div>
          <div class="card-body pt-1 text-sm" :class="isDarkMode ? 'bg-black' : 'bg-white'">
            <div class="row">
              <div class="col-12">
                <label class="font-weight-bold ms-0 mb-1">Opis wydarzenia</label>
                <p class="text-sm">{{ event.description ? event.description : "Brak" }}</p>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <label class="font-weight-bold ms-0 mb-1">Załączniki</label>
                <p v-if="event.attachments.length === 0" class="text-sm">Brak załączników</p>
                <p v-for="(item, index) in event.attachments" :key="item" class="text-sm d-flex align-items-center" :class="index !== event.attachments.length - 1 ? `mb-1` : `mb-3`">
                  <a :href="'data:' + item.type + ';base64,' + item.blob" :download="item.name">{{ item.name }}</a> <span class="ms-2 text-xxs">({{ item.size }} KB)</span>
                </p>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <label class="font-weight-bold ms-0 mb-1">Kalendarz</label>
                <p class="text-sm">{{ event.calendar ? event.calendar.name : "Brak" }}</p>
              </div>
            </div>

            <div class="row" :class="event.members.length === 0 ? '' : 'mb-3'">
              <div class="col-12">
                <label class="font-weight-bold ms-0 mb-1">Osoby powiązane</label>
                <p v-if="event.members.length === 0" class="text-sm">Brak powiązanych osób</p>
                <p v-for="item in event.members" :key="item" class="text-sm mb-1">{{ item.fullName }}</p>
              </div>
            </div>

            <div class="row" :class="event.members.length === 0 ? 'mt-2' : 'mt-3'">
              <div class="col-12">
                <label class="font-weight-bold ms-0 mb-1">Powiadomienie</label>
                <p v-if="event.reminder === ''" class="text-sm">Brak</p>
                <p v-if="event.reminder === '10 minutes'" class="text-sm">10 minut przed</p>
                <p v-if="event.reminder === '30 minutes'" class="text-sm">30 minut przed</p>
                <p v-if="event.reminder === '1 hours'" class="text-sm">1 godzinę przed</p>
                <p v-if="event.reminder === '2 hours'" class="text-sm">2 godziny przed</p>
                <p v-if="event.reminder === '1 days'" class="text-sm">1 dzień przed</p>
                <p v-if="event.reminder === '5 days'" class="text-sm">5 dni przed</p>
              </div>
            </div>

            <div v-if="event.blocked" class="row mt-2">
              <div class="col-12">
                <p class="text-sm">Zablokowano do edycji</p>
              </div>
            </div>

            <div class="d-flex align-items-center justify-content-between mt-3">
              <span v-if="me.mailAccounts.length > 0" class="my-auto text-sm cursor-pointer" :class="`text-${ color }`" @click="createMessage()">
                Wyślij zaproszenie
              </span>

              <div class="d-flex align-items-center">
                <material-button
                  v-if="event.calendar.owner.id === me.id || (event.permissions && !event.blocked)"
                  :color="color"
                  variant="gradient"
                  @click="openModal(1)"
                >
                  Edycja
                </material-button>

                <material-button
                  class="ms-3"
                  color="light"
                  variant="gradient"
                  @click="closeModal()"
                >
                  Anuluj
                </material-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal: Usunięcie wydarzenia -->
    <div v-if="modals[3]" class="modal-dialog">
      <div class="modal-content">
        <div class="card px-2 py-1">
          <div class="card-header pb-1" :class="isDarkMode ? 'bg-black text-light' : 'bg-white text-dark'">
            <div class="d-flex align-items-center justify-content-between">
              <h5 class="my-auto">Potwierdzenie usunięcia</h5>
              <span class="my-auto">
                <i class="material-symbols-outlined text-lg cursor-pointer" @click="closeModal()">close</i>
              </span>
            </div>
          </div>
          <div class="card-body pt-1 text-sm" :class="isDarkMode ? 'bg-black' : 'bg-white'">
            Czy na pewno chcesz usunąć wydarzenie?

            <div class="d-flex justify-content-end mt-2">
              <material-button
                :color="color"
                variant="gradient"
                @click="deleteEvent(event.id); closeModal()"
              >
                Potwierdź
              </material-button>

              <material-button
                class="ms-3"
                color="light"
                variant="gradient"
                @click="closeModal()"
              >
                Anuluj
              </material-button>
            </div>
          </div>  
        </div>
      </div>
    </div>

    <!-- Modal: Edycja kalendarza -->
    <div v-if="modals[4]" class="modal-dialog">
      <div class="modal-content">
        <div class="card px-2 py-1">
          <div class="card-header pb-1" :class="isDarkMode ? 'bg-black text-light' : 'bg-white text-dark'">
            <div class="d-flex align-items-center justify-content-between">
              <h5 class="my-auto">Edycja kalendarza</h5>
              <span class="my-auto">
                <i class="material-symbols-outlined text-lg cursor-pointer" @click="closeModal()">close</i>
              </span>
            </div>
          </div>
          <div class="card-body pt-1 text-sm" :class="isDarkMode ? 'bg-black' : 'bg-white'">
            <div class="row">
              <div class="col-12">
                <div class="input-group input-group-static">
                  <label class="d-flex">
                    Nazwa kalendarza
                  </label>
                  <input
                    v-model="calendar.name"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                    placeholder="Wydarzenie"
                    :disabled="calendar.privacy"
                  />
                </div>
              </div>
            </div>

            <h5 class="mt-3">Udostępnianie kalendarza</h5>

            <div class="row mb-3">
              <div class="col-12">
                <SimpleTypeahead
                  ref="calendarUsersAC"
                  placeholder="Wyszukaj..."
                  :items="calendarUsersAC"
                  :min-input-length="0"
                  class="w-100 text-sm pb-2 mt-2"
                  style="background: none; border: none; outline: none; border-bottom: 1px solid #eeeeee"
                  :class="isDarkMode ? 'text-white' : 'text-dark'"
                  @select-item="selectCalendarUser"
                >
                </SimpleTypeahead>
              </div>
            </div>

            <div class="row mb-2 font-weight-bold">
              <div class="col-md-9">
                Imię i nazwisko
              </div>

              <div class="col-md-2 text-center">
                Zapis
              </div>

              <div class="col-md-1"></div>
            </div>

            <div class="row mb-2">
              <div class="col-md-9">
                {{ me.fullName }}
              </div>

              <div class="col-md-2 text-center">
                <material-checkbox checked disabled />
              </div>

              <div class="col-md-1"></div>
            </div>

            <div v-for="(item, index) in calendar.members" :key="item">
              <div v-if="item.id !== me.id" class="row mb-1">
                <div class="col-md-9">
                  {{ item.fullName }}
                </div>

                <div class="col-md-2 text-center">
                  <material-checkbox :checked="item.permissions" @change="item.permissions = !item.permissions" />
                </div>

                <div class="col-md-1">
                  <i class="material-icons cursor-pointer" :class="isDarkMode ? 'text-light' : 'text-dark'" @click="calendarUsers.push(item); calendarUsersAC.push(item.fullName); calendar.members.splice(index, 1)">delete</i>
                </div>
              </div>
            </div>

            <div class="d-flex justify-content-end mt-3">
              <material-button
                :color="color"
                variant="gradient"
                @click="patchCalendar()"
              >
                Zapisz
              </material-button>

              <material-button
                class="ms-3"
                color="light"
                variant="gradient"
                @click="closeModal()"
              >
                Anuluj
              </material-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal: Podgląd kalendarza -->
    <div v-if="modals[5]" class="modal-dialog">
      <div class="modal-content">
        <div class="card px-2 py-1">
          <div class="card-header pb-0" :class="isDarkMode ? 'bg-black text-light' : 'bg-white text-dark'">
            <div class="d-flex align-items-center justify-content-between">
              <h5 class="my-auto">Podgląd kalendarza</h5>
              <span class="my-auto">
                <i class="material-symbols-outlined text-lg cursor-pointer" @click="closeModal()">close</i>
              </span>
            </div>
          </div>
          <div class="card-body pt-1 text-sm" :class="isDarkMode ? 'bg-black text-white' : 'bg-white text-dark'">
            <div class="row mb-2">
              <div class="col-md-3">Nazwa</div>
              <div class="col-md-9 font-weight-bold">{{ calendar.name }}</div>
            </div>

            <div class="row mb-2">
              <div class="col-md-3">Właściciel</div>
              <div class="col-md-9 font-weight-bold">{{ calendar.owner.fullName }}</div>
            </div>

            <div class="row">
              <div class="col-md-3">Prawa</div>
              <div class="col-md-9 font-weight-bold">{{ calendar.permission ? "Odczyt i zapis" : "Odczyt" }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal: Usunięcie kalendarza -->
    <div v-if="modals[6]" class="modal-dialog">
      <div class="modal-content">
        <div class="card px-2 py-1">
          <div class="card-header pb-1" :class="isDarkMode ? 'bg-black text-light' : 'bg-white text-dark'">
            <div class="d-flex align-items-center justify-content-between">
              <h5 class="my-auto">Potwierdzenie usunięcia</h5>
              <span class="my-auto">
                <i class="material-symbols-outlined text-lg cursor-pointer" @click="closeModal()">close</i>
              </span>
            </div>
          </div>
          <div class="card-body pt-1 text-sm" :class="isDarkMode ? 'bg-black' : 'bg-white'">
            Czy na pewno chcesz usunąć kalendarz?

            <div class="d-flex justify-content-end mt-3">
              <material-button
                :color="color"
                variant="gradient"
                @click="deleteCalendar(calendar.id); closeModal()"
              >
                Potwierdź
              </material-button>

              <material-button
                class="ms-3"
                color="light"
                variant="gradient"
                @click="closeModal()"
              >
                Anuluj
              </material-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-if="me" class="container-fluid">
    <div class="d-flex justify-content-end mb-4">
      <!-- Przycisk: Nowe wydarzenie -->
      <material-button
        :color="color"
        variant="gradient"
        @click="getNewEvent()"
      >
        + &nbsp; Nowe wydarzenie
      </material-button>
    </div>

    <div class="row">
      <div class="col-lg-3">
        <!-- Moje kalendarze -->
        <div class="card mb-4 py-3">
          <div class="card-header py-0 cursor-pointer" @click="calendarsExpansion[0] = !calendarsExpansion[0]">
            <div class="d-flex justify-content-between">
              <h6 class="my-auto">Moje kalendarze</h6>
              <i class="material-symbols-outlined text-md my-auto">
                {{ calendarsExpansion[0] ? "expand_less" : "expand_more" }}
              </i>
            </div>
          </div>
          <div class="card-body pb-0 text-sm" :class="calendarsExpansion[0] ? 'pt-2' : 'pt-0'">
            <div v-if="calendarsExpansion[0]">
              <label class="d-flex align-items-center mb-1 mx-0">
                <material-checkbox class="mx-0 px-0 my-0" checked @change="onChangeStatusAll(true, $event)" />
                <p class="my-auto ms-1 pt-1 text-sm">(Wszystkie)</p>
              </label>

              <div v-for="(item, index) in calendars" :key="item">
                <div v-if="item.owner && item.owner.id === me.id" class="d-flex align-items-center ps-0 mb-1">
                  <label class="d-flex align-items-center mb-0 mx-0">
                    <material-checkbox class="mx-0 px-0 my-auto" :checked="item.status" @change="onChangeStatus(index, $event.target.checked)" />
                    <input class="mx-1 my-auto cursor-pointer" type="color" :value="item.color" @change="patchCalendarColor(item, $event.target.value)">
                    <p class="my-auto ms-1 pt-1 text-sm">{{ item.name }}</p>
                    <i v-if="item.privacy" class="material-symbols-outlined ms-2 text-md pt-1">lock</i>
                  </label>
                  <a
                    class="ms-auto"
                    :class="[color]"
                    data-bs-toggle="dropdown"
                  >
                    <i class="material-icons my-auto cursor-pointer" :class="isDarkMode ? 'text-white' : 'text-dark'">more_vert</i>
                  </a>
                  <ul class="p-2 dropdown-menu dropdown-menu-end me-sm-n0 z-index-5 shadow">
                    <li class="cursor-pointer" @click="getCalendar(item.id, 4)">
                      <a class="px-2 dropdown-item border-radius-md">
                        <div class="mx-0 px-0 py-1 d-flex">
                          <div class="d-flex flex-column justify-content-center">
                            <h6 class="my-auto text-sm font-weight-normal" style="color: #344767 !important">
                              Edycja
                            </h6>
                          </div>
                        </div>
                      </a>
                    </li>
                    <hr v-if="!item.privacy" class="my-1 bg-light">
                    <li v-if="!item.privacy" class="cursor-pointer" @click="getCalendar(item.id, 6)">
                      <a class="px-2 dropdown-item border-radius-md">
                        <div class="mx-0 px-0 py-1 d-flex">
                          <div class="d-flex flex-column justify-content-center">
                            <h6 class="my-auto text-sm font-weight-normal" style="color: #344767 !important">
                              Usuń
                            </h6>
                          </div>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div v-if="calendar && calendar.operation === 'new'" class="d-flex align-items-center">
                <div class="input-group input-group-static">
                  <input
                    v-model="calendar.name"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                    placeholder="Nazwa kalendarza"
                  />
                </div>

                <span class="cursor-pointer" @click="postCalendar()">
                  <i class="material-icons text-lg ms-2" :class="isDarkMode ? 'text-light' : 'text-dark'">
                    add
                  </i>
                </span>

                <span class="cursor-pointer" @click="calendar = null">
                  <i class="material-icons text-lg ms-2" :class="isDarkMode ? 'text-light' : 'text-dark'">
                    close
                  </i>
                </span>
              </div>

              <div v-else class="d-flex align-items-center mt-2 text-sm cursor-pointer" :class="`text-${ color }`" @click="getNewCalendar()">
                <i class="material-symbols-outlined text-lg me-2" :class="`text-${ color }`">add</i>
                Utwórz nowy kalendarz
              </div>
            </div>
          </div>
        </div>

        <!-- Współdzielone kalendarze -->
        <div class="card mb-4 py-3">
          <div class="card-header py-0 cursor-pointer" @click="calendarsExpansion[1] = !calendarsExpansion[1]">
            <div class="d-flex justify-content-between">
              <h6 class="my-auto">Współdzielone kalendarze</h6>
              <i class="material-symbols-outlined text-md my-auto">
                {{ calendarsExpansion[1] ? "expand_less" : "expand_more" }}
              </i>
            </div>
          </div>
          <div class="card-body pb-0 text-sm" :class="calendarsExpansion[1] ? 'pt-2' : 'pt-0'">
            <div v-if="calendarsExpansion[1]">
              <label class="d-flex align-items-center mb-1 mx-0">
                <material-checkbox class="mx-0 px-0 my-0" checked @change="onChangeStatusAll(false, $event)" />
                <p class="my-auto ms-1 pt-1 text-sm">(Wszystkie)</p>
              </label>

              <div v-for="(item, index) in calendars" :key="item">
                <div v-if="item.owner && item.owner.id !== me.id" class="d-flex align-items-center ps-0 mb-1">
                  <label class="d-flex align-items-center mb-0 mx-0">
                    <material-checkbox class="mx-0 px-0 my-auto" :checked="item.status" @change="onChangeStatus(index, $event.target.checked)" />
                    <input class="mx-1 my-auto cursor-pointer" type="color" disabled :value="item.color">
                    <p class="my-auto ms-1 pt-1 text-sm">{{ item.name }}</p>
                  </label>
                  <a
                    class="ms-auto z-index-5"
                    :class="[color]"
                    data-bs-toggle="dropdown"
                  >
                    <i class="material-icons cursor-pointer" :class="isDarkMode ? 'text-white' : 'text-dark'">more_vert</i>
                  </a>
                  <ul class="p-2 dropdown-menu dropdown-menu-end me-sm-n0 z-index-5 shadow">
                    <li class="cursor-pointer" @click="getCalendar(item.id, 5);">
                      <a class="px-2 dropdown-item border-radius-md">
                        <div class="mx-0 px-0 py-1 d-flex">
                          <div class="d-flex flex-column justify-content-center">
                            <h6 class="my-auto text-sm font-weight-normal" style="color: #344767 !important">
                              Podgląd
                            </h6>
                          </div>
                        </div>
                      </a>
                    </li>
                    <hr class="my-1 bg-light">
                    <li class="cursor-pointer" @click="deleteMember(item)">
                      <a class="px-2 dropdown-item border-radius-md">
                        <div class="mx-0 px-0 py-1 d-flex">
                          <div class="d-flex flex-column justify-content-center">
                            <h6 class="my-auto text-sm font-weight-normal" style="color: #344767 !important">
                              Odłącz
                            </h6>
                          </div>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Moje zadania -->
        <div class="card mb-4 py-3">
          <div class="card-header py-0 cursor-pointer" @click="calendarsExpansion[2] = !calendarsExpansion[2]">
            <div class="d-flex justify-content-between">
              <h6 class="my-auto">Moje zadania</h6>
              <i class="material-symbols-outlined text-md my-auto">
                {{ calendarsExpansion[2] ? "expand_less" : "expand_more" }}
              </i>
            </div>
          </div>
          <div class="card-body pb-0 text-sm" :class="calendarsExpansion[2] ? 'pt-2' : 'pt-0'">
            <div v-if="calendarsExpansion[2]">
              <label class="d-flex align-items-center mb-1 mx-0">
                <material-checkbox class="mx-0 px-0 my-0" checked @change="onChangeTaskStatus(true, $event)" />
                <p class="my-auto ms-1 pt-1 text-sm">(Wszystkie)</p>
              </label>

              <div v-for="(item, index) in me.tasksGroups" :key="index">
                <div v-if="item.owner && !item.owner.id" class="d-flex align-items-center ps-0 mb-1">
                  <label class="d-flex align-items-center mb-0 mx-0">
                    <material-checkbox class="mx-0 px-0 my-auto" :checked="item.status" @change="item.status = !item.status; getEvents()" />
                    <input class="mx-1 my-auto cursor-pointer" type="color" :value="item.color" @change="patchTaskColor(item, $event.target.value)">
                    <p class="my-auto ms-1 pt-1 text-sm">{{ item.name }}</p>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Współdzielone zadania -->
        <div class="card mb-4 py-3">
          <div class="card-header py-0 cursor-pointer" @click="calendarsExpansion[3] = !calendarsExpansion[3]">
            <div class="d-flex justify-content-between">
              <h6 class="my-auto">Współdzielone zadania</h6>
              <i class="material-symbols-outlined text-md my-auto">
                {{ calendarsExpansion[3] ? "expand_less" : "expand_more" }}
              </i>
            </div>
          </div>
          <div class="card-body pb-0 text-sm" :class="calendarsExpansion[3] ? 'pt-2' : 'pt-0'">
            <div v-if="calendarsExpansion[3]">
              <label class="d-flex align-items-center mb-1 mx-0">
                <material-checkbox class="mx-0 px-0 my-0" checked @change="onChangeTaskStatus(false, $event)" />
                <p class="my-auto ms-1 pt-1 text-sm">(Wszystkie)</p>
              </label>

              <div v-for="(item, index) in me.tasksGroups" :key="index">
                <div v-if="item.owner && item.owner.id" class="d-flex align-items-center ps-0 mb-1">
                  <label class="d-flex align-items-center mb-0 mx-0">
                    <material-checkbox class="mx-0 px-0 my-auto" :checked="item.status" @change="item.status = !item.status; getEvents()" />
                    <input class="mx-1 my-auto cursor-pointer" type="color" :value="item.color" disabled @change="patchTaskColor(item, $event.target.value)">
                    <p class="my-auto ms-1 pt-1 text-sm">{{ item.name }}</p>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Kalendarz -->
      <div class="col-lg-9">
        <div class="card">
          <div class="card-header pb-1">
            <h5>Kalendarz</h5>
          </div>
          <my-calendar :events="events" />
          <div class="ms-auto text-sm text-secondary mx-3 mb-3 cursor-pointer" @click="print()">Drukuj kalendarz</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MyCalendar from "./components/Calendar.vue"
import MaterialCheckbox from "@/components/MaterialCheckbox.vue"
import MaterialSwitch from "@/components/MaterialSwitch.vue"
import MaterialButton from "@/components/MaterialButton.vue"
import UserDataService from "@/services/UserDataService"
import CalendarDataService from "@/services/CalendarDataService"
import EventDataService from "@/services/EventDataService"
import AttachmentDataService from "@/services/AttachmentDataService"
import TaskGroupDataService from "@/services/TaskGroupDataService"
import SimpleTypeahead from "vue3-simple-typeahead"
import { mapState } from "vuex"

export default {
  name: "Calendar",
  components: {
    MyCalendar,
    MaterialCheckbox,
    MaterialSwitch,
    MaterialButton,
    SimpleTypeahead,
  },
  mixins: [
    MyCalendar
  ],
  data() {
    return {
      me: null, // Obiekt zalogowanego użytkownika

      calendar: null, // Obiekt wybranego kalendarza
      calendars: [], // Tablica kalendarzy zalogowanego użytkownika
      
      calendarUsers: [], // Tablica dostępnych użytkowników
      calendarUsersAC: [], // Tablica imion i nazwisk dostępnych użytkowników

      event: null, // Obiekt tworzonego wydarzenia
      events: [], // Tablica aktywnych wydarzeń

      eventUsers: [], // Tablica dostępnych użytkowników
      eventUsersAC: [], // Tablica imion i nazwisk dostępnych użytkowników

      availableCalendars: [], // Tablica dostępnych kalendarzy

      calendarsExpansion: [true, true, true, true], // Tablica rozwinięcia kalendarzy
      modals: [], // Tablica odpowiedzialna za status modali
    }
  },
  computed: {
    ...mapState(["isDarkMode", "color"])
  },
  created() {
    this.getMe()
  },
  methods: {
    // Funkcja pobierająca dane zalogowanego użytkownika
    getMe() {
      UserDataService.me()
      .then(res => {
        this.getUser(res.data.id)
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja pobierająca dane zalogowanego użytkownika
    getUser(uid) {
      UserDataService.get(uid)
      .then(res => {
        if (res.data.deleted || res.data.status === "Dezaktywowany") {
          this.$logout()
        }
        else {
          this.me = res.data
          this.me.tasksGroups.forEach(item => {
            item.status = true
          })

          this.getCalendars()

          if (this.$store.state.openedEvent) {
            this.getEvent(this.$store.state.openedEvent)
            this.$store.state.openedEvent = null
          }

          if (this.$store.state.createdEvent.operation) {
            this.getNewEvent("", "", false, this.$store.state.createdEvent.attachments)
            this.$store.state.createdEvent.operation = false
            this.$store.state.createdEvent.attachments = []
          }
        }
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja pobierająca kalendarze zalogowanego użytkownika
    getCalendars() {
      CalendarDataService.getAll(`?members.id=${ this.me.id }`)
      .then(res => {
        res.data["hydra:member"].forEach(item => {
          let exist = false

          this.calendars.forEach(calendar => {
            if (item.id === calendar.id) {
              exist = true
              calendar.events = item.events
            }
          })

          if (!exist) {
            item.status = true
            this.calendars.push(item)
          }
        })

        this.getEvents()
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja pobierająca wybrany kalendarz
    getCalendar(id, modal) {
      CalendarDataService.get(id)
      .then(res => {
        this.calendar = res.data
        this.calendar.permission = false
        
        this.calendar.permissions.forEach(permission => {
          this.calendar.members.forEach(member => {
            if (permission.id === member.id) {
              member.permissions = true
            }
          })

          if (permission.id === this.me.id) {
            this.calendar.permission = true
          }
        })

        this.getCalendarUsers()
        this.openModal(modal)
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja tworząca nowy kalendarz
    getNewCalendar() {
      this.calendar = null
      this.calendar = {
        name: "",
        operation: "new",
      }
    },

    // Funkcja dodająca nowy kalendarz
    postCalendar() {
      if (this.calendar.name !== "" && this.calendar.name.length <= 65) {
        CalendarDataService.post(
          {
            name: this.calendar.name,
            color: "#e91e63",
            owner: `/users/${ this.me.id }`,
            members: [`/users/${ this.me.id }`],
            privacy: false,
          }
        )
        .then(res => {
          console.log(res.data)
          this.getCalendars()
          this.calendar = null
        })
        .catch(error => {
          console.log(error)
        })
      }
    },

    // Funkcja pobierająca wszystkich dostępnych użytkowników
    getCalendarUsers() {
      UserDataService.getAll("?deleted=false")
      .then(res => {
        this.calendarUsers = []
        this.calendarUsersAC = []

        res.data["hydra:member"].forEach(item => {
          let add = true

          this.calendar.members.forEach(member => {
            if (item.id === member.id) {
              add = false
            }
          })

          if (add) {
            this.calendarUsers.push(item)
            this.calendarUsersAC.push(item.fullName)
          }
        })
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja aktualizująca wybrany kalendarz
    patchCalendar() {
      if (this.calendar.name !== "" && this.calendar.name.length <= 65) {
        let members = [`/users/${ this.me.id }`]
        let permissions = [`/users/${ this.me.id }`]

        this.calendar.members.forEach(item => {
          members.push(`/users/${ item.id }`)
          if (item.permissions) {
            permissions.push(`/users/${ item.id }`)
          }
        })

        CalendarDataService.patch(this.calendar.id,
          {
            name: this.calendar.name,
            members: members,
            permissions: permissions,
          },
          {
            headers: { "Content-Type": "application/merge-patch+json" }
          }
        )
        .then(res => {
          console.log(res.data)
          this.getCalendars()
          this.closeModal()
        })
        .catch(error => {
          console.log(error)
        })
      }
    },

    // Funkcja zmieniająca kolor kalendarza
    patchCalendarColor(calendar, color) {
      CalendarDataService.patch(calendar.id,
        {
          color: color,
        },
        {
          headers: { "Content-Type": "application/merge-patch+json" }
        }
      )
      .then(res => {
        console.log(res.data)
        calendar.color = color
        this.getEvents()
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja zmieniająca kolor zadań
    patchTaskColor(task, color) {
      TaskGroupDataService.patch(task.id,
        {
          color: color,
        },
        {
          headers: { "Content-Type": "application/merge-patch+json" }
        }
      )
      .then(res => {
        console.log(res.data)
        task.color = color
        this.getEvents()
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja odłączająca mnie od kalendarza
    deleteMember(item) {
      let members = []

      item.members.forEach(member => {
        if (member.id != this.me.id) {
          members.push(`/users/${ member.id }`)
        }
      })

      CalendarDataService.patch(item.id,
        {
          members: members,
        },
        {
          headers: { "Content-Type": "application/merge-patch+json" }
        }
      )
      .then(res => {
        console.log(res.data)
        this.getCalendars()
        this.closeModal()
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja usuwająca wybrany kalendarz
    deleteCalendar(id) {
      CalendarDataService.delete(id)
      .then(res => {
        console.log(res.data)
        this.getCalendars()
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja pobierająca wydarzenia
    getEvents() {
      this.events = []

      this.calendars.forEach(calendar => {
        if (calendar.status) {
          calendar.events.forEach(event => {
            event.permissions = false

            if (calendar.owner.id === this.me.id) {
              event.permissions = true
            }

            calendar.permissions.forEach(item => {
              if (item.id === this.me.id && !event.blocked) {
                event.permissions = true
              }
            })
            
            if (event.repeat !== "never") {
              event.permissions = false
              event.startRecur = event.startDate

              let eventStartDate = this.moment(event.startDate).format("YYYY-MM-DD HH:mm:ss")
              let eventEndRecur = this.moment(event.endRecur).format("YYYY-MM-DD HH:mm:ss")

              // Codziennie
              if (event.repeat === "daily") {
                for(let i = 1; eventStartDate < eventEndRecur; i++) {
                  eventStartDate = this.moment(event.startDate).add(i, "days").format("YYYY-MM-DD HH:mm:ss")
                  if (eventStartDate < eventEndRecur) {
                    this.events.push({
                      id: event.type === "sale" ? event.stageHistory.sale.id : event.id,
                      title: event.title,
                      start: this.moment(event.startDate).add(i, "days").format("YYYY-MM-DD HH:mm:ss"),
                      end: this.moment(event.endDate).add(i, "days").format("YYYY-MM-DD HH:mm:ss"),
                      repeat: event.repeat,
                      allDay: event.allDay,
                      className: "cursor-pointer",
                      backgroundColor: calendar.color,
                      editable: event.type === "event" ? event.permissions : false,
                      type: event.type
                    })
                  }
                }
              }

              // Co tydzień
              if (event.repeat === "weekly") {
                for(let i = 7; eventStartDate < eventEndRecur; i += 7) {
                  eventStartDate = this.moment(event.startDate).add(i, "days").format("YYYY-MM-DD HH:mm:ss")
                  if (eventStartDate < eventEndRecur) {
                    this.events.push({
                      id: event.type === "sale" ? event.stageHistory.sale.id : event.id,
                      title: event.title,
                      start: this.moment(event.startDate).add(i, "days").format("YYYY-MM-DD HH:mm:ss"),
                      end: this.moment(event.endDate).add(i, "days").format("YYYY-MM-DD HH:mm:ss"),
                      repeat: event.repeat,
                      allDay: event.allDay,
                      className: "cursor-pointer",
                      backgroundColor: calendar.color,
                      editable: event.type === "event" ? event.permissions : false,
                      type: event.type
                    })
                  }
                }
              }

              // Co dwa tygodnie
              if (event.repeat === "biweekly") {
                for(let i = 14; eventStartDate < eventEndRecur; i += 14) {
                  eventStartDate = this.moment(event.startDate).add(i, "days").format("YYYY-MM-DD HH:mm:ss")
                  if (eventStartDate < eventEndRecur) {
                    this.events.push({
                      id: event.type === "sale" ? event.stageHistory.sale.id : event.id,
                      title: event.title,
                      start: this.moment(event.startDate).add(i, "days").format("YYYY-MM-DD HH:mm:ss"),
                      end: this.moment(event.endDate).add(i, "days").format("YYYY-MM-DD HH:mm:ss"),
                      repeat: event.repeat,
                      allDay: event.allDay,
                      className: "cursor-pointer",
                      backgroundColor: calendar.color,
                      editable: event.type === "event" ? event.permissions : false,
                      type: event.type
                    })
                  }
                }
              }

              // Co miesiąc
              if (event.repeat === "monthly") {
                for(let i = 1; eventStartDate < eventEndRecur; i += 1) {
                  eventStartDate = this.moment(event.startDate).add(i, "months").format("YYYY-MM-DD HH:mm:ss")
                  if (eventStartDate < eventEndRecur) {
                    this.events.push({
                      id: event.type === "sale" ? event.stageHistory.sale.id : event.id,
                      title: event.title,
                      start: this.moment(event.startDate).add(i, "months").format("YYYY-MM-DD HH:mm:ss"),
                      end: this.moment(event.endDate).add(i, "months").format("YYYY-MM-DD HH:mm:ss"),
                      repeat: event.repeat,
                      allDay: event.allDay,
                      className: "cursor-pointer",
                      backgroundColor: calendar.color,
                      editable: event.type === "event" ? event.permissions : false,
                      type: event.type
                    })
                  }
                }
              }

              // Co rok
              if (event.repeat === "yearly") {
                for(let i = 1; eventStartDate < eventEndRecur; i++) {
                  eventStartDate = this.moment(event.startDate).add(i, "years").format("YYYY-MM-DD HH:mm:ss")
                  if (eventStartDate < eventEndRecur) {
                    this.events.push({
                      id: event.type === "sale" ? event.stageHistory.sale.id : event.id,
                      title: event.title,
                      start: this.moment(event.startDate).add(i, "years").format("YYYY-MM-DD HH:mm:ss"),
                      end: this.moment(event.endDate).add(i, "years").format("YYYY-MM-DD HH:mm:ss"),
                      repeat: event.repeat,
                      allDay: event.allDay,
                      className: "cursor-pointer",
                      backgroundColor: calendar.color,
                      editable: event.type === "event" ? event.permissions : false,
                      type: event.type
                    })
                  }
                }
              }
            }

            this.events.push({
              id: event.type === "sale" ? event.stageHistory.sale.id : event.id,
              title: event.title,
              start: event.startDate,
              end: event.endDate,
              repeat: event.repeat,
              allDay: event.allDay,
              className: "cursor-pointer",
              backgroundColor: calendar.color,
              editable: event.type === "event" ? event.permissions : false,
              type: event.type
            })
          })
        }
      })

      this.me.tasksGroups.forEach(item => {
        if (item.status) {
          item.tasks.forEach(task => {
            this.events.push({
              id: task.id,
              title: task.name,
              start: task.closedAt,
              end: task.closedAt,
              allDay: true,
              className: "cursor-pointer",
              backgroundColor: item.color,
              editable: false,
              type: "task",
              icon: task.status
            })
          })
        }
      })

      this.createCalendar("650px", true, this.getView(), this.getDate())
    },

    // Funkcja pobierająca wydarzenie
    getEvent(id) {
      EventDataService.get(id)
      .then(res => {
        this.event = res.data
        this.event.calendar.id = res.data.calendar.id

        if (this.event.allDay) {
          this.event.startDate = this.moment(this.event.startDate).format("YYYY-MM-DD")
          this.event.endDate = this.moment(this.event.endDate).format("YYYY-MM-DD")
        }

        this.event.permissions = false
        this.event.calendar.permissions.forEach(item => {
          if (this.me.id === item.id) {
            this.event.permissions = true
          }
        })

        this.getEventUsers(this.event.calendar.id)
        this.getAvailableCalendars()

        this.event.newAttachments = []
        this.event.removedAttachments = []

        this.event.error = ""
        
        this.openModal(2)
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja tworząca nowe wydarzenie
    getNewEvent(startDate = "", endDate = "", allDay = false, attachments = []) {
      this.event = null
      this.event = {
        title: "",
        descritpion: "",
        startDate: startDate,
        endDate: endDate,
        repeat: "never",
        endRecur: null,
        allDay: allDay,
        calendar: "",
        members: [],
        reminder: "",
        blocked: false,
        attachments: attachments,
        error: "",
      }

      this.getAvailableCalendars()

      this.openModal(0)
    },

    // Funkcja sprawdzająca poprawność wpisanych danych
    validationPostEvent() {
      let success = true
      this.event.error = ""

      if (this.event.title === "") {
        success = false
        this.event.error = "Niepoprawna nazwa wydarzenia!"
      }

      if (this.event.title.length > 65) {
        success = false
        this.event.error = "Nazwa wydarzenia jest za długa (max. 65 znaków)"
      }

      if (this.event.startDate === "") {
        success = false
        this.event.error = "Podaj początek wydarzenia!"
      }

      if (this.event.endDate === "") {
        success = false
        this.event.error = "Podaj koniec wydarzenia!"
      }

      if (this.event.startDate > this.event.endDate) {
        success = false
        this.event.error = "Początkowa data nie może być mniejsza od końcowej!"
      }

      if (this.event.calendar === "") {
        success = false
        this.event.error = "Wybierz kalendarz!"
      }

      if (success) {
        this.postEvent()
      }
    },

    // Funkcja dodawająca nowe wydarzenie
    postEvent() {
      EventDataService.post(this.event)
      .then(res => {
        console.log(res.data)

        this.event.attachments.forEach(item => {
          this.postAttachment(res.data.id, item)
        })

        this.event = null
        this.getCalendars()
        this.closeModal()
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja sprawdzająca poprawność wpisanych danych
    validationPatchEvent() {
      let success = true
      this.event.error = ""

      if (this.event.title === "") {
        success = false
        this.event.error = "Niepoprawna nazwa wydarzenia!"
      }

      if (this.event.title.length > 65) {
        success = false
        this.event.error = "Nazwa wydarzenia jest za długa (max. 65 znaków)"
      }

      if (this.event.startDate === "") {
        success = false
        this.event.error = "Podaj początek wydarzenia!"
      }

      if (this.event.endDate === "") {
        success = false
        this.event.error = "Podaj koniec wydarzenia!"
      }

      if (this.event.startDate > this.event.endDate) {
        success = false
        this.event.error = "Początkowa data nie może być mniejsza od końcowej!"
      }

      if (this.event.calendar === "") {
        success = false
        this.event.error = "Wybierz kalendarz!"
      }

      if (success) {
        this.patchEvent()
      }
    },

    // Funkcja zmieniająca dane wydarzenia
    patchEvent() {
      EventDataService.patch(this.event.id, this.event,
        {
          headers: { "Content-Type": "application/merge-patch+json" }
        }
      )
      .then(res => {
        console.log(res.data)

        this.event.newAttachments.forEach(item => {
          this.postAttachment(this.event.id, item)
        })

        this.event = null
        this.getCalendars()
        this.closeModal()
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja pobierająca wszystkich dostępnych użytkowników
    getEventUsers(id) {
      UserDataService.getAll(`?calendars.id=${ id }`)
      .then(res => {
        this.eventUsers = []
        this.eventUsersAC = []

        res.data["hydra:member"].forEach(user => {
          let add = true

          if (this.event) {
              this.event.members.forEach(item => {
                if (user.id === item.id) {
                  add = false
                }
              })
            }

          if (add) {
            this.eventUsers.push(user)
            this.eventUsersAC.push(user.fullName)
          }
        })
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja pobierająca z API zapisywalne kalendarze użytkownika
    getAvailableCalendars() {
      CalendarDataService.getAll(`?owner.id=${ this.me.id }`)
      .then (res => {
        this.availableCalendars = []

        res.data["hydra:member"].forEach(item => {
          this.availableCalendars.push(item)
        })

        CalendarDataService.getAll(`?permissions.id=${ this.me.id }`)
        .then (response => {
          response.data["hydra:member"].forEach(item => {
            let exist = false

            this.availableCalendars.forEach(calendar => {
              if (item.id === calendar.id) {
                exist = true
              }
            })
            
            if (!exist) {
              this.availableCalendars.push(item)
            }
          })
        })
        .catch(error => {
          console.log(error)
        })
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja usuwająca wydarzenie
    deleteEvent(id) {
      EventDataService.delete(id)
      .then(res => {
        console.log(res.data)
        this.getCalendars()
        this.closeModal()
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja dodająca załącznik do listy
    createAttachment(array, event) {
      array.push(event.target.files[0])
    },

    // Funkcja tworząca nowy załącznik
    postAttachment(id, item) {
      const formData = new FormData()
      formData.append("file", item)
      formData.append("entity", "Event")
      formData.append("entityId", id)

      AttachmentDataService.post(formData,
        {
          headers: { "Content-Type": "multipart/form-data" }
        }
      )
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja wysyłająca zaproszenie do wydarzenia
    createMessage() {
      this.$store.state.mail = {
        id: null,
        from: "",
        to: "",
        subject: `Zaproszenie ${ this.event.title }`,
        text: `${ this.event.title } / ${ this.event.description !== "" ? this.event.description : "Brak" } / ${ this.event.startDate } - ${ this.event.endDate }`,
        attachments: [],
        accountId: null,
      }

      this.$redirect("Nowa wiadomość")
    },

    // Funkcja ustawiająca końcową date
    setEndDate() {
      if (this.event.endDate === "") {
        this.event.endDate = this.moment(this.event.startDate).add(30, "minutes").toString()
      }
    },

    // Funkcja zmieniająca status kalendarza
    onChangeStatus(i, event) {
      this.calendars[i].status = event
      this.getEvents()
    },

    // Funkcja zmieniająca status wszystkich kalendarzy
    onChangeStatusAll(type, e) {
      if (type) {
        this.calendars.forEach(item => {
          if (item.owner && item.owner.id === this.me.id) {
            item.status = e.target.checked
          }
        })
      }
      else {
        this.calendars.forEach(item => {
          if (item.owner && item.owner.id !== this.me.id) {
            item.status = e.target.checked
          }
        })
      }

      this.getEvents()
    },

    // Funkcja zmieniająca status wszystkich zadań
    onChangeTaskStatus(type, e) {
      if (type) {
        this.me.tasksGroups.forEach(item => {
          if (item.owner && !item.owner.id) {
            item.status = e.target.checked
          }
        })
      }
      else {
        this.me.tasksGroups.forEach(item => {
          if (item.owner && item.owner.id) {
            item.status = e.target.checked
          }
        })
      }

      this.getEvents()
    },

    // Funkcja dodająca nowego użytkownika do kalendarza
    selectCalendarUser(user) {
      this.calendarUsers.forEach((item, index) => {
        if (item.fullName === user) {
          item.permissions = false
          this.calendar.members.push(item)
          this.calendarUsersAC.splice(index, 1)
          this.calendarUsers.splice(index, 1)
        }
      })

      this.$refs.calendarUsersAC.clearInput()
    },

    // Funkcja dodająca nowego użytkownika do wydarzenia
    selectEventUser(user) {
      this.eventUsers.forEach((item, index) => {
        if (item.fullName === user) {
          this.event.members.push(item)
          this.eventUsers.splice(index, 1)
          this.eventUsersAC.splice(index, 1)
        }
      })

      this.$refs.eventUsersAC.clearInput()
    },

    // Funkcja otwierająca okno
    openModal(x) {
      for (let i = 0; i < this.modals.length; i++) {
        this.modals[i] = false
      }

      document.getElementById("modal").classList.add("d-block")
      this.modals[x] = true
    },

    // Funkcja zamykająca okno
    closeModal() {
      document.getElementById("modal").classList.remove("d-block")
      for(let i = 0; i < this.modals.length; i++) {
        this.modals[i] = false
      }

      this.unselect()
    }
  }
}
</script>

<style scoped>
* {
  opacity: 1 !important;
}

.modal {
  background: rgb(0, 0, 0, 0.5);
  z-index: 10000 !important;
}
.modal-content {
  background: none;
  border: none;
}

select, input {
  background: none;
  border: none;
  outline: none;
  border-bottom: 1px solid;
}

select {
  width: 100%;
}

select option {
  color: black !important;
}

.simple-typeahead {
  color: #000;
}

input[type="color"], input[type="color"]::-webkit-color-swatch {
  width: 21px;
  height: 18px;
  border-radius: 50%;
  border: none !important;
  outline: none !important;
}
</style>