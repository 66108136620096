import http from "@/http-common"

class EdiTypeExportDataService {
    getAll(url) {
        return http.get(`edi_type_exports${ url }`)
    }

    get(id) {
        return http.get(`edi_type_exports/${ id }`)
    }

    post(data) {
        return http.post("edi_type_exports", data)
    }

    patch(id, data, headers) {
        return http.patch(`edi_type_exports/${ id }`, data, headers)
    }

    delete(id) {
        return http.delete(`edi_type_exports/${ id }`)
    }
}

export default new EdiTypeExportDataService()