import http from "@/http-common"

class LoginDataService {
    login(data) {
        return http.post("login", data)
    }

    me(token) {
        return http.get("me", token)
    }

    logout(token) {
        return http.post("logout", token)
    }
}

export default new LoginDataService()