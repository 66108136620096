<template>
  <!-- Modal -->
  <div id="modal" class="modal show" tabindex="-1" role="dialog">
    <!-- Modal: Nowy typ importu -->
    <div v-if="modals[0]" class="modal-dialog">
      <div class="modal-content">
        <div class="card px-2 py-1">
          <div class="card-header pb-1" :class="isDarkMode ? `bg-black text-light` : `bg-white text-dark`">
            <div class="d-flex align-items-center justify-content-between">
              <h5 class="my-auto">Nowy typ importu</h5>
              <span class="my-auto">
                <i class="material-symbols-outlined text-lg cursor-pointer" @click="closeModal()">close</i>
              </span>
            </div>
          </div>
          <div class="card-body pt-1" :class="isDarkMode ? `bg-black` : `bg-white`">
            <div class="row mb-2">
              <div class="col-12">
                <label class="form-label ms-0 text-xs">Nazwa</label>
                <input
                  v-model="importType.name"
                  class="w-100 px-3 py-2 text-sm"
                  :class="isDarkMode ? 'text-white' : 'bg-light text-dark'"
                  style="border: none; border-radius: 10px"
                  placeholder="Nazwa"
                />
              </div>
            </div>

            <div class="row mb-2">
              <div class="col-12">
                <label class="form-label ms-0 text-xs">URL</label>
                <input
                  v-model="importType.url"
                  class="w-100 px-3 py-2 text-sm"
                  :class="isDarkMode ? 'text-white' : 'bg-light text-dark'"
                  style="border: none; border-radius: 10px"
                  placeholder="URL"
                />
              </div>
            </div>

            <div class="row mb-2">
              <div class="col-12">
                <label class="form-label ms-0 text-xs">Nazwa użytkownika</label>
                <input
                  v-model="importType.username"
                  class="w-100 px-3 py-2 text-sm"
                  :class="isDarkMode ? 'text-white' : 'bg-light text-dark'"
                  style="border: none; border-radius: 10px"
                  placeholder="Nazwa użytkownika"
                />
              </div>
            </div>

            <div class="row mb-2">
              <div class="col-12">
                <label class="form-label ms-0 text-xs">Hasło</label>
                <input
                  v-model="importType.password"
                  class="w-100 px-3 py-2 text-sm"
                  :class="isDarkMode ? 'text-white' : 'bg-light text-dark'"
                  style="border: none; border-radius: 10px"
                  placeholder="Hasło"
                />
              </div>
            </div>

            <div class="row mb-2">
              <div class="col-12">
                <label class="form-label ms-0 text-xs">Kontroler</label>
                <input
                  v-model="importType.controller"
                  class="w-100 px-3 py-2 text-sm"
                  :class="isDarkMode ? 'text-white' : 'bg-light text-dark'"
                  style="border: none; border-radius: 10px"
                  placeholder="Kontroler"
                />
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-12">
                <label class="d-flex align-items-center">
                  <material-checkbox
                    :checked="importType.customUrl"
                    @change="importType.customUrl = !importType.customUrl"
                  />
                  <span class="ms-1 text-sm">Spersonalizowany URL</span>
                </label>
              </div>
            </div>
            
            <div class="d-flex justify-content-end mt-3">
              <material-button
                :color="color"
                variant="gradient"
                @click="postImportType()"
              >
                Stwórz
              </material-button>

              <material-button
                class="ms-3"
                color="light"
                variant="gradient"
                @click="closeModal()"
              >
                Anuluj
              </material-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal: Edycja typu importu -->
    <div v-if="modals[1]" class="modal-dialog">
      <div class="modal-content">
        <div class="card px-2 py-1">
          <div class="card-header pb-1" :class="isDarkMode ? `bg-black text-light` : `bg-white text-dark`">
            <div class="d-flex align-items-center justify-content-between">
              <h5 class="my-auto">Edycja typu importu</h5>
              <span class="my-auto">
                <i class="material-symbols-outlined text-lg cursor-pointer" @click="closeModal()">close</i>
              </span>
            </div>
          </div>
          <div class="card-body pt-1" :class="isDarkMode ? `bg-black` : `bg-white`">
            <div class="row mb-2">
              <div class="col-12">
                <label class="form-label ms-0 text-xs">Nazwa</label>
                <input
                  v-model="importType.name"
                  class="w-100 px-3 py-2 text-sm"
                  :class="isDarkMode ? 'text-white' : 'bg-light text-dark'"
                  style="border: none; border-radius: 10px"
                  placeholder="Nazwa"
                />
              </div>
            </div>

            <div class="row mb-2">
              <div class="col-12">
                <label class="form-label ms-0 text-xs">URL</label>
                <input
                  v-model="importType.url"
                  class="w-100 px-3 py-2 text-sm"
                  :class="isDarkMode ? 'text-white' : 'bg-light text-dark'"
                  style="border: none; border-radius: 10px"
                  placeholder="URL"
                />
              </div>
            </div>

            <div class="row mb-2">
              <div class="col-12">
                <label class="form-label ms-0 text-xs">Nazwa użytkownika</label>
                <input
                  v-model="importType.username"
                  class="w-100 px-3 py-2 text-sm"
                  :class="isDarkMode ? 'text-white' : 'bg-light text-dark'"
                  style="border: none; border-radius: 10px"
                  placeholder="Nazwa użytkownika"
                />
              </div>
            </div>

            <div class="row mb-2">
              <div class="col-12">
                <label class="form-label ms-0 text-xs">Hasło</label>
                <input
                  v-model="importType.password"
                  class="w-100 px-3 py-2 text-sm"
                  :class="isDarkMode ? 'text-white' : 'bg-light text-dark'"
                  style="border: none; border-radius: 10px"
                  placeholder="Hasło"
                />
              </div>
            </div>

            <div class="row mb-2">
              <div class="col-12">
                <label class="form-label ms-0 text-xs">Kontroler</label>
                <input
                  v-model="importType.controller"
                  class="w-100 px-3 py-2 text-sm"
                  :class="isDarkMode ? 'text-white' : 'bg-light text-dark'"
                  style="border: none; border-radius: 10px"
                  placeholder="Kontroler"
                />
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-12">
                <label class="d-flex align-items-center">
                  <material-checkbox
                    :checked="importType.customUrl"
                    @change="importType.customUrl = !importType.customUrl"
                  />
                  <span class="ms-1 text-sm">Spersonalizowany URL</span>
                </label>
              </div>
            </div>
            
            <div class="d-flex justify-content-end mt-3">
              <material-button
                :color="color"
                variant="gradient"
                @click="patchImportType()"
              >
                Stwórz
              </material-button>

              <material-button
                class="ms-3"
                color="light"
                variant="gradient"
                @click="closeModal()"
              >
                Anuluj
              </material-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-if="me" class="container-fluid">
    <div class="d-flex justify-content-end mb-4">
      <!-- Przycisk: Nowy typ importu -->
      <material-button
        class="d-flex align-items-center"
        :color="color"
        variant="gradient"
        @click="createImportType()"
      >
        + &nbsp; Nowy typ importu
      </material-button>
    </div>

    <div class="row">
      <div class="col-12 mb-4">
        <div class="card">
          <div class="card-header pb-1">
            <h5>Typy importu</h5>
          </div>
          <div class="card-body p-0">
            <div class="table-responsive">
              <div class="dataTable-wrapper dataTable-loading no-footer searchable fixed-height fixed-columns">
                <div class="dataTable-container">
                  <table class="table table-flush dataTable-table">
                    <thead class="thead-light">
                      <!-- Kolumny -->
                      <tr>
                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder" style="width: 5%">ID</th>
                        <th class="ps-0 text-uppercase text-secondary text-xxs font-weight-bolder">Nazwa</th>
                        <th class="ps-0 text-uppercase text-secondary text-xxs font-weight-bolder">URL</th>
                        <th class="ps-0 text-uppercase text-secondary text-xxs font-weight-bolder">Kontroler</th>
                        <th class="ps-0 text-uppercase text-secondary text-xxs font-weight-bolder" style="width: 5%"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in importTypes" :key="item">
                        <!-- Tabela: ID -->
                        <td>
                          <p class="m-0 text-xs">#{{ item.id }}</p>
                        </td>

                        <!-- Tabela: Nazwa -->
                        <td class="ps-0">
                          <p class="m-0 text-xs">{{ item.name }}</p>
                        </td>

                        <!-- Tabela: URL -->
                        <td class="ps-0">
                          <p v-if="item.url" class="m-0 text-xs">
                            <a :href="item.url" target="_blank">{{ item.url }}</a>
                          </p>
                          <p v-else class="m-0 text-xs">Brak</p>
                        </td>

                        <!-- Tabela: Kontroler -->
                        <td class="ps-0">
                          <p class="m-0 text-xs">{{ item.controller }}</p>
                        </td>

                        <!-- Tabela: Menu -->
                        <td class="text-xs text-end">
                          <a
                            :class="[color]"
                            data-bs-toggle="dropdown"
                          >
                            <i class="material-icons cursor-pointer">more_vert</i>
                          </a>
                          <ul
                            class="p-2 dropdown-menu dropdown-menu-end me-sm-n0 z-index-3 shadow"
                          >
                            <li class="cursor-pointer" @click="getImportType(item.id)">
                              <a class="px-2 dropdown-item border-radius-md">
                                <div class="mx-0 px-0 py-1 d-flex">
                                  <div class="d-flex flex-column justify-content-center">
                                    <h6 class="my-auto text-sm font-weight-normal" style="color: #344767 !important">
                                      Edycja
                                    </h6>
                                  </div>
                                </div>
                              </a>
                            </li>
                            <hr class="horizontal my-1 bg-light">
                            <li class="cursor-pointer" @click="deleteImportType(item.id)">
                              <a class="px-2 dropdown-item border-radius-md">
                                <div class="mx-0 px-0 py-1 d-flex">
                                  <div class="d-flex flex-column justify-content-center">
                                    <h6 class="my-auto text-sm font-weight-normal" style="color: #344767 !important">
                                      Usuń
                                    </h6>
                                  </div>
                                </div>
                              </a>
                            </li>
                          </ul>
                        </td>
                      </tr>

                      <!-- Brak typów importu -->
                      <tr :class="importTypes.length > 0 ? 'd-none' : ''">
                        <td class="dataTables-empty text-sm pb-2" colspan="5">Nie znaleziono typów importu</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MaterialCheckbox from "@/components/MaterialCheckbox.vue"
import MaterialButton from "@/components/MaterialButton.vue"
import UserDataService from "@/services/UserDataService"
import EdiTypeImportDataService from "@/services/EdiTypeImportDataService"
import { mapState } from "vuex"

export default {
  name: "ImportTypes",
  components: {
    MaterialCheckbox,
    MaterialButton,
  },
  data() {
    return {
      me: null, // Obiekt obecnie zalogowanego użytkownika

      importType: null, // Obiekt typu importu
      importTypes: [], // Tablica wszystkich typów importu

      modals: [], // Tablica obecnych modali
    }
  },
  computed: {
    ...mapState(["isDarkMode", "color"])
  },
  created() {
    this.getMe()
  },
  methods: {
    // Funkcja pobierająca dane zalogowanego użytkownika
    getMe() {
      UserDataService.me()
      .then(res => {
        this.getUser(res.data.id)
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja pobierająca dane zalogowanego użytkownika
    getUser(id) {
      UserDataService.get(id)
      .then(res => {
        if (res.data.deleted) {
          this.$logout()
        }
        else {
          this.me = res.data
          this.getImportTypes()
        }
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja wczytuje obiekt wybranego typu importu
    getImportType(id) {
      EdiTypeImportDataService.get(id)
      .then(res => {
        this.importType = res.data
        this.openModal(1)
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja tworząca obiekt typu importu
    createImportType() {
      this.importType = null
      this.importType = {
        name: "",
        url: "",
        username: "",
        password: "",
        controller: "",
        customUrl: false
      }

      this.openModal(0)
    },

    // Funkcja tworząca nowy typ importu
    postImportType() {
      EdiTypeImportDataService.post(this.importType)
      .then(res => {
        console.log(res.data)
        this.getImportTypes()
        this.closeModal()
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja edytująca wybrany typ importu
    patchImportType() {
      EdiTypeImportDataService.patch(this.importType.id, this.importType,
        {
          headers: { "Content-Type": "application/merge-patch+json" }
        }
      )
      .then(res => {
        console.log(res.data)
        this.importType = null
        this.getImportTypes()
        this.closeModal()
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja usuwająca typ importu
    deleteImportType(id) {
      EdiTypeImportDataService.delete(id)
      .then(res => {
        console.log(res.data)
        this.getImportTypes()
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja pobierająca wszystkie typy importu
    getImportTypes() {
      EdiTypeImportDataService.getAll("")
      .then(res => {
        this.importTypes = res.data["hydra:member"]
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja otwierająca okno
    openModal(x) {
      for (let i = 0; i < this.modals.length; i++) {
        this.modals[i] = false
      }

      document.getElementById("modal").classList.add("d-block")
      this.modals[x] = true
    },

    // Funkcja zamykająca okno
    closeModal() {
      document.getElementById("modal").classList.remove("d-block")
      for (let i = 0; i < this.modals.length; i++) {
        this.modals[i] = false
      }
    }
  }
}
</script>

<style scoped>
* {
  opacity: 1 !important;
}

.modal {
  background: rgb(0, 0, 0, 0.5);
  z-index: 10000 !important;
}

.modal-content {
  background: none;
  border: none;
}

select, input {
  background: none;
  border: none;
  outline: none;
  border-bottom: 1px solid;
}

select option {
  color: black !important;
}
</style>