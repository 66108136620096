<template>
  <div v-if="me && group" class="container-fluid">
    <div class="d-flex mb-4">
      <!-- Przycisk: Powrót -->
      <material-button
        :color="color"
        variant="gradient"
        @click="$redirect('Grupy')"
      >
        <i class="material-icons me-2">keyboard_backspace</i> Powrót
      </material-button>
    </div>
    
    <div class="row">
      <div class="col-lg-3">
        <div class="card position-sticky top-1 mb-4">
          <ul class="nav flex-column bg-white border-radius-lg p-3">
            <li class="nav-item">
              <a class="nav-link text-dark d-flex align-items-center" data-scroll href="#info" :class="isDarkMode ? 'text-white' : 'text-dark'">
                <i class="material-symbols-outlined text-lg me-2">receipt</i>
                <span class="text-sm">Informacje ogólne</span>
              </a>
            </li>
            <li class="nav-item pt-2">
              <a class="nav-link text-dark d-flex align-items-center" data-scroll href="#members" :class="isDarkMode ? 'text-white' : 'text-dark'">
                <i class="material-symbols-outlined text-lg me-2">person</i>
                <span class="text-sm">Przypisani użytkownicy</span>
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div class="col-lg-9">
        <!-- Informacje ogólne -->
        <div id="info" class="card mb-4">
          <div class="card-header pb-1">
            <h5>Informacje ogólne</h5>
          </div>
          <div class="card-body pt-1">
            <div class="row col-md-6">
              <label class="form-label ms-0 mb-0 font-weight-bold">
                Nazwa: <span class="font-weight-normal ms-2">{{ group.name }}</span>
              </label>
            </div>
          </div>
        </div>

        <!-- Przypisani użytkownicy -->
        <div id="members" class="card mb-4 z-index-2">
          <div class="card-header pb-1">
            <h5>Przypisani użytkownicy</h5>
          </div>
          <div class="card-body pt-1">
            <div v-if="group.members.length === 0" class="d-flex align-items-center text-sm">Brak podłączonych użytkowników</div>
            <div v-for="(item, index) in group.members" v-else :key="item" class="d-flex align-items-center" :class="index !== group.members.length - 1 ? 'mb-2' : ''">
              <i class="material-symbols-outlined me-2">person</i>
              <span class="text-sm">{{ item.fullName }}</span>
            </div>
          </div>
        </div>

        <div class="d-flex justify-content-end">
          <material-button
            :color="color"
            variant="gradient"
            @click="$redirectID('Edycja grupy', group.id)"
          >
            Edytuj
          </material-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MaterialButton from "@/components/MaterialButton.vue"
import UserDataService from "@/services/UserDataService"
import UserGroupDataService from "@/services/UserGroupDataService"
import { mapState } from "vuex"

export default {
  name: "OverviewGroup",
  components: {
    MaterialButton,
  },
  data() {
    return {
      me: null, // Obiekt zalogowanego użytkownika

      group: null, // Obiekt edytowanej grupy
      members: [], // Tablica użytkowników należących do tej grupy
    }
  },
  computed: {
    ...mapState(["isDarkMode", "color"])
  },
  created() {
    this.getMe()
  },
  methods: {
    // Funkcja sprawdzająca czy użytkownik ma uprawnienia
    getMe() {
      UserDataService.me()
      .then(res => {
        this.getUser(res.data.id)
      })
      .catch(error => {
        console.log(error)
        
        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja sprwadzająca status użytkownika
    getUser(id) {
      UserDataService.get(id)
      .then(res => {
        if (res.data.deleted || res.data.status === "Dezaktywowany") {
          this.$logout()
        }
        else {
          this.me = res.data
          this.getGroup()
        }
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja pobierająca dane edytowanej grupy
    getGroup() {
      UserGroupDataService.get(this.$route.params.id)
      .then(res => {
        this.group = res.data
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },
  }
}
</script>

<style scoped>
* {
  opacity: 1 !important;
}
</style>