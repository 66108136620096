import http from "@/http-common"

class CustomerDataService {
    getAll(url) {
        return http.get(`customers${ url }`)
    }

    get(id) {
        return http.get(`customers/${ id }`)
    }

    post(data) {
        return http.post("customers", data)
    }

    patch(id, data, headers) {
        return http.patch(`customers/${ id }`, data, headers)
    }

    reset_password(id, data, headers) {
        return http.patch(`customers/${ id }/reset-password`, data, headers)
    }

    delete(id) {
        return http.delete(`customers/${ id }`)
    }
}

export default new CustomerDataService()