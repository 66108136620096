<template>
  <nav aria-label="breadcrumb">
    <ol
      class="px-0 pt-1 pb-0 mb-0 me-sm-6 bg-transparent breadcrumb"
    >
      <li class="text-sm breadcrumb-item" :class="color">
        <a :class="color" class="opacity-8" href="/home/dashboard">
          <i class="material-icons">
            home
          </i>
        </a>
      </li>
      <li
        class="text-sm breadcrumb-item active"
        :class="color"
        aria-current="page"
      >
        {{ currentPage }}
      </li>
    </ol>
  </nav>
</template>

<script>
export default {
  name: "Breadcrumbs",
  props: {
    currentPage: {
      type: String,
      default: "",
      required: true
    },
    color: {
      type: String,
      default: "text-dark"
    }
  }
}
</script>