import http from "@/http-common"

class SessionDataService {
    getAll(url) {
        return http.get(`sessions${ url }`)
    }

    post(data, token) {
        return http.post("sessions", data, token)
    }
}

export default new SessionDataService()