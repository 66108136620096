<template>
  <div v-if="me && app && app.length !== 0" class="container-fluid">
    <div class="row">
      <div class="col-lg-3">
        <div class="card position-sticky top-1 mb-4">
          <ul class="nav flex-column bg-white border-radius-lg p-3">
            <li class="nav-item">
              <a class="nav-link text-dark d-flex align-items-center" data-scroll href="#info" :class="isDarkMode ? 'text-white' : 'text-dark'">
                <i class="material-symbols-outlined text-lg me-2">receipt</i>
                <span class="text-sm">Informacje</span>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link text-dark d-flex align-items-center" data-scroll href="#logotype" :class="isDarkMode ? 'text-white' : 'text-dark'">
                <i class="material-symbols-outlined text-lg me-2">image</i>
                <span class="text-sm">Logotyp</span>
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div class="col-lg-9">
        <!-- Informacje -->
        <div id="info" class="card mb-4">
          <div class="card-header pb-1">
            <h5>Informacje</h5>
          </div>
          <div class="card-body pt-1">
            <div class="row">
              <div class="col-md-6 mb-3">
                <div class="input-group input-group-static">
                  <label class="d-flex">
                    {{ app[0].description }}
                    <i class="material-icons text-sm ms-1 text-warning">star</i>
                  </label>
                  <input
                    v-model="app[0].value"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                    :placeholder="app[0].description"
                  />
                </div>
              </div>

              <div class="col-md-6 mb-3">
                <div class="input-group input-group-static">
                  <label class="d-flex">
                    {{ app[1].description }}
                    <i class="material-icons text-sm ms-1 text-warning">star</i>
                  </label>
                  <input
                    v-model="app[1].value"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                    disabled
                    :placeholder="app[1].description"
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6 mb-3">
                <div class="input-group input-group-static">
                  <label class="d-flex">
                    {{ app[2].description }}
                    <i class="material-icons text-sm ms-1 text-warning">star</i>
                  </label>
                  <input
                    v-model="app[2].value"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                    :placeholder="app[2].description"
                  />
                </div>
              </div>

              <div class="col-md-6 mb-3">
                <div class="input-group input-group-static">
                  <label class="d-flex">
                    {{ app[3].description }}
                    <i class="material-icons text-sm ms-1 text-warning">star</i>
                  </label>
                  <input
                    v-model="app[3].value"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                    :placeholder="app[3].description"
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <div class="input-group input-group-static">
                  <label class="d-flex">
                    {{ app[4].description }}
                    <i class="material-icons text-sm ms-1 text-warning">star</i>
                  </label>
                  <input
                    v-model="app[4].value"
                    v-mask="'##-###'"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                    :placeholder="app[4].description"
                  />
                </div>
              </div>
            </div>

            <p v-if="error !== ''" class="w-100 mt-2 text-sm font-weight-bold text-end text-danger">{{ error }}</p>
          </div>
        </div>

        <!-- Logotyp -->
        <div id="logotype" class="card mb-4">
          <div class="card-header d-flex align-items-center pb-1">
            <h5>Logotyp</h5>
            <label class="d-flex align-items-center w-100 font-weight-bold cursor-pointer" :class="`text-${ color }`">
              <i class="material-symbols-outlined text-lg me-1">upload</i>
              Załącz plik
              <input type="file" class="d-none" accept="image/jpeg, image/png" @change="createAttachment($event)">
            </label>
          </div>
          <div class="card-body py-1" :class="app[7].value || attachment ? 'mb-4' : ''">
            <div class="row">
              <div class="col-12">
                <img v-if="attachmentBlob" :src="attachmentBlob" width="256">
                <img v-else :src="'data:image/jpeg;base64,' + app[7].value" width="256">
              </div>
            </div>
          </div>
        </div>

        <div class="d-flex justify-content-end">
          <material-button
            :color="color"
            variant="gradient"
            @click="validation()"
          >
            Zapisz
          </material-button>
        </div>
      </div>
    </div>

    <!-- Snackbar -->
    <div class="position-fixed ms-1 bottom-2 left-1 z-index-2">
      <material-snackbar
        v-if="snackbar"
        description="Zmiany zostały zapisane pomyślnie!"
        title="snackbar"
        color="success"
        :close-handler="closeSnackbar"
      />
    </div>
  </div>
</template>

<script>
import MaterialSnackbar from "@/components/MaterialSnackbar.vue"
import MaterialButton from "@/components/MaterialButton.vue"
import UserDataService from "@/services/UserDataService"
import AppSettingsDataService from "@/services/AppSettingsDataService"
import { mapState } from "vuex"

export default {
  name: "AppSettings",
  components: {
    MaterialSnackbar,
    MaterialButton,
  },
  data() {
    return {
      me: null, // Obiekt obecnie zalogowanego użytkownika

      app: null, // Obiekt aplikacji

      attachment: null, // Obiekt załącznika
      attachmentBlob: null, // Obiekt załącznika

      error: "", // Zmienna przechowująca treść błędu

      snackbar: null, // Zmienna odpowiedzialna za pokazywanie się powiadomienia
    }
  },
  computed: {
    ...mapState(["isDarkMode", "color"])
  },
  created() {
    this.getMe()
  },
  methods: {
    // Funkcja pobierająca dane zalogowanego użytkownika
    getMe() {
      UserDataService.me()
      .then(res => {
        this.getUser(res.data.id)
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja pobierająca dane zalogowanego użytkownika
    getUser(id) {
      UserDataService.get(id)
      .then(res => {
        if (res.data.deleted || res.data.status === "Dezaktywowany") {
          this.$logout()
        }
        else {
          this.me = res.data
          this.getApp()
        }
      })
      .catch(error => {
        console.log(error)
        
        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja pobierająca objekt aplikacji z API
    getApp() {
      AppSettingsDataService.getAll("")
      .then(res => {
        this.app = res.data["hydra:member"]
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja sprawdzająca poprawność wpisanych danych
    validation() {
      let success = true
      this.error = ""

      this.app.forEach(item => {
        // item.name === "mh_rate" ? item.value === "" ? item.value = "0" : item.value = item.value.toString() : item.value = item.value.toString()
        if (item.name !== "company_logotype" && item.value === "") {
          success = false
          this.error = "Pola nie mogą być puste!"
        }
      })

      if (success) {
        this.patchApp()
      }
    },

    // Funkcja aktualizująca aplikację w API
    patchApp() {
      this.app.forEach(item => {
        AppSettingsDataService.patch(item.id, item,
          {
            headers: { "Content-Type": "application/merge-patch+json" }
          }
        )
        .then(res => {
          console.log(res.data)

          this.snackbar = "success"

          if (item.name === "company_logotype" && this.attachment) {
            this.patchAttachment(item.id)
          }
        })
        .catch(error => {
          console.log(error)
        })
      })
    },

    patchAttachment(id) {
      const formData = new FormData()
      formData.append("file", this.attachment)

      AppSettingsDataService.patch_file_upload(id, formData,
        {
          headers: { "Content-Type": "multipart/form-data" }
        }
      )
      .then(res => {
        console.log(res.data)
      })
      .catch(error => {
        console.log(error)

        if (error.response.status == 500) {
          this.$router.go(0)
        }
      })
    },

    // Funkcja dodająca plik do zmiennej
    createAttachment(event) {
      this.attachment = event.target.files[0]
      if (this.attachment) {
        const reader = new FileReader()
        reader.onload = (e) => {
          this.attachmentBlob = e.target.result
        }
        reader.readAsDataURL(this.attachment)
      }
    },

    // Funkcja zamykająca powiadomienie
    closeSnackbar() {
      this.snackbar = null
    },
  }
}
</script>

<style scoped>
* {
  opacity: 1 !important;
}

select {
  background: none;
  border: none;
  outline: none;
  border-bottom: 1px solid;
}

select option {
  color: black !important;
}

.modal {
  background: rgb(0, 0, 0, 0.5);
  z-index: 10000 !important;
}

.modal-content {
  background: none;
  border: none;
}
</style>