import http from "@/http-common"

class UserGroupDataService {
    getAll(url) {
        return http.get(`user_groups${ url }`)
    }

    get(id) {
        return http.get(`user_groups/${ id }`)
    }

    post(data) {
        return http.post("user_groups", data)
    }

    patch(id, data, headers) {
        return http.patch(`user_groups/${ id }`, data, headers)
    }

    delete(id) {
        return http.delete(`user_groups/${ id }`)
    }
}

export default new UserGroupDataService()