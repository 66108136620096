import http from "@/http-common"

class SaleSystemDataService {
    getAll(url) {
        return http.get(`sale_systems${ url }`)
    }

    get(id) {
        return http.get(`sale_systems/${ id }`)
    }

    post(data) {
        return http.post("sale_systems", data)
    }

    patch(id, data, headers) {
        return http.patch(`sale_systems/${ id }`, data, headers)
    }

    delete(id) {
        return http.delete(`sale_systems/${ id }`)
    }
}

export default new SaleSystemDataService()