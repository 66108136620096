import http from "@/http-common"

class EdiTypeImportDataService {
    getAll(url) {
        return http.get(`edi_type_imports${ url }`)
    }

    get(id) {
        return http.get(`edi_type_imports/${ id }`)
    }

    post(data) {
        return http.post("edi_type_imports", data)
    }

    patch(id, data, headers) {
        return http.patch(`edi_type_imports/${ id }`, data, headers)
    }

    delete(id) {
        return http.delete(`edi_type_imports/${ id }`)
    }
}

export default new EdiTypeImportDataService()