<template>
  <aside
    id="sidenav-main"
    class="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 ms-3"
    :class="`${
      isRTL ? 'me-3 rotate-caret fixed-end' : 'fixed-start ms-3'
    } ${sidebarType}`"
  >
    <sidenav-list />
  </aside>
</template>
<script>
import SidenavList from "./SidenavList.vue"
import logo from "@/assets/img/logo-ct.png"
import logoDark from "@/assets/img/logo-ct-dark.png"
import { mapState } from "vuex"

export default {
  name: "Index",
  components: {
    SidenavList
  },
  data() {
    return {
      logo,
      logoDark,
    }
  },
  computed: {
    ...mapState(["isRTL", "sidebarType", "isDarkMode"])
  }
}
</script>
