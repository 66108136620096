<template>
  <div class="mb-3 timeline-block">
    <material-avatar
      :img="$getAvatar(fullName, avatar)"
      class="me-3 timeline-step"
      alt="img"
      size="sm"
      shadow="sm"
      circular
    />
    <div class="timeline-content">
      <h6
        class="mb-0 text-sm font-weight-bold"
        :class="$parent.darkMode ? 'text-white' : 'text-dark'"
      >
        {{ fullName }}
        <span class="font-weight-normal">
          {{ activity }}
        </span>
      </h6>
      <p class="mt-1 mb-0 text-xs opacity-7 font-weight-normal">
        {{ dateTime }}
      </p>
      <!-- eslint-disable-next-line -->
      <p v-if="description" class="mt-3 mb-2 text-sm" :class="$parent.darkMode ? 'text-white' : 'text-dark'" style="white-space: pre-line" v-html="description"></p>
    </div>
  </div>
</template>

<script>
import MaterialAvatar from "@/components/MaterialAvatar.vue"

export default {
  name: "TimelineItem",
  components: {
    MaterialAvatar
  },
  props: {
    fullName: {
      type: String,
      default: ""
    },
    avatar: {
      type: String,
      default: ""
    },
    activity: {
      type: String,
      default: ""
    },
    dateTime: {
      type: String,
      default: ""
    },
    description: {
      type: String,
      default: ""
    }
  }
}
</script>
