<template>
  <div class="form-check p-0">
    <input
      :id="id"
      class="form-check-input"
      type="checkbox"
      :name="name"
      :checked="checked"
      :disabled="disabled"
    />
    <label :for="id" class="custom-control-label">
      <slot />
    </label>
  </div>
</template>

<script>
import { mapState } from "vuex"

export default {
  name: "MaterialCheckbox",
  props: {
    name: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    checked: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState(["color"])
  }
}
</script>

<style scoped>
input[type="checkbox"] {
  background: white !important;
}

input[type="checkbox"]:after {
  color: black !important
}
</style>