<template>
  <div
    id="sidenav-collapse-main"
    class="w-auto h-auto collapse navbar-collapse max-height-vh-100 h-100"
  >
    <ul class="navbar-nav">
      <!-- Profil -->
      <li v-if="me" class="nav-item pt-3 pb-1" :class="getRoute() === 'profil' ? 'active' : ''">
        <sidenav-collapse
          collapse-ref="profil"
          :nav-text="me.fullName.length >= 16 ? me.fullName.substring(0, 16) + '...' : me.fullName"
          :class="getRoute() === 'Zarządzanie kontem' || getRoute() === 'Personalizacja' ? 'active' : 'bg-transparent shadow-none'"
        >
          <template #icon>
            <material-avatar
              :img="$getAvatar(me.fullName, me.avatar)"
              class="avatar shadow"
              circular
              alt="avatar image"
            />
          </template>
          <template #list>
            <ul class="nav pe-0">
              <!-- Zarządaj kontem -->
              <sidenav-item
                :to="{ name: 'Zarządzanie kontem' }"
                mini-icon="account_circle"
                text="Zarządzanie kontem"
              />

              <!-- Personalizacja -->
              <sidenav-item
                :to="{ name: 'Personalizacja' }"
                mini-icon="settings"
                text="Personalizacja"
              />

              <!-- Wyloguj -->
              <sidenav-item
                :to="{ name: 'Login' }"
                mini-icon="logout"
                text="Wyloguj"
                @click="logout"
              />
            </ul>
          </template>
        </sidenav-collapse>
      </li>

      <hr class="horizontal light mt-2 mb-2" />

      <!-- Kokpit -->
      <li class="nav-item">
        <sidenav-collapse
          nav-text="Kokpit"
          :collapse="false"
          collapse-ref="/home/dashboard"
          :class="getRoute() === 'Kokpit' ? `active bg-${ color }` : ''"
          style="margin-top: 0 !important; margin-bottom: 0 !important"
        >
          <template #icon>
            <i class="material-symbols-outlined opacity-10" :style="getRoute() === 'Kokpit' ? 'color: white !important' : ''">dashboard</i>
          </template>
        </sidenav-collapse>
      </li>

      <li
        v-if="me && me.roles[0] == 'ROLE_ADMIN'"
        class="mt-3 nav-item"
      >
        <h6
          class="text-xs ps-4 text-uppercase font-weight-bolder text-white"
          :class="isRTL ? 'me-4' : 'ms-2'"
        >
          Administrator
        </h6>
      </li>

      <!-- Panel administratora -->
      <li
        v-if="me && me.roles[0] == 'ROLE_ADMIN'"
        class="nav-item pb-1"
        :class="getRoute() === 'admin' ? 'active' : ''"
      >
        <sidenav-collapse
            collapse-ref="admin"
            nav-text="Panel administratora"
            :class="getRoute() === 'Użytkownicy' || getRoute() === 'Grupy' ? 'active' : 'bg-transparent shadow-none'"
          >
          <template #icon>
            <i class="material-symbols-outlined opacity-10">person</i>
          </template>
          <template #list>
            <ul class="nav pe-0">
              <!-- Użytkownicy -->
              <sidenav-item
                :to="{ name: 'Użytkownicy' }"
                mini-icon="group"
                text="Użytkownicy"
              />

              <!-- Grupy -->
              <sidenav-item
                :to="{ name: 'Grupy' }"
                mini-icon="groups"
                text="Grupy"
              />
            </ul>
          </template>
        </sidenav-collapse>
      </li>

      <li class="mt-3 nav-item">
        <h6
          class="text-xs ps-4 text-uppercase font-weight-bolder text-white"
          :class="isRTL ? 'me-4' : 'ms-2'"
        >
          Strony
        </h6>
      </li>

      <!-- Baza danych -->
      <li class="nav-item pb-1" :class="getRoute() === 'data' ? 'active' : ''">
        <sidenav-collapse
          collapse-ref="data"
          nav-text="Baza danych"
          :class="getRoute() === 'Produkty' || getRoute() === 'Zdjęcia' || getRoute() === 'Kontrahenci' || getRoute() === 'Ceny' || getRoute() === 'Zamówienia' || getRoute() === 'Logi' ? 'active' : 'bg-transparent shadow-none'"
        >
          <template #icon>
            <i class="material-symbols-outlined opacity-10">description</i>
          </template>
          <template #list>
            <ul class="nav pe-0">
              <!-- Produkty -->
              <sidenav-item
                :to="{ name: 'Produkty' }"
                mini-icon="category"
                text="Produkty"
              />

              <!-- Zdjęcia -->
              <sidenav-item
                :to="{ name: 'Zdjęcia' }"
                mini-icon="image"
                text="Zdjęcia"
              />

              <!-- Kontrahenci -->
              <sidenav-item
                :to="{ name: 'Kontrahenci' }"
                mini-icon="group"
                text="Kontrahenci"
              />

              <!-- Ceny -->
              <sidenav-item
                :to="{ name: 'Ceny' }"
                mini-icon="payments"
                text="Ceny"
              />

              <!-- Zamówienia -->
              <sidenav-item
                :to="{ name: 'Zamówienia' }"
                mini-icon="sell"
                text="Zamówienia"
              />

              <!-- Logi -->
              <sidenav-item
                :to="{ name: 'Logi' }"
                mini-icon="data_object"
                text="Logi"
              />
            </ul>
          </template>
        </sidenav-collapse>
      </li>

      <!-- Operacje -->
      <li class="nav-item pb-1" :class="getRoute() === 'import' ? 'active' : ''">
        <sidenav-collapse
          collapse-ref="import"
          nav-text="Operacje"
          :class="getRoute() === 'Import' || getRoute() === 'Typy importu' || getRoute() === 'Typy exportu' ? 'active' : 'bg-transparent shadow-none'"
        >
          <template #icon>
            <i class="material-symbols-outlined opacity-10">build</i>
          </template>
          <template #list>
            <ul class="nav pe-0">
              <!-- EDI -->
              <sidenav-item
                :to="{ name: 'Edi' }"
                mini-icon="reorder"
                text="EDI"
              />

              <!-- Typy importu -->
              <sidenav-item
                :to="{ name: 'Typy importu' }"
                mini-icon="upload"
                text="Typy importu"
              />

              <!-- Typy exportu -->
              <sidenav-item
                :to="{ name: 'Typy exportu' }"
                mini-icon="download"
                text="Typy exportu"
              />
            </ul>
          </template>
        </sidenav-collapse>
      </li>
    </ul>
  </div>
</template>

<script>
import MaterialAvatar from "@/components/MaterialAvatar.vue"
import SidenavItem from "./SidenavItem.vue"
import SidenavCollapse from "./SidenavCollapse.vue"
import LoginDataService from "@/services/LoginDataService"
import UserDataService from "@/services/UserDataService"
import { mapState } from "vuex"

export default {
  name: "SidenavList",
  components: {
    MaterialAvatar,
    SidenavItem,
    SidenavCollapse,
  },
  data() {
    return {
      me: null, // Obiekt zalogowanego użytkownika
    }
  },
  computed: {
    ...mapState(["isRTL", "color"])
  },
  created() {
    if(localStorage.getItem('token')) {
      this.getMe()
    }
  },
  methods: {
    getRoute() {
      const routeArr = this.$route.name
      return routeArr
    },

    // Funkcja pobierająca dane o obecnie zalogowanym użytkowniku
    getMe() {
      UserDataService.me()
        .then(res => {
          this.getUser(res.data.id)
        })
        .catch(error => {
          console.log(error)

          if(JSON.stringify(error.response.data.code) == 401) {
            this.$logout()
          }
        })
    },

    // Funkcja pobierająca dane o obecnie zalogowanym użytkowniku
    getUser(id) {
      UserDataService.get(id)
      .then(res => {
        this.me = res.data
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja wylogowująca użytkownika
    logout() {
      LoginDataService.logout(
        {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") }
        }
      )
      .then(res => {
        console.log(res.data)
        localStorage.removeItem("token")
        this.$router.push({ name: "Login" })  
      })
      .catch(error => {
        console.log(error)
      })
    },
  }
}
</script>