import http from "@/http-common"

class UserHistoryDataService {
    getAll(url) {
        return http.get(`user_histories${ url }`)
    }

    post(data, token) {
        return http.post("user_histories", data, token)
    }
}

export default new UserHistoryDataService()