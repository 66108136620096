<template>
  <div v-if="me != null" class="container-fluid">
    <div class="row">
      <div class="col-lg-3">
        <div class="card position-sticky top-1 mb-4">
          <ul class="nav flex-column bg-white border-radius-lg p-3">
            <li class="nav-item">
              <a class="nav-link text-dark d-flex align-items-center" data-scroll href="#dashboard-settings" :class="isDarkMode ? 'text-white' : 'text-dark'">
                <i class="material-symbols-outlined text-lg me-2">home</i>
                <span class="text-sm">Ustawienia kokpitu</span>
              </a>
            </li>
            <li class="nav-item pt-2">
              <a class="nav-link text-dark d-flex align-items-center" data-scroll href="#notifications" :class="isDarkMode ? 'text-white' : 'text-dark'">
                <i class="material-symbols-outlined text-lg me-2">notifications</i>
                <span class="text-sm">Ustawienia powiadomień</span>
              </a>
            </li>
            <li class="nav-item pt-2">
              <a class="nav-link text-dark d-flex align-items-center" data-scroll href="#ui" :class="isDarkMode ? 'text-white' : 'text-dark'">
                <i class="material-symbols-outlined text-lg me-2">palette</i>
                <span class="text-sm">Personalizacja UI</span>
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div class="col-lg-9">
        <!-- Ustawienia kokpitu -->
        <div id="dashboard-settings" class="card mb-4">
          <div class="card-header pb-1">
            <h5>Ustawienia kokpitu</h5>
            <p class="text-sm">
              Wybierz moduły, które chcesz wyświetlać na kokpicie.
            </p>
          </div>
          <div class="card-body pt-1">
            <div class="d-flex align-items-center font-weight-light">
              <i class="material-symbols-outlined me-3">history</i>
              <span class="text-sm">Historia</span>
              <material-switch
                class="ms-auto"
                :checked="me.settings.displayHistory"
                @change="onChangeOption('displayHistory', $event.target.checked)"
              />
            </div>
            <hr class="horizontal" :class="isDarkMode ? 'bg-dark' : 'bg-light'" />
            <div class="d-flex align-items-center font-weight-light">
              <i class="material-symbols-outlined me-3">notifications</i>
              <span class="text-sm">Powiadomienia</span>
              <material-switch
                class="ms-auto"
                :checked="me.settings.displayNotifications"
                @change="onChangeOption('displayNotifications', $event.target.checked)"
              />
            </div>
          </div>
        </div>

        <!-- Ustawienia powiadomień -->
        <div id="notifications" class="card mb-4">
          <div class="card-header pb-1">
            <h5>Ustawienia powiadomień</h5>
          </div>
          <div class="card-body pt-1">
            <div class="d-flex align-items-center font-weight-light">
              <span class="text-sm">Wysyłaj powiadomienia na pulpicie</span>
              <material-switch
                class="ms-auto"
                :checked="me.settings.sendNotifications"
                @change="onChangeOption('sendNotifications', $event.target.checked)"
              />
            </div>
            <hr class="horizontal" :class="isDarkMode ? 'bg-dark' : 'bg-light'" />
            <div class="d-flex align-items-center font-weight-light">
              <span class="text-sm">Wysyłaj powiadomienia na telefon</span>
              <material-switch
                class="ms-auto"
                :checked="me.settings.pushNotifications"
                @change="onChangeOption('pushNotifications', $event.target.checked)"
              />
            </div>
            <hr class="horizontal" :class="isDarkMode ? 'bg-dark' : 'bg-light'" />
            <div class="d-flex align-items-center font-weight-light">
              <span class="text-sm">Odtwórz dźwięk przy powiadomieniach</span>
              <material-switch
                class="ms-auto"
                :checked="me.settings.playSound"
                @change="onChangeOption('playSound', $event.target.checked)"
              />
            </div>
          </div>
        </div>

        <!-- Personalizacja UI -->
        <div id="ui" class="card mb-4">
          <div class="card-header pb-1">
            <h5>Personalizacja UI</h5>
          </div>
          <div class="card-body pt-1">
            <div class="d-flex align-items-center font-weight-light">
              <span class="text-sm">Jasny / Ciemny</span>
              <material-switch
                class="ms-auto"
                :checked="me.settings.darkMode"
                @change="onChangeOption('darkMode', $event.target.checked)"
              />
            </div>
            <hr class="horizontal" :class="isDarkMode ? 'bg-dark' : 'bg-light'" />
            <div class="d-flex align-items-center font-weight-light">
              <span class="text-sm">Kolor przycisków</span>
              <div class="fixed-plugin my-auto ms-auto">
                <a href="#" class="switch-trigger background-color">
                  <div
                    class="mt-1 badge-colors"
                    :class="isRTL ? 'text-end' : ' text-start'"
                  >
                    <span
                      class="badge filter bg-gradient-primary"
                      data-color="primary"
                      :class="me.settings.sidebarColor === 'primary' ? 'active' : ''"
                      @click="onChangeOption('sidebarColor', 'primary')"
                    ></span>
                    <span
                      class="badge filter bg-gradient-dark"
                      data-color="dark"
                      :class="me.settings.sidebarColor === 'dark' ? 'active' : ''"
                      @click="onChangeOption('sidebarColor', 'dark')"
                    ></span>
                    <span
                      class="badge filter bg-gradient-info"
                      data-color="info"
                      :class="me.settings.sidebarColor === 'info' ? 'active' : ''"
                      @click="onChangeOption('sidebarColor', 'info')"
                    ></span>
                    <span
                      class="badge filter bg-gradient-success"
                      data-color="success"
                      :class="me.settings.sidebarColor === 'success' ? 'active' : ''"
                      @click="onChangeOption('sidebarColor', 'success')"
                    ></span>
                    <span
                      class="badge filter bg-gradient-warning"
                      data-color="warning"
                      :class="me.settings.sidebarColor === 'warning' ? 'active' : ''"
                      @click="onChangeOption('sidebarColor', 'warning')"
                    ></span>
                    <span
                      class="badge filter bg-gradient-danger"
                      data-color="danger"
                      :class="me.settings.sidebarColor === 'danger' ? 'active' : ''"
                      @click="onChangeOption('sidebarColor', 'danger')"
                    ></span>
                  </div>
                </a>
              </div>
            </div>
            <hr class="horizontal" :class="isDarkMode ? 'bg-dark' : 'bg-light'" />
            <div class="d-flex align-items-center font-weight-light">
              <span class="text-sm">Kolor menu</span>
              <div class="fixed-plugin my-auto ms-auto">
                <button
                  id="btn-dark"
                  class="px-3 mb-0 btn bg-gradient-dark"
                  :class="me.settings.sidebarType === 'bg-gradient-dark' ? 'active' : ''"
                  @click="onChangeOption('sidebarType', 'bg-gradient-dark')"
                >
                  Ciemny
                </button>
                <button
                  id="btn-transparent"
                  class="px-3 mb-0 btn bg-gradient-dark ms-2"
                  :class="me.settings.sidebarType === 'bg-transparent' ? 'active' : ''"
                  @click="onChangeOption('sidebarType', 'bg-transparent')"
                >
                  Przeźroczysty
                </button>
                <button
                  id="btn-white"
                  class="px-3 mb-0 btn bg-gradient-dark ms-2"
                  :class="me.settings.sidebarType === 'bg-white' ? 'active' : ''"
                  @click="onChangeOption('sidebarType', 'bg-white')"
                >
                  Jasny
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div class="d-flex justify-content-end">
        <material-button
          :color="color"
          variant="gradient"
          @click="patchSettings()"
        >
          Zapisz zmiany
        </material-button>

        <material-button
          class="ms-3"
          color="light"
          variant="gradient"
          @click="$redirect('Kokpit')"
        >
          Anuluj
        </material-button>
      </div>
    </div>

    <!-- Snackbar -->
    <div class="position-fixed ms-1 bottom-2 left-1 z-index-2">
      <material-snackbar
        v-if="snackbar"
        description="Zmiany zostały zapisane pomyślnie!"
        title="snackbar"
        color="success"
        :close-handler="closeSnackbar"
      />
    </div>
  </div>
</template>

<script>
import MaterialSwitch from "@/components/MaterialSwitch.vue"
import MaterialSnackbar from "@/components/MaterialSnackbar.vue"
import MaterialButton from "@/components/MaterialButton.vue"
import UserDataService from "@/services/UserDataService"
import SettingsDataService from "@/services/SettingsDataService"
import { activateDarkMode, deactivateDarkMode } from "@/assets/js/dark-mode"
import { mapState, mapActions } from "vuex"

export default {
  name: "Settings",
  components: {
    MaterialSwitch,
    MaterialSnackbar,
    MaterialButton,
  },
  data() {
    return {
      me: null, // Obiekt obecnie zalogowanego użytkownika

      snackbar: null, // Zmienna odpowiedzialna za pokazywanie się powiadomienia
    }
  },
  computed: {
    ...mapState([
      "sidebarType",
      "isRTL",
      "isDarkMode",
      "color"
    ])
  },
  created() {
    this.getMe()
  },
  methods: {
    ...mapActions(["setColor"]),

    // Funkcja pobierająca dane zalogowanego użytkownika
    getMe() {
      UserDataService.me()
      .then(res => {
        this.getUser(res.data.id)
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja pobierająca dane zalogowanego użytkownika
    getUser(uid) {
      UserDataService.get(uid)
      .then(res => {
        if (res.data.deleted || res.data.status === "Dezaktywowany") {
          this.$logout()
        }
        
        this.me = res.data
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja zmieniająca wybraną opcje
    patchSettings() {
      SettingsDataService.patch(this.me.settings.id, this.me.settings,
        {
          headers: { "Content-Type": "application/merge-patch+json" }
        }
      )
      .then(res => {
        console.log(res.data)
        this.snackbar = "success"

        // Konfiguracja: Sidebar color
        document.querySelector("#sidenav-main").setAttribute("data-color", this.me.settings.sidebarColor)
        this.setColor(this.me.settings.sidebarColor)

        // Konfiguracja: Sidebar type
        this.$store.state.sidebarType = this.me.settings.sidebarType
        
        // Konfiguracja: Dark Mode
        if (!this.me.settings.darkMode) {
          this.$store.state.isDarkMode = this.me.settings.darkMode
          deactivateDarkMode()
          return
        }
        else {
          this.$store.state.isDarkMode = this.me.settings.darkMode
          activateDarkMode()
        }
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja pobierająca zmiany ustawień
    onChangeOption(option, value) {
      this.me.settings[option] = value
    },

    // Funkcja zamykająca powiadomienie
    closeSnackbar() {
      this.snackbar = null
    },
  }
}
</script>

<style scoped>
* {
  opacity: 1 !important;
}

select {
  width: 100%;
  background: none;
  border: none;
  outline: none;
  border-bottom: 1px solid;
}

select option {
  color: black !important;
}
</style>