<template>
  <!-- Modal -->
  <div id="modal" class="modal show" tabindex="-1" role="dialog">
    <!-- Modal: Potwierdzenie dezaktywacji -->
    <div v-if="modals[0]" class="modal-dialog">
      <div class="modal-content">
        <div class="card px-2 py-1">
          <div class="card-header pb-1" :class="isDarkMode ? 'bg-black text-light' : 'bg-white text-dark'">
            <div class="d-flex align-items-center justify-content-between">
              <h5 class="my-auto">Potwierdzenie dezaktywacji</h5>
              <span class="my-auto">
                <i class="material-symbols-outlined text-lg cursor-pointer" @click="closeModal()">close</i>
              </span>
            </div>
          </div>
          <div class="card-body pt-1 text-sm" :class="isDarkMode ? 'bg-black' : 'bg-white'">
            Czy na pewno chcesz dezaktywować te konto?
          
            <div class="d-flex justify-content-end mt-3">
              <material-button
                :color="color"
                variant="gradient"
                @click="blockUser()"
              >
                Potwierdź
              </material-button>

              <material-button
                class="ms-3"
                color="light"
                variant="gradient"
                @click="closeModal()"
              >
                Anuluj
              </material-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal: Potwierdzenie aktywacji -->
    <div v-if="modals[1]" class="modal-dialog">
      <div class="modal-content">
        <div class="card px-2 py-1">
          <div class="card-header pb-1" :class="isDarkMode ? 'bg-black text-light' : 'bg-white text-dark'">
            <div class="d-flex align-items-center justify-content-between">
              <h5 class="my-auto">Potwierdzenie aktywacji</h5>
              <span class="my-auto">
                <i class="material-symbols-outlined text-lg cursor-pointer" @click="closeModal()">close</i>
              </span>
            </div>
          </div>
          <div class="card-body pt-1 text-sm" :class="isDarkMode ? 'bg-black' : 'bg-white'">
            Czy na pewno chcesz aktywować te konto?
          
            <div class="d-flex justify-content-end mt-3">
              <material-button
                :color="color"
                variant="gradient"
                @click="activateUser()"
              >
                Potwierdź
              </material-button>

              <material-button
                class="ms-3"
                color="light"
                variant="gradient"
                @click="closeModal()"
              >
                Anuluj
              </material-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal: Potwierdzenie usunięcia -->
    <div v-if="modals[2]" class="modal-dialog">
      <div class="modal-content">
        <div class="card px-2 py-1">
          <div class="card-header pb-1" :class="isDarkMode ? 'bg-black text-light' : 'bg-white text-dark'">
            <div class="d-flex align-items-center justify-content-between">
              <h5 class="my-auto">Potwierdzenie usunięcia</h5>
              <span class="my-auto">
                <i class="material-symbols-outlined text-lg cursor-pointer" @click="closeModal()">close</i>
              </span>
            </div>
          </div>
          <div class="card-body pt-1 text-sm" :class="isDarkMode ? 'bg-black' : 'bg-white'">
            Czy na pewno chcesz usunąć te konto?
          
            <div class="d-flex justify-content-end mt-3">
              <material-button
                :color="color"
                variant="gradient"
                @click="deleteUser()"
              >
                Potwierdź
              </material-button>

              <material-button
                class="ms-3"
                color="light"
                variant="gradient"
                @click="closeModal()"
              >
                Anuluj
              </material-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-if="me && user" class="container-fluid">
    <div class="d-flex mb-4">
      <!-- Przycisk: Powrót -->
      <material-button
        :color="color"
        variant="gradient"
        @click="$redirect('Użytkownicy')"
      >
        <i class="material-icons me-2">keyboard_backspace</i> Powrót
      </material-button>
    </div>

    <div class="row">
      <div class="col-lg-3">
        <div class="card position-sticky top-1 mb-4">
          <ul class="nav flex-column bg-white border-radius-lg p-3">
            <li class="nav-item">
              <a class="nav-link text-dark d-flex align-items-center" data-scroll href="#profile" :class="isDarkMode ? 'text-white' : 'text-dark'">
                <i class="material-symbols-outlined text-lg me-2">person</i>
                <span class="text-sm">Profil</span>
              </a>
            </li>
            <li class="nav-item pt-2">
              <a class="nav-link text-dark d-flex align-items-center" data-scroll href="#user-data" :class="isDarkMode ? 'text-white' : 'text-dark'">
                <i class="material-symbols-outlined text-lg me-2">receipt</i>
                <span class="text-sm">Dane użytkownika</span>
              </a>
            </li>
            <li class="nav-item pt-2">
              <a class="nav-link text-dark d-flex align-items-center" data-scroll href="#password" :class="isDarkMode ? 'text-white' : 'text-dark'">
                <i class="material-symbols-outlined text-lg me-2">lock</i>
                <span class="text-sm">Zmiana hasła</span>
              </a>
            </li>
            <li class="nav-item pt-2">
              <a class="nav-link text-dark d-flex align-items-center" data-scroll href="#group" :class="isDarkMode ? 'text-white' : 'text-dark'">
                <i class="material-symbols-outlined text-lg me-2">groups</i>
                <span class="text-sm">Grupa</span>
              </a>
            </li>
            <li class="nav-item pt-2">
              <a class="nav-link text-dark d-flex align-items-center" data-scroll href="#history" :class="isDarkMode ? 'text-white' : 'text-dark'">
                <i class="material-symbols-outlined text-lg me-2">desktop_windows</i>
                <span class="text-sm">Historia logowania</span>
              </a>
            </li>
            <li v-if="user.status === 'Dezaktywowany'" class="nav-item pt-2">
              <a class="nav-link text-dark d-flex align-items-center" data-scroll href="#activate" :class="isDarkMode ? 'text-white' : 'text-dark'">
                <i class="material-symbols-outlined text-lg me-2">update</i>
                <span class="text-sm">Aktywuj konto</span>
              </a>
            </li>
            <li v-else class="nav-item pt-2">
              <a class="nav-link text-dark d-flex align-items-center" data-scroll href="#block" :class="isDarkMode ? 'text-white' : 'text-dark'">
                <i class="material-symbols-outlined text-lg me-2">block</i>
                <span class="text-sm">Dezaktywuj konto</span>
              </a>
            </li>
            <li class="nav-item pt-2">
              <a class="nav-link text-dark d-flex align-items-center" data-scroll href="#delete" :class="isDarkMode ? 'text-white' : 'text-dark'">
                <i class="material-symbols-outlined text-lg me-2">delete</i>
                <span class="text-sm">Usuń konto</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
      
      <div class="col-lg-9">
        <!-- Profil -->
        <div id="profile" class="card mb-4">
          <div class="card-body pb-3">
            <div class="row align-items-center">
              <div class="col-sm-auto col-4">
                <material-avatar
                  :img="$getAvatar(user.fullName, user.avatar)"
                  alt="bruce"
                  size="xl"
                  shadow="sm"
                  circular
                />
              </div>

              <div class="col-md-auto col-8 px-1 my-auto">
                <h5 class="ms-1 font-weight-bolder">{{ user.fullName }}</h5>
              </div>
            </div>
          </div>
        </div>

        <!-- Dane użytkownika -->
        <div id="user-data" class="card mb-4">
          <div class="card-header pb-1">
            <h5>Dane użytkownika</h5>
          </div>
          <div class="card-body pt-1">
            <div class="row">
              <div class="col-md-6 mb-3">
                <material-input
                  id="firstName"
                  variant="static"
                  label="Imię"
                  placeholder="Jan"
                  :value="user.fullName.substring(0, user.fullName.indexOf(' '))"
                />
              </div>
              <div class="col-md-6 mb-3">
                <material-input
                  id="lastName"
                  variant="static"
                  label="Nazwisko"
                  placeholder="Kowalski"
                  :value="user.fullName.substring(user.fullName.indexOf(' ') + 1)"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="input-group input-group-static">
                  <label class="d-flex">
                    Adres e-mail
                  </label>
                  <input
                    v-model="user.email"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                    placeholder="jan.kowalski@elf24.pl"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="input-group input-group-static">
                  <label class="d-flex">
                    Numer telefonu
                  </label>
                  <input
                    v-model="user.phoneNumber"
                    v-mask="'+48 ### ### ###'"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                    placeholder="+48 *** *** ***"
                  />
                </div>
              </div>
            </div>
          </div>

          <p v-if="errorData !== ''" class="w-100 text-end px-4 text-sm font-weight-bold text-danger">{{ errorData }}</p>
        </div>

        <!-- Zmiana hasła -->
        <div id="password" class="card mb-4">
          <div class="card-header pb-1">
            <h5>Zmiana hasła</h5>
          </div>
          <div class="card-body pt-1">
            <div class="row mb-3">
              <material-input
                id="newPassword"
                variant="static"
                type="password"
                label="Nowe hasło"
                placeholder="********"
              />
            </div>
            <div class="row">
              <material-input
                id="confirmNewPassword"
                variant="static"
                type="password"
                label="Potwierdź nowe hasło"
                placeholder="********"
              />
            </div>
          </div>

          <p v-if="errorPassword !== ''" class="w-100 text-end px-4 text-sm font-weight-bold text-danger">{{ errorPassword }}</p>
        </div>

        <!-- Uprawnienia -->
        <div id="permissions" class="card mb-4">
          <div class="card-header pb-1">
            <h5>Uprawnienia</h5>
          </div>
          <div class="card-body pt-1">
            <div class="row">
              <div class="col-md-6">
                <label class="form-label ms-0 mb-1">Prawa</label>
                <select
                  v-model="user.roles[0]"
                  class="w-100 p-2 text-sm"
                  :class="isDarkMode ? 'text-white' : 'text-dark'"
                  style="border: none; border-radius: 10px"
                  :style="isDarkMode ? 'background: rgb(0, 0, 0, 0.2)' : 'background: rgb(0, 0, 0, 0.1)'"
                >
                  <option value="ROLE_USER">Użytkownik</option>
                  <option value="ROLE_ADMIN">Administrator</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <!-- Grupa -->
        <div id="group" class="card mb-4">
          <div class="card-header pb-1">
            <h5>Grupa</h5>
            <p class="mb-0 text-sm">
              Wybierz grupę, do której ma należeć użytkownik.
            </p>
          </div>
          <div class="card-body pt-1">
            <div v-if="!user.group" class="mt-3 text-sm">Brak podłączonej grupy</div>
            <div v-else class="d-flex align-items-center mt-2">
              <i class="material-symbols-outlined me-2">groups</i>
              <p class="my-auto text-sm">{{ user.group.name }}</p>
              <span
                class="text-danger text-sm icon-move-right my-auto ms-auto me-3 cursor-pointer"
                @click="groups.push(user.group); groupsAC.push(user.group.name); user.group = null"
              >
                Odłącz
              </span>
            </div>

            <div class="row col-md-6 mt-4">
              <label class="form-label ms-0">Podłącz grupę</label>
              <SimpleTypeahead
                ref="groupsAC"
                placeholder="Wybierz grupę z listy..."
                :items="groupsAC"
                :min-input-length="0"
                class="text-sm pb-2"
                style="width: 100%; background: none; border: none; outline: none; border-bottom: 1px solid #eeeeee"
                :class="isDarkMode ? 'text-white' : 'text-dark'"
                @select-item="selectGroup"
              >
              </SimpleTypeahead>
            </div>
          </div>
        </div>

        <!-- Historia logowania -->
        <div id="history" class="card mb-4">
          <div class="card-header pb-1">
            <h5>Historia logowania</h5>
          </div>
          <div class="card-body pt-1">
            <div v-if="sessions.length == 0" class="text-sm">Brak ostatnich sesji</div>
            <div v-for="(item, index) in sessions" v-else :key="item">
              <div class="d-flex align-items-center">
                <i class="material-symbols-outlined me-2">computer</i>
                <p class="my-auto text-sm">{{ item.createdAt }}</p>
                <material-badge v-if="index === 0 && user.status === 'Aktywny'" color="success" size="sm" class="ms-auto">Aktywne</material-badge>
              </div>
              <hr v-if="index != sessions.length - 1" class="horizontal dark" />
            </div>
          </div>
        </div>

        <!-- Aktywuj konto -->
        <div v-if="user.status === 'Dezaktywowany'" id="activate" class="card mb-4">
          <div class="card-header pb-1">
            <h5>Aktywuj konto</h5>
            <p class="mb-0 text-sm">
              Po aktywacji konta, użytkownik będzie mógł się zalogować.
            </p>
          </div>
          <div class="card-body pt-1">
            <material-button
              color="success"
              variant="outline"
              class="float-end mb-0"
              type="button"
              name="button"
              @click="openModal(1)"
            >
              Aktywuj
            </material-button>
          </div>
        </div>

        <!-- Dezaktywuj konto -->
        <div v-else id="block" class="card mb-4">
          <div class="card-header pb-1">
            <h5>Dezaktywuj konto</h5>
            <p class="mb-0 text-sm">
              Po dezaktywacji konta, użytkownik nie będzie mógł się zalogować.
            </p>
          </div>
          <div class="card-body pt-1">
            <material-button
              color="danger"
              variant="outline"
              class="float-end mb-0"
              type="button"
              name="button"
              @click="openModal(0)"
            >
              Dezaktywuj
            </material-button>
          </div>
        </div>

        <!-- Usuń konto -->
        <div id="delete" class="card mb-4">
          <div class="card-header pb-1">
            <h5>Usuń konto</h5>
            <p class="mb-0 text-sm">
              Po usunięciu konta, przywrócenie go będzie niemożliwe.
            </p>
          </div>
          <div class="card-body pt-1">
            <material-button
              color="danger"
              variant="outline"
              class="float-end mb-0"
              type="button"
              name="button"
              @click="openModal(2)"
            >
              Usuń
            </material-button>
          </div>
        </div>

        <div class="d-flex justify-content-end">
          <material-button
            :color="color"
            variant="gradient"
            @click="validation()"
          >
            Zapisz zmiany
          </material-button>

          <material-button
            class="ms-3"
            color="light"
            variant="gradient"
            @click="$redirect('Użytkownicy')"
          >
            Anuluj
          </material-button>
        </div>
      </div>
    </div>

    <!-- Snackbar -->
    <div class="position-fixed ms-1 bottom-2 left-1 z-index-2">
      <material-snackbar
        v-if="snackbar"
        description="Zmiany zostały zapisane pomyślnie!"
        title="snackbar"
        color="success"
        :close-handler="closeSnackbar"
      />
    </div>
  </div>
</template>

<script>
import MaterialAvatar from "@/components/MaterialAvatar.vue"
import MaterialInput from "@/components/MaterialInput.vue"
import MaterialBadge from "@/components/MaterialBadge.vue"
import MaterialSnackbar from "@/components/MaterialSnackbar.vue"
import MaterialButton from "@/components/MaterialButton.vue"
import SimpleTypeahead from "vue3-simple-typeahead"
import UserDataService from "@/services/UserDataService"
import UserGroupDataService from "@/services/UserGroupDataService"
import SessionDataService from "@/services/SessionDataService"
import { mapState } from "vuex"

export default {
  name: "EditUser",
  components: {
    MaterialAvatar,
    MaterialInput,
    MaterialBadge,
    MaterialSnackbar,
    MaterialButton,
    SimpleTypeahead,
  },
  data() {
    return {
      me: null, // Obiekt zalogowanego użytkownika

      user: null, // Obiekt edytowanego użytkownika

      groups: [], // Lista wszystkich wolnych grup
      groupsAC: [], // Lista wszystkich nazw wolnych grup

      sessions: [], // Lista 3 ostatnich sesji użytkownika
      
      modals: [], // Tablica odpowiedzialna za status modali

      errorData: "", // Zmienna odpowiedzialna za błąd danych
      errorPassword: "", // Zmienna odpowiedzialna za błąd hasła

      snackbar: null, // Zmienna odpowiedzialna za pokazywanie się powiadomienia
    }
  },
  computed: {
    ...mapState(["isDarkMode", "color"])
  }, 
  created() {
    this.getMe()
  },
  methods: {
    // Funkcja sprawdzająca uprawnienia użytkownika
    getMe() {
      UserDataService.me()
      .then(res => {
        this.verifyUser(res.data.id)
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja sprwadzająca status użytkownika
    verifyUser(id) {
      UserDataService.get(id)
      .then(res => {
        if (res.data.deleted || res.data.status === "Dezaktywowany") {
          this.$logout()
        }
        else {
          this.me = res.data
          this.getUser()
        }
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja pobierająca dane edytowanego użytkownika
    getUser() {
      UserDataService.get(this.$route.params.id)
      .then(res => {
        this.user = res.data
        this.getGroups()
        this.getSessions()
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja sprawdzająca poprawność wpisanych danych
    validation() {
      let success = true
      this.errorData = ""
      this.errorPassword = ""

      if (document.getElementById("firstName").value === "" || document.getElementById("lastName").value === "") {
        success = false
        this.errorData = "Niepoprawne dane użytkownika!"
      }
      else {
        this.user.fullName = document.getElementById("firstName").value + " " + document.getElementById("lastName").value
      }

      if (this.user.email === "") {
        success = false
        this.errorData = "Niepoprawne dane użytkownika!"
      }

      if (this.user.phoneNumber === "") {
        success = false
        this.errorData = "Niepoprawne dane użytkownika!"
      }

      if (document.getElementById("newPassword").value !== "" || document.getElementById("confirmNewPassword").value !== "") {
        if (document.getElementById("newPassword").value !== document.getElementById("confirmNewPassword").value) {
          success = false
          this.errorPassword = "Niepoprawne hasło!"
        }
        else {
          this.user.password = document.getElementById("newPassword").value
        }
      }

      if (success) {
        this.patchUser()
      }
    },

    // Funkcja aktualizująca dane użytkownika
    patchUser() {
      UserDataService.patch(this.user.id,
        {
          fullName: this.user.fullName,
          email: this.user.email,
          phoneNumber: this.user.phoneNumber,
          password: this.user.password,
          group: this.user.group ? `/user_groups/${ this.user.group.id }` : null,
          roles: this.user.roles,
        },
        {
          headers: { "Content-Type": "application/merge-patch+json" }
        }
      )
      .then(res => {
        console.log(res.data)
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.status) === 500) {
          this.errorData = "Adres e-mail jest już zajęty!"
        }
      })
    },

    // Funkcja pobierająca wszystkie grupy z API
    getGroups() {
      UserGroupDataService.getAll("")
      .then(res => {
        res.data["hydra:member"].forEach(item => {
          let add = true

          if(this.user.group && this.user.group.name === item.name) {
            add = false
          }

          if (add) {
            this.groups.push(item)
            this.groupsAC.push(item.name)
          }
        })
      })
      .catch(error => {
        console.log(error)
        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja pobierająca dane o sesjach edytowanego użytkownika
    getSessions() {
      SessionDataService.getAll(`?itemsPerPage=3&order[createdAt]=desc&owner.id=${ this.user.id }`)
        .then(res => {
          this.sessions = res.data["hydra:member"]
        })
        .catch(error => {
          console.log(error)
        })
    },

    // Funckja dezaktywująca edytowanego użytkownika
    blockUser() {
      UserDataService.patch(this.user.id,
        {
          status: "Dezaktywowany",
        },
        {
          headers: { 'Content-Type': 'application/merge-patch+json' }
        }
      )
      .then(res => {
        console.log(res.data)
        this.$redirect("Użytkownicy")
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funckja aktywująca edytowanego użytkownika
    activateUser() {
      UserDataService.patch(this.user.id,
        {
          status: "Nieaktywny",
        },
        {
          headers: { "Content-Type": "application/merge-patch+json" }
        }
      )
      .then(res => {
        console.log(res.data)
        this.$redirect("Użytkownicy")
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funckja usuwająca edytowanego użytkownika
    deleteUser() {
      UserDataService.patch(this.user.id,
        {
          group: null,
          calendars: [],
          myCalendars: [],
          calendarPermissions: [],
          events: [],
          tasksGroups: [],
          status: "Usunięty",
          deleted: true,
        },
        {
          headers: { "Content-Type": "application/merge-patch+json" }
        }
      )
      .then(res => {
        console.log(res.data)
        this.$redirect("Użytkownicy")
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja pobierająca dane o grupie z listy rozwijanej
    selectGroup(group) {
      this.groups.forEach((item, index) => {
        if(item.name === group) {
          if (this.user.group) {
            this.groups.push(this.user.group)
            this.groupsAC.push(this.user.group.name)
          }

          this.user.group = item
          this.groups.splice(index, 1)
          this.groupsAC.splice(index, 1)
        }
      })

      this.$refs.groupsAC.clearInput()
    },

    // Funkcja otwierająca okno
    openModal(x) {
      for (let i = 0; i < this.modals.length; i++) {
        this.modals[i] = false
      }

      document.getElementById("modal").classList.add("d-block")
      this.modals[x] = true
    },

    // Funkcja zamykająca okno
    closeModal() {
      document.getElementById("modal").classList.remove("d-block")
      for (let i = 0; i < this.modals.length; i++) {
        this.modals[i] = false
      }
    },

    // Funkcja zamykająca powiadomienie
    closeSnackbar() {
      this.snackbar = null
    },
  }
}
</script>

<style scoped>
* {
  opacity: 1 !important;
}

.modal {
  background: rgb(0, 0, 0, 0.5);
  z-index: 10000 !important;
}

.modal-content {
  background: none;
}

select {
  background: none;
  border: none;
  outline: none;
  border-bottom: 1px solid;
}

select option {
  color: black !important;
}

.simple-typeahead {
  color: #000;
}
</style>