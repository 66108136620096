import http from "@/http-common"

class ImportDataService {
    getAll(url) {
        return http.get(`edi_imports${ url }`)
    }

    get(id) {
        return http.get(`edi_imports/${ id }`)
    }

    post(data) {
        return http.post("edi_imports", data)
    }

    import(id) {
        return http.get(`import/${ id }`)
    }

    export(id) {
        return http.get(`export/${ id }`)
    }

    import_export(id) {
        return http.get(`import_export/${ id }`)
    }

    patch(id, data, headers) {
        return http.patch(`edi_imports/${ id }`, data, headers)
    }

    delete(id) {
        return http.delete(`edi_imports/${ id }`)
    }
}

export default new ImportDataService()