<template>
  <div v-if="me" class="container-fluid">
    <!-- Filtry -->
    <div class="d-flex align-items-center mb-4" :class="filter_ediImport ? 'justify-content-between' : 'justify-content-end'">
      <!-- Export -->
      <material-button
        v-if="filter_ediImport"
        class="d-flex align-items-center"
        :color="isDarkMode ? 'light' : 'dark'"
        variant="outline"
        @click="exportLogs()"
      >
        <i class="material-symbols-outlined text-lg me-2">download</i> Export
      </material-button>

      <div class="d-flex align-items-center">
        <!-- Filtr: Operacja -->
        <material-button
          :color="isDarkMode ? 'light' : 'dark'"
          variant="outline"
          class="dropdown-toggle me-3"
          data-bs-toggle="dropdown"
        >
          Operacja {{ filter_ediImport ? `: ${ filter_ediImport.name }` : "" }}
        </material-button>
        <ul class="dropdown-menu px-2 shadow">
          <li v-for="item in ediImports" :key="item">
            <a class="dropdown-item border-radius-md cursor-pointer" @click="filter_ediImport = item; getLogs(1)">
              {{ item.name }}
            </a>
          </li>
          <li>
            <hr class="horizontal bg-light my-2">
          </li>
          <li>
            <a class="dropdown-item border-radius-md text-danger cursor-pointer" @click="filter_ediImport = null; getLogs(1)"
            >Wyczyść filtr</a>
          </li>
        </ul>

        <!-- Filtr: Typ -->
        <material-button
          :color="isDarkMode ? 'light' : 'dark'"
          variant="outline"
          class="dropdown-toggle"
          data-bs-toggle="dropdown"
        >
          Typ {{ filter_type ? `: ${ filter_type }` : "" }}
        </material-button>
        <ul class="dropdown-menu px-2 shadow">
          <li>
            <a class="dropdown-item border-radius-md cursor-pointer" @click="filter_type = 'form-to-mail-registration'; getLogs(1)">
              form-to-mail-registration
            </a>
          </li>
          <li>
            <a class="dropdown-item border-radius-md cursor-pointer" @click="filter_type = 'Info'; getLogs(1)">
              Info
            </a>
          </li>
          <li>
            <a class="dropdown-item border-radius-md cursor-pointer" @click="filter_type = 'Success'; getLogs(1)">
              Success
            </a>
          </li>
          <li>
            <a class="dropdown-item border-radius-md cursor-pointer" @click="filter_type = 'Error'; getLogs(1)">
              Error
            </a>
          </li>
          <li>
            <hr class="horizontal bg-light my-2">
          </li>
          <li>
            <a class="dropdown-item border-radius-md text-danger cursor-pointer" @click="filter_type = ''; getLogs(1)"
            >Wyczyść filtr</a>
          </li>
        </ul>

        <material-button
          class="ms-3"
          :color="color"
          variant="gradient"
          @click="getLogs(page)"
        >Odśwież</material-button>
      </div>
    </div>

    <!-- Tabela: Logi -->
    <div class="row">
      <div class="mb-4" :class="log ? 'col-md-8' : 'col-12'">
        <div class="card">
          <div class="card-header pb-1">
            <h5>Logi</h5>
          </div>
          <div class="card-body p-0">
            <div class="table-responsive pt-1">
              <div class="dataTable-wrapper dataTable-loading no-footer searchable fixed-height fixed-columns">
                <!-- Wyszukiwarka -->
                <div class="dataTable-top mb-3 py-0">
                  <div class="dataTable-search">
                    <div class="input-group input-group-static">
                      <input
                        v-model="filter_name"
                        class="form-control text-sm px-3 py-2 border-secondary"
                        :class="isDarkMode ? 'text-white' : 'text-dark'"
                        style="background: none; border: 1px solid; border-radius: 10px !important; outline: none"
                        placeholder="Wyszukaj..."
                      />
                    </div>
                  </div>
                  <div class="dataTable-search"></div>
                </div>
                <div class="dataTable-container">
                  <table class="table table-flush dataTable-table">
                    <!-- Kolumny -->
                    <thead class="thead-light">
                      <tr>
                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder" style="width: 5%">
                          <a href="#" class="dataTable-sorter" @click="onOrder('id')">ID</a>
                        </th>

                        <th class="ps-0 text-uppercase text-secondary text-xxs font-weight-bolder" style="width: 50%">
                          <a href="#" class="dataTable-sorter" @click="onOrder('name')">Nazwa</a>
                        </th>

                        <th class="ps-0 text-uppercase text-secondary text-xxs font-weight-bolder" style="width: 20%">
                          <a href="#" class="dataTable-sorter" @click="onOrder('createdAt')">Data</a>
                        </th>

                        <th class="ps-0 text-uppercase text-secondary text-xxs font-weight-bolder" style="width: 20%">
                          <a href="#" class="dataTable-sorter" @click="onOrder('type')">Typ</a>
                        </th>

                        <th class="ps-0 text-uppercase text-secondary text-xxs font-weight-bolder" style="width: 5%"></th>
                      </tr>
                    </thead>

                    <!-- Wiersze -->
                    <tbody>
                      <!-- Logi -->
                      <tr
                        v-for="item in logs"
                        :key="item"
                        :class="log && log.id === item.id ? 'bg-light' : ''"
                      >
                        <!-- ID -->
                        <td class="cursor-pointer" @click="log && log.id === item.id ? log = null : getLog(item.id)">
                          <p class="m-0 text-xs" :class="item.type === 'Error' ? 'text-danger' : ''">#{{ item.id }}</p>
                        </td>

                        <!-- Nazwa -->
                        <td class="ps-0 cursor-pointer" @click="log && log.id === item.id ? log = null : getLog(item.id)">
                          <p class="m-0 text-xs" :class="item.type === 'Error' ? 'text-danger' : ''">{{ item.name ? item.name : "Brak" }}</p>
                        </td>

                        <!-- Data -->
                        <td class="ps-0 cursor-pointer" @click="log && log.id === item.id ? log = null : getLog(item.id)">
                          <p class="m-0 text-xs" :class="item.type === 'Error' ? 'text-danger' : ''">{{ item.createdAt ? item.createdAt : "Brak" }}</p>
                        </td>

                        <!-- Typ -->
                        <td class="ps-0 cursor-pointer" @click="log && log.id === item.id ? log = null : getLog(item.id)">
                          <p class="m-0 text-xs" :class="item.type === 'Error' ? 'text-danger' : ''">{{ item.type ? item.type : "Brak" }}</p>
                        </td>

                        <!-- Menu -->
                        <td class="text-xs text-end">
                          <a
                            :class="[color]"
                            data-bs-toggle="dropdown"
                          >
                            <i class="material-icons cursor-pointer">more_vert</i>
                          </a>
                          <ul class="p-2 dropdown-menu dropdown-menu-end me-sm-n0 z-index-3 shadow">
                            <li class="cursor-pointer" @click="getProduct(item.id)">
                              <a class="px-2 dropdown-item border-radius-md">
                                <div class="mx-0 px-0 py-1 d-flex">
                                  <div class="d-flex flex-column justify-content-center">
                                    <h6 class="my-auto text-sm font-weight-normal">
                                      Podgląd
                                    </h6>
                                  </div>
                                </div>
                              </a>
                            </li>
                          </ul>
                        </td>
                      </tr>

                      <!-- Brak logów -->
                      <tr :class="logs.length > 0 ? 'd-none' : ''">
                        <td class="dataTables-empty" colspan="5">
                          <div v-if="isLoading" class="loader mx-auto"></div>
                          <div v-else class="text-sm">Nie znaleziono logów</div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <!-- Paginacja -->
                <div v-if="logs.length > 0" class="dataTable-bottom pb-3">
                  <div class="dataTable-dropdown">
                    <label>
                      <select
                        class="dataTable-selector text-sm p-2 border-secondary me-2"
                        style="background: none; border: 1px solid; border-radius: 10px !important; outline: none"
                        :class="isDarkMode ? 'text-white' : 'text-dark'"
                        @change="onChangeRecordsPerPage($event)"
                      >
                        <option value="10" selected>10</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="1000000000">Wszystkie</option>
                      </select>
                      pozycji na stronę
                    </label>
                  </div>

                  <div class="dataTable-pagination">
                    <ul class="dataTable-pagination-list">
                      <li v-if="page !== 1">
                        <a href="#" @click="onPrevPage(1)">‹‹</a>
                      </li>
                      <li v-if="page !== 1">
                        <a href="#" @click="onPrevPage(page - 1)">‹</a>
                      </li>
                      <li v-if="page !== 1">
                        <a href="#" @click="onPrevPage(page - 1)">
                          {{ page - 1 }}
                        </a>
                      </li>
                      <li class="active">
                        <a href="#" :style="isDarkMode ? 'background: #f0f2f5 !important; color: #344767 !important' : 'background: #344767 !important; color: #f0f2f5 !important'">
                          {{ page }}
                        </a>
                      </li>
                      <li v-if="page !== lastPage">
                        <a href="#" @click="onNextPage(page + 1)">
                          {{ page + 1 }}
                        </a>
                      </li>
                      <li v-if="page !== lastPage">
                        <a href="#" @click="onNextPage(page + 1)">›</a>
                      </li>
                      <li v-if="page !== lastPage">
                        <a href="#" @click="onNextPage(lastPage)">››</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Log -->
      <div v-if="log" class="col-md-4">
        <div class="card position-sticky top-1 overflow-auto">
          <div class="card-header pb-1">
            <h5>{{ log.name ? log.name : "Brak" }}</h5>
          </div>
          <div class="card-body pt-1 pb-0">
            <div class="row mb-1">
              <div class="col-12">
                <label class="form-label ms-0 mb-0 font-weight-bold">
                  Typ: <span class="font-weight-normal ms-2">{{ log.type ? log.type : "Brak" }}</span>
                </label>
              </div>
            </div>

            <div class="row mb-1">
              <div class="col-12">
                <label class="form-label ms-0 mb-0 font-weight-bold">
                  Operacja: <span class="font-weight-normal ms-2">{{ log.ediImport ? log.ediImport.name : "Brak" }}</span>
                </label>
              </div>
            </div>

            <div class="row mb-1">
              <div class="col-12">
                <label class="form-label ms-0 mb-0 font-weight-bold">
                  Data: <span class="font-weight-normal ms-2">{{ log.createdAt ? log.createdAt : "Brak" }}</span>
                </label>
              </div>
            </div>

            <div class="row mb-1">
              <div class="col-12">
                <label class="form-label ms-0 mb-0 font-weight-bold">
                  Opis: <span class="font-weight-normal ms-2">{{ log.description ? log.description : "Brak" }}</span>
                </label>
              </div>
            </div>

            <div class="row my-3">
              <div class="col-12 d-flex justify-content-end">
                <material-button
                  color="light"
                  variant="gradient"
                  @click="log = null"
                >
                  Anuluj
                </material-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MaterialButton from "@/components/MaterialButton.vue"
import UserDataService from "@/services/UserDataService"
import EdiLogDataService from "@/services/EdiLogDataService"
import ImportDataService from "@/services/ImportDataService"
import { mapState } from "vuex"

export default {
  name: "Logs",
  components: {
    MaterialButton,
  },
  data() {
    return {
      me: null, // Obiekt zalogowanego użytkownika

      log: null, // Obiekt wybranego loga
      logs: [], // Tablica zawierająca dane logów z API

      ediImports: [], // Tablica zawierająca wszystkie importy z API

      column: "createdAt", // Nazwa filtrowanej kolumny
      columnOrder: "desc", // Wartość filtrowania

      page: 1, // Obecna strona
      lastPage: 1, // Ostatnia strona

      filter_name: "", // Filtr: Nazwa
      filter_ediImport: null, // Filtr: Operacja
      filter_type: "", // Filtr: Typ
      filter_recordsPerPage: 10, // Filtr: Ilość rekordów na stronę

      isLoading: false // Wartość informująca o trwającym procesie ładowania
    }
  },
  computed: {
    ...mapState(["isDarkMode", "color"])
  },
  watch: {
    filter_name(newSearch) {
      this.filter_name = newSearch
      this.getLogs(1)
    }
  },
  created() {
    this.getMe()
  },
  methods: {
    // Funkcja pobierająca dane zalogowanego użytkownika
    getMe() {
      UserDataService.me()
      .then(res => {
        this.getUser(res.data.id)
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja pobierająca dane zalogowanego użytkownika
    getUser(id) {
      UserDataService.get(id)
      .then(res => {
        if (res.data.deleted) {
          this.$logout()
        }
        else {
          this.me = res.data
          this.getLogs(this.page)
          this.getEdiImports()
        }
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja pobierająca wybrany log z API
    getLog(id) {
      EdiLogDataService.get(id)
      .then(res => {
        this.log = res.data
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja pobierająca logi z API
    getLogs(page) {
      this.isLoading = true
      this.logs = []

      EdiLogDataService.getAll(`?page=${ page }&itemsPerPage=${ this.filter_recordsPerPage }&order[${ this.column }]=${ this.columnOrder }&name=${ this.filter_name }${ this.filter_ediImport ? "&ediImport.id=" + this.filter_ediImport.id : "" }${ this.filter_type ? "&type=" + this.filter_type : "" }`)
      .then(res => {
        this.page = page
        this.lastPage = Math.ceil(res.data["hydra:totalItems"] / this.filter_recordsPerPage)
        this.logs = res.data["hydra:member"]
        this.isLoading = false
      })
      .catch(error => {
        console.log(error)
        this.isLoading = false

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja pobierająca wszystkie operacje z API
    getEdiImports() {
      ImportDataService.getAll("")
      .then(res => {
        this.ediImports = res.data["hydra:member"]
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja exportująca logi do pliku
    exportLogs() {
      EdiLogDataService.export(`?ediId=${ this.filter_ediImport.id }`)
      .then(res => {
        const url = window.URL.createObjectURL(new Blob([res.data]))
        const link = document.createElement("a")
        link.href = url
        link.setAttribute("download", "logi.xlsx")
        document.body.appendChild(link)
        link.click()
        link.remove()
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja przypisująca wybraną nazwę do zmiennej
    selectItem(item) {
      this.filter_name = item
      this.getLogs(1)
    },

    // Funkcja przypisująca do zmiennej wybraną ilość rekordów na stronę
    onChangeRecordsPerPage(e) {
      this.filter_recordsPerPage = e.target.value
      this.getLogs(1)
    },

    // Funkcja przenosząca na następną stronę
    onNextPage(page) {
      if(page > this.lastPage) {
        page = 1
        this.getLogs(page)
      }
      else {
        this.getLogs(page)
      }
    },

    // Funkcja przenosząca na poprzednią stronę
    onPrevPage(page) {
      if(page < 1) {
        page = this.lastPage
        this.getLogs(page)
      }
      else {
        this.getLogs(page)
      }
    },

    // Funkcja sortująca kolumny w tabeli
    onOrder(column) {
      this.column = column
      if (this.columnOrder === "asc") {
        this.columnOrder = "desc"
        this.getLogs(this.page)
      }
      else {
        this.columnOrder = "asc"
        this.getLogs(this.page)
      }
    }
  }
}
</script>

<style scoped>
* {
  opacity: 1 !important;
}

.simple-typeahead {
  color: #000;
}

.loader {
  border: 2px solid #f3f3f3;
  border-radius: 50%;
  border-top: 2px solid #3498db;
  width: 18px;
  height: 18px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>