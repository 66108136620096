<template>
  <!-- Modal -->
  <div id="modal" class="modal show" tabindex="-1" role="dialog">
    <!-- Modal: Potwierdzenie usunięcia -->
    <div v-if="modals[0]" class="modal-dialog">
      <div class="modal-content">
        <div class="card px-2 py-1">
          <div class="card-header pb-1" :class="isDarkMode ? 'bg-black text-light' : 'bg-white text-dark'">
            <div class="d-flex align-items-center justify-content-between">
              <h5 class="my-auto">Potwierdzenie usunięcia</h5>
              <span class="my-auto">
                <i class="material-symbols-outlined text-lg cursor-pointer" @click="closeModal()">close</i>
              </span>
            </div>
          </div>
          <div class="card-body pt-1 text-sm" :class="isDarkMode ? 'bg-black' : 'bg-white'">
            Czy na pewno chcesz usunąć zdjęcie?
           
            <div class="d-flex justify-content-end mt-3">
              <material-button
                :color="color"
                variant="gradient"
                @click="deleteImage(deletedImage)"
              >
                Potwierdź
              </material-button>

              <material-button
                class="ms-3"
                color="light"
                variant="gradient"
                @click="closeModal()"
              >
                Anuluj
              </material-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-if="me" class="container-fluid">
    <!-- Tabela: Zdjęcia -->
    <div class="row">
      <div class="mb-4" :class="image ? 'col-md-8' : 'col-12'">
        <div class="card">
          <div class="card-header pb-1">
            <h5>Zdjęcia</h5>
          </div>
          <div class="card-body p-0">
            <div class="table-responsive pt-1">
              <div class="dataTable-wrapper dataTable-loading no-footer searchable fixed-height fixed-columns">
                <!-- Wyszukiwarka -->
                <div class="dataTable-top mb-3 py-0">
                  <div class="dataTable-search">
                    <input
                      v-model="filter_image"
                      placeholder="Wyszukaj zdjęcie..."
                      class="text-sm me-2 px-3 py-2 border-secondary"
                      :class="isDarkMode ? 'text-white' : 'text-dark'"
                      style="background: none; border: 1px solid; border-radius: 10px !important; outline: none"
                    >
                  </div>

                  <div class="dataTable-search d-flex align-items-center">
                    <SimpleTypeahead
                      ref="productRef"
                      v-model="filter_product"
                      placeholder="Wyszukaj produkt..."
                      :items="products"
                      :min-input-length="1"
                      class="text-sm me-2 px-3 py-2 border-secondary"
                      :class="isDarkMode ? 'text-white' : 'text-dark'"
                      style="background: none; border: 1px solid; border-radius: 10px !important; outline: none"
                      @select-item="selectProduct"
                    ></SimpleTypeahead>

                    <SimpleTypeahead
                      ref="colorRef"
                      v-model="filter_color"
                      placeholder="Wyszukaj kolor..."
                      :items="colors"
                      :min-input-length="1"
                      class="text-sm px-3 py-2 border-secondary"
                      :class="isDarkMode ? 'text-white' : 'text-dark'"
                      style="background: none; border: 1px solid; border-radius: 10px !important; outline: none"
                      @select-item="selectColor"
                    ></SimpleTypeahead>
                  </div>
                </div>
                <div class="dataTable-container">
                  <table class="table table-flush dataTable-table">
                    <thead class="thead-light">
                      <!-- Kolumny -->
                      <tr>
                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder" style="width: 5%">
                          <a href="#" class="dataTable-sorter" @click="onOrder('id')">ID</a>
                        </th>

                        <th class="ps-0 text-uppercase text-secondary text-xxs font-weight-bolder" style="width: 40%">
                          <a href="#" class="dataTable-sorter" @click="onOrder('fileName')">Nazwa</a>
                        </th>

                        <th class="ps-0 text-uppercase text-secondary text-xxs font-weight-bolder" style="width: 35%">
                          <a href="#" class="dataTable-sorter" @click="onOrder('externalUrl')">External URL</a>
                        </th>

                        <th class="ps-0 text-uppercase text-secondary text-xxs font-weight-bolder" style="width: 15%">
                          <a href="#" class="dataTable-sorter" @click="onOrder('createdAt')">Data dodania</a>
                        </th>

                        <th class="ps-0 text-uppercase text-secondary text-xxs font-weight-bolder" style="width: 5%"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="item in images"
                        :key="item"
                        :class="image && image.id === item.id ? 'bg-light' : ''"
                      >
                        <!-- ID -->
                        <td class="cursor-pointer" @click="image && image.id === item.id ? image = null : getImage(item.id)">
                          <p class="m-0 text-xs">#{{ item.id }}</p>
                        </td>

                        <!-- Nazwa -->
                        <td class="ps-0 cursor-pointer" @click="image && image.id === item.id ? image = null : getImage(item.id)">
                          <p class="m-0 text-xs">
                            <img :src="'data:image/jpeg;base64,' + item.fileBlob" width="32" class="me-2">
                            {{ item.fileName ? item.fileName : "Brak" }}
                          </p>
                        </td>

                        <!-- External URL -->
                        <td class="ps-0 cursor-pointer" @click="image && image.id === item.id ? image = null : getImage(item.id)">
                          <p v-if="item.externalUrl" class="m-0 text-xs">
                            <a :href="item.externalUrl" target="_blank">{{ item.externalUrl }}</a>
                          </p>
                          <p v-else class="m-0 text-xs">Brak</p>
                        </td>

                        <!-- Data dodania -->
                        <td class="ps-0 cursor-pointer" @click="image && image.id === item.id ? image = null : getImage(item.id)">
                          <p class="m-0 text-xs text-capitalize">{{ item.createdAt }}</p>
                        </td>

                        <!-- Menu -->
                        <td class="text-xs text-end">
                          <a
                            :class="[color]"
                            data-bs-toggle="dropdown"
                          >
                            <i class="material-icons cursor-pointer">more_vert</i>
                          </a>
                          <ul class="p-2 dropdown-menu dropdown-menu-end me-sm-n0 z-index-3 shadow">
                            <li class="cursor-pointer" @click="getImage(item.id)">
                              <a class="px-2 dropdown-item border-radius-md">
                                <div class="mx-0 px-0 py-1 d-flex">
                                  <div class="d-flex flex-column justify-content-center">
                                    <h6 class="my-auto text-sm font-weight-normal">
                                      Podgląd
                                    </h6>
                                  </div>
                                </div>
                              </a>
                            </li>
                            <hr class="horizontal my-1 bg-light">
                            <li class="cursor-pointer" @click="getImage(item.id, 'edit')">
                              <a class="px-2 dropdown-item border-radius-md">
                                <div class="mx-0 px-0 py-1 d-flex">
                                  <div class="d-flex flex-column justify-content-center">
                                    <h6 class="my-auto text-sm font-weight-normal">
                                      Edycja
                                    </h6>
                                  </div>
                                </div>
                              </a>
                            </li>
                            <hr class="horizontal my-1 bg-light">
                            <li class="cursor-pointer" @click="deletedImage = item.id; openModal(0)">
                              <a class="px-2 dropdown-item border-radius-md">
                                <div class="mx-0 px-0 py-1 d-flex">
                                  <div class="d-flex flex-column justify-content-center">
                                    <h6 class="my-auto text-sm font-weight-normal">
                                      Usuń
                                    </h6>
                                  </div>
                                </div>
                              </a>
                            </li>
                          </ul>
                        </td>
                      </tr>

                      <!-- Brak zdjęć -->
                      <tr :class="images.length > 0 ? 'd-none' : ''">
                        <td class="dataTables-empty text-sm" colspan="5">Nie znaleziono zdjęć</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <!-- Paginacja -->
                <div v-if="images.length > 0" class="dataTable-bottom pb-3">
                  <div class="dataTable-dropdown">
                    <label>
                      <select
                        class="dataTable-selector text-sm p-2 border-secondary me-2"
                        style="background: none; border: 1px solid; border-radius: 10px !important; outline: none"
                        :class="isDarkMode ? 'text-white' : 'text-dark'"
                        @change="onChangeRecordsPerPage($event)"
                      >
                        <option value="10" selected>10</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="1000000000">Wszystkie</option>
                      </select>
                      pozycji na stronę
                    </label>
                  </div>

                  <div class="dataTable-pagination">
                    <ul class="dataTable-pagination-list">
                      <li v-if="page !== 1">
                        <a href="#" @click="onPrevPage(1)">‹‹</a>
                      </li>
                      <li v-if="page !== 1">
                        <a href="#" @click="onPrevPage(page - 1)">‹</a>
                      </li>
                      <li v-if="page !== 1">
                        <a href="#" @click="onPrevPage(page - 1)">
                          {{ page - 1 }}
                        </a>
                      </li>
                      <li class="active">
                        <a href="#" :style="isDarkMode ? 'background: #f0f2f5 !important; color: #344767 !important' : 'background: #344767 !important; color: #f0f2f5 !important'">
                          {{ page }}
                        </a>
                      </li>
                      <li v-if="page !== lastPage">
                        <a href="#" @click="onNextPage(page + 1)">
                          {{ page + 1 }}
                        </a>
                      </li>
                      <li v-if="page !== lastPage">
                        <a href="#" @click="onNextPage(page + 1)">›</a>
                      </li>
                      <li v-if="page !== lastPage">
                        <a href="#" @click="onNextPage(lastPage)">››</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Edycja zdjęcia -->
      <div v-if="image && image.operation === 'edit'" class="col-md-4">
        <div class="card position-sticky top-1 overflow-auto">
          <div class="card-header pb-1">
            <h5>Edycja zdjęcia</h5>
          </div>
          <div class="card-body pt-1 pb-0">
            <!-- div class="row mb-3">
              <div class="col-12">
                <label class="form-label ms-0 mb-0 text-xs">Zdjęcie</label>
                <label
                  class="w-100 m-0 px-0 py-2 text-sm cursor-pointer d-flex align-items-center justify-content-between"
                  :class="isDarkMode ? 'text-white' : 'text-dark'"
                  style="background: none; border: 0; border-bottom: 1px solid black; outline: none"
                >
                  <span>{{ image.name ? image.name : image.imageFile ? image.imageFile.name : "Wybierz zdjęcie..." }}</span>
                  <input type="file" class="d-none" accept="image/*" @change="image.imageFile = $event.target.files[0]">
                  <i class="material-symbols-outlined text-lg">upload</i>
                </label>
              </div>
            </div -->

            <div class="row mb-3">
              <div class="col-12">
                <div class="input-group input-group-static">
                  <label>External URL</label>
                  <input
                    v-model="image.externalUrl"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                    placeholder="https://url/"
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <div class="input-group input-group-static">
                  <label class="d-flex align-items-center mb-1">
                    Produkt
                    <span v-if="image.product" class="ms-2 d-flex align-items-center text-xxs">
                      ({{ image.product.name }})
                    </span>
                  </label>
                  <SimpleTypeahead
                    ref="product"
                    placeholder="Wyszukaj..."
                    :items="products"
                    :min-input-length="1"
                    class="w-100 text-sm border-secondary"
                    style="padding-bottom: 10px; background: none; border: 0; border-bottom: 1px solid white; outline: none"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    @select-item="getProduct"
                  ></SimpleTypeahead>
                </div>
              </div>
            </div>

            <div class="row my-3">
              <div class="col-12 d-flex justify-content-end">
                <material-button
                  :color="color"
                  variant="gradient"
                  @click="patchImage(image.id)"
                >
                  Zapisz
                </material-button>

                <material-button
                  class="ms-3"
                  color="light"
                  variant="gradient"
                  @click="image = null"
                >
                  Anuluj
                </material-button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Podgląd zdjęcia -->
      <div v-if="image && image.operation === 'overview'" class="col-md-4">
        <div class="card position-sticky top-1 overflow-auto">
          <div class="card-header pb-1">
            <img :src="'data:image/jpeg;base64,' + image.fileBlob" class="w-100">
          </div>
          <div class="card-body pt-1 pb-0">
            <div class="row mb-1">
              <div class="col-12">
                <label class="form-label ms-0 mb-0 font-weight-bold">
                  External ID: <span class="font-weight-normal ms-2">{{ image.externalId ? image.externalId : "Brak" }}</span>
                </label>
              </div>
            </div>

            <div class="row mb-1">
              <div class="col-12">
                <label class="form-label ms-0 mb-0 font-weight-bold">
                  Grupa: <span class="font-weight-normal ms-2">{{ image.group ? image.group.name : "Brak" }}</span>
                </label>
              </div>
            </div>

            <div class="row mb-1">
              <div class="col-12">
                <label class="form-label ms-0 mb-0 font-weight-bold">
                  Produkt: <span class="font-weight-normal ms-2 cursor-pointer" @click="$store.state.product = image.product.id; $redirect('Produkty')">{{ image.product ? image.product.name : "Brak" }}</span>
                </label>
              </div>
            </div>

            <div class="row mb-1">
              <div class="col-12">
                <label class="form-label ms-0 mb-0 font-weight-bold">
                  External URL: <span class="font-weight-normal ms-2">{{ image.externalUrl ? image.externalUrl : "Brak" }}</span>
                </label>
              </div>
            </div>

            <div class="row mb-1">
              <div class="col-12">
                <label class="form-label ms-0 mb-0 font-weight-bold">
                  Kolory: <span class="font-weight-normal ms-2">{{ image.color ? image.color : "Brak" }}</span>
                </label>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <label class="form-label ms-0 mb-0 font-weight-bold">
                  Data stworzenia: <span class="font-weight-normal ms-2">{{ image.createdAt ? image.createdAt : "Brak" }}</span>
                </label>
              </div>
            </div>

            <div class="row my-3">
              <div class="col-12 d-flex justify-content-end">
                <material-button
                  :color="color"
                  variant="gradient"
                  @click="getImage(image.id, 'edit')"
                >
                  Edycja
                </material-button>

                <material-button
                  class="ms-3"
                  color="light"
                  variant="gradient"
                  @click="image = null"
                >
                  Anuluj
                </material-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MaterialButton from "@/components/MaterialButton.vue"
import UserDataService from "@/services/UserDataService"
import ImageDataService from "@/services/ImageDataService"
import ProductDataService from "@/services/ProductDataService"
import SimpleTypeahead from "vue3-simple-typeahead"
import { mapState } from "vuex"

export default {
  name: "Images",
  components: {
    MaterialButton,
    SimpleTypeahead,
  },
  data() {
    return {
      me: null, // Obiekt zalogowanego użytkownika

      image: null, // Obiekt zawierający wybrane zdjęcie

      deletedImage: null, // Obiekt usuwanego zdjęcia

      images: [], // Tablica zawierająca dane zdjęć z API
      imagesAC: [], // Tablica zawierająca nazwy zdjęć z API

      modals: [], // Tablica obecnych modali

      products: [], // Tablica zawierająca nazwy produktów z API
      colors: [], // Tablica zawierająca kolory z API

      column: "id", // Nazwa filtrowanej kolumny
      columnOrder: "asc", // Wartość filtrowania

      page: 1, // Obecna strona
      lastPage: 1, // Ostatnia strona

      filter_image: "", // Filtr: Zdjęcie
      filter_product: "", // Filtr: Produkt
      filter_color: "", // Filtr: Kolor
      filter_recordsPerPage: 10, // Filtr: Ilość rekordów na stronę
    }
  },
  computed: {
    ...mapState(["isDarkMode", "color"])
  },
  watch: {
    filter_image(newSearch) {
      this.filter_image = newSearch
      this.getImages(1)
    },

    filter_product(newSearch) {
      this.filter_product = newSearch
      this.getImages(1)
    },

    filter_color(newSearch) {
      this.filter_color = newSearch
      this.getImages(1)
    }
  },
  created() {
    this.getMe()
  },
  methods: {
    // Funkcja pobierająca dane zalogowanego użytkownika
    getMe() {
      UserDataService.me()
      .then(res => {
        this.getUser(res.data.id)
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja pobierająca dane zalogowanego użytkownika
    getUser(id) {
      UserDataService.get(id)
      .then(res => {
        if (res.data.deleted) {
          this.$logout()
        }
        else {
          this.me = res.data
          this.getImages(this.page)
          this.getProducts()

          if (this.$store.state.image) {
            this.getImage(this.$store.state.image)
            this.$store.state.image = null
          }
        }
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    testfile(e) {
      console.log(e)
    },

    // Funkcja pobierająca wybrane zdjęcie z API
    getImage(id, operation = "overview") {
      ImageDataService.get(id)
      .then(res => {
        this.image = res.data
        this.image.operation = operation
        console.log(this.image)
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja aktualizująca wybrane zdjęcie
    patchImage(id) {
      ImageDataService.patch(id,
        {
          externalUrl: this.image.externalUrl,
          product: this.image.product ? `/products/${ this.image.product.id }` : null,
        },
        {
          headers: { "Content-Type": "application/merge-patch+json" }
        }
      )
      .then(res => {
        console.log(res.data)
        this.getImages(this.page)
        this.image = null
      })
    },

    // Funkcja pobierająca wybrany produkt z API
    getProduct(name) {
      ProductDataService.getAll(`?name=${ name }`)
      .then(res => {
        this.image.product = null

        res.data["hydra:member"].forEach(item => {
          if (item.name === name) {
            this.image.product = item
          }
        })

        this.$refs.product.clearInput()
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja usuwająca wybrane zdjęcie
    deleteImage(id) {
      ImageDataService.delete(id)
      .then(res => {
        console.log(res.data)
        this.getImages(1)
        this.deletedImage = null
        this.closeModal()
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja pobierająca zdjęcia z API
    getImages(page) {
      let image = this.filter_image ? `&id=${ this.filter_image }` : ""
      let product = this.filter_product ? `&product.externalId=${ this.filter_product }` : ""
      let color = this.filter_color ? `&color=${ this.filter_color }` : ""

      ImageDataService.getAll(`?page=${ page }&itemsPerPage=${ this.filter_recordsPerPage }&order[${ this.column }]=${ this.columnOrder }${ image }${ product }${ color }`)
      .then(res => {
        this.page = page
        this.lastPage = Math.ceil(res.data["hydra:totalItems"] / this.filter_recordsPerPage)
        this.images = res.data["hydra:member"]
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja pobierająca nazwy zdjęć z API
    getProducts() {
      ProductDataService.getAll("")
      .then(res => {
        res.data["hydra:member"].forEach(item => {
          this.products.push(item.externalId)

          item.productFeatureValues.forEach(productFeatureValue => {
            let exist = false

            this.colors.forEach(color => {
              if (productFeatureValue.value === color) {
                exist = true
              }
            })

            if (!exist) {
              this.colors.push(productFeatureValue.value)
            }
          })
        })
      })
      .catch(error => {
        console.log(error)
      })
    },

    // Funkcja przypisująca wybraną nazwę do zmiennej
    selectProduct(item) {
      this.filter_product = item
      this.getImages(1)
    },

    // Funkcja przypisująca wybraną nazwę do zmiennej
    selectColor(item) {
      this.filter_color = item
      this.getImages(1)
    },

    // Funkcja przypisująca do zmiennej wybraną ilość rekordów na stronę
    onChangeRecordsPerPage(e) {
      this.filter_recordsPerPage = e.target.value
      this.getImages(this.page)
    },

    // Funkcja przenosząca na następną stronę
    onNextPage(page) {
      if(page > this.lastPage) {
        page = 1
        this.getImages(page)
      }
      else {
        this.getImages(page)
      }
    },

    // Funkcja przenosząca na poprzednią stronę
    onPrevPage(page) {
      if(page < 1) {
        page = this.lastPage
        this.getImages(page)
      }
      else {
        this.getImages(page)
      }
    },

    // Funkcja sortująca kolumny w tabeli
    onOrder(column) {
      this.column = column
      if (this.columnOrder === "asc") {
        this.columnOrder = "desc"
        this.getImages(this.page)
      }
      else {
        this.columnOrder = "asc"
        this.getImages(this.page)
      }
    },

    // Funkcja otwierająca okno
    openModal(x) {
      for (let i = 0; i < this.modals.length; i++) {
        this.modals[i] = false
      }

      document.getElementById("modal").classList.add("d-block")
      this.modals[x] = true
    },

    // Funkcja zamykająca okno
    closeModal() {
      document.getElementById("modal").classList.remove("d-block")
      for (let i = 0; i < this.modals.length; i++) {
        this.modals[i] = false
      }
    },
  }
}
</script>

<style scoped>
* {
  opacity: 1 !important;
}

.modal {
  background: rgb(0, 0, 0, 0.5);
  z-index: 10000 !important;
}

.modal-content {
  background: none;
  border: none;
}

.simple-typeahead {
  color: #000;
}
</style>