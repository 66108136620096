import http from "@/http-common"

class AppSettingsDataService {
    getAll(url) {
        return http.get(`app_settings${ url }`)
    }

    get(id) {
        return http.get(`app_settings/${ id }`)
    }

    post(data) {
        return http.post("app_settings", data)
    }

    patch(id, data, headers) {
        return http.patch(`app_settings/${ id }`, data, headers)
    }

    delete(id) {
        return http.delete(`app_settings/${ id }`)
    }
}

export default new AppSettingsDataService()