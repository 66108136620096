import http from "@/http-common"

class SettingsDataService {
    getAll(url) {
        return http.get(`settings${ url }`)
    }

    get(id) {
        return http.get(`settings/${ id }`)
    }

    post(data) {
        return http.post("settings", data)
    }

    patch(id, data, headers) {
        return http.patch(`settings/${ id }`, data, headers)
    }
}

export default new SettingsDataService()