import http from "@/http-common"

class UserDataService {
    me() {
        return http.get("me")
    }

    getAll(url) {
        return http.get(`users${ url }`)
    }

    get(id) {
        return http.get(`users/${ id }`)
    }

    post(data) {
        return http.post("users", data)
    }

    forgot_password(data, headers) {
        return http.post("forgot_password", data, headers)
    }

    patch(id, data, headers) {
        return http.patch(`users/${ id }`, data, headers)
    }

    delete(id) {
        return http.delete(`users/${ id }`)
    }
}

export default new UserDataService()