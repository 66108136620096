import http from "@/http-common"

class EdiLogDataService {
    getAll(url) {
        return http.get(`edi_logs${ url }`)
    }

    export(url) {
        return http.get(`edi_logs/export${ url }`,  { responseType: "blob" })
    }

    get(id) {
        return http.get(`edi_logs/${ id }`)
    }

    post(data) {
        return http.post("edi_logs", data)
    }

    patch(id, data, headers) {
        return http.patch(`edi_logs/${ id }`, data, headers)
    }

    delete(id) {
        return http.delete(`edi_logs/${ id }`)
    }
}

export default new EdiLogDataService()