<template>
  <li class="nav-item active ps-3">
    <router-link class="nav-link py-2" style="box-shadow: none" :to="to">
      <span class="sidenav-mini-icon text-white">
        <i class="material-symbols-outlined mt-1 fixed-plugin-button-nav cursor-pointer">
          {{ miniIcon }} 
        </i>
      </span>
      <span class="sidenav-normal me-3 ms-2 ps-1 text-white font-weight-normal">
        {{ text }}
      </span>
    </router-link>
  </li>
</template>
<script>
export default {
  name: "SidenavItem",
  props: {
    to: {
      type: [Object, String],
      required: true
    },
    miniIcon: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    }
  }
}
</script>