<template>
  <div v-if="me" class="container-fluid">
    <div class="d-flex mb-4">
      <!-- Przycisk: Powrót -->
      <material-button
        :color="color"
        variant="gradient"
        @click="$redirect('Grupy')"
      >
        <i class="material-icons me-2">keyboard_backspace</i>Powrót
      </material-button>
    </div>
    
    <div class="row">
      <div class="col-lg-3">
        <div class="card position-sticky top-1 mb-4">
          <ul class="nav flex-column bg-white border-radius-lg p-3">
            <li class="nav-item">
              <a class="nav-link text-dark d-flex align-items-center" data-scroll href="#info" :class="isDarkMode ? 'text-white' : 'text-dark'">
                <i class="material-symbols-outlined text-lg me-2">receipt</i>
                <span class="text-sm">Informacje ogólne</span>
              </a>
            </li>
            <li class="nav-item pt-2">
              <a class="nav-link text-dark d-flex align-items-center" data-scroll href="#members" :class="isDarkMode ? 'text-white' : 'text-dark'">
                <i class="material-symbols-outlined text-lg me-2">person</i>
                <span class="text-sm">Przypisani użytkownicy</span>
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div class="col-lg-9">
        <!-- Informacje ogólne -->
        <div id="info" class="card mb-4">
          <div class="card-header pb-1">
            <h5>Informacje ogólne</h5>
          </div>
          <div class="card-body pt-1">
            <div class="row">
              <div class="col-md-6">
                <div class="input-group input-group-static">
                  <label class="d-flex">
                    Nazwa grupy
                  </label>
                  <input
                    v-model="group.name"
                    class="form-control"
                    :class="isDarkMode ? 'text-white' : 'text-dark'"
                    style="border: 1px"
                    placeholder="Biuro"
                  />
                </div>
              </div>
            </div>
          </div>

          <p v-if="error !== ''" class="w-100 text-end px-4 text-sm font-weight-bold text-danger">{{ error }}</p>
        </div>

        <!-- Przypisani użytkownicy -->
        <div id="members" class="card mb-4 z-index-2">
          <div class="card-header pb-1">
            <h5>Przypisani użytkownicy</h5>
            <p class="mb-0 text-sm">
              Wybierz użytkowników, którzy mają mieć dostęp do tej grupy.
            </p>
          </div>
          <div class="card-body pt-1">
            <div v-if="group.members.length === 0" class="mt-2 text-sm">Brak podłączonych użytkowników</div>
            <div v-for="(item, index) in group.members" v-else :key="item" class="d-flex align-items-center mt-2">
              <i class="material-symbols-outlined me-2">person</i>
              <span class="text-sm">{{ item.fullName }}</span>
              <span
                class="text-danger text-sm icon-move-right my-auto ms-auto me-3 cursor-pointer"
                @click="users.push(item); usersAC.push(item.fullName); group.members.splice(index, 1)"
              >
                Odłącz
              </span>
            </div>

            <div class="row col-md-6 mt-4">
              <label class="form-label ms-0">Podłącz użytkownika</label>
              <SimpleTypeahead
                ref="usersAC"
                placeholder="Wybierz użytkownika z listy..."
                :items="usersAC"
                :min-input-length="0"
                class="text-sm pb-2"
                :class="isDarkMode ? 'text-white' : 'text-dark'"
                style="width: 100%; background: none; border: none; outline: none; border-bottom: 1px solid #eeeeee"
                @select-item="selectItem"
              >
              </SimpleTypeahead>
            </div>
          </div>
        </div>

        <div class="d-flex justify-content-end">
          <material-button
            :color="color"
            variant="gradient"
            @click="validation()"
          >
            Dodaj
          </material-button>

          <material-button
            class="ms-3"
            :color="color"
            variant="gradient"
            @click="addAnother = true; validation()"
          >
            Dodaj i utwórz kolejną
          </material-button>

          <material-button
            class="ms-3"
            color="light"
            variant="gradient"
            @click="$redirect('Grupy')"
          >
            Anuluj
          </material-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MaterialButton from "@/components/MaterialButton.vue"
import SimpleTypeahead from "vue3-simple-typeahead"
import UserDataService from "@/services/UserDataService"
import UserGroupDataService from "@/services/UserGroupDataService"
import { mapState } from "vuex"

export default {
  name: "NewGroup",
  components: {
    MaterialButton,
    SimpleTypeahead,
  },
  data() {
    return {
      me: null, // Obiekt zalogowanego użytkownika

      group: {
        name: "",
        members: [],
      }, // Obiekt tworzonej grupy

      users: [], // Lista wszystkich użytkowników
      usersAC: [], // Lista imion i nazwisk wszystkich użytkowników

      error: "", // Zmienna odpowiedzialna za błąd danych

      addAnother: false, // Zmienna sprawdzająca czy dodać kolejne konto
    }
  },
  computed: {
    ...mapState(["isDarkMode", "color"])
  },
  created() {
    this.getMe()
  },
  methods: {
    // Funkcja sprawdzająca czy użytkownik ma uprawnienia
    getMe() {
      UserDataService.me()
      .then(res => {
        this.getUser(res.data.id)
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja sprwadzająca status użytkownika
    getUser(id) {
      UserDataService.get(id)
      .then(res => {
        if (res.data.deleted || res.data.status === "Dezaktywowany") {
          this.$logout()
        }
        else {
          this.me = res.data
          this.getUsers()
        }
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja sprawdzająca poprawność wpisanych danych
    validation() {
      let success = true
      this.error = ""

      if (this.group.name === "") {
        success = false
        this.error = "Nazwa grupy nie może być pusta!"
      }

      if (success) {
        this.postGroup()
      }
    },
    
    // Funkcja dodająca nową grupę do bazy danych
    postGroup() {
      let members = []
      this.group.members.forEach(item => {
        members.push(`/users/${ item.id }`)
      })

      UserGroupDataService.post(
        {
          name: this.group.name,
          members: members,
        }
      )
      .then(res => {
        console.log(res.data)
        if(!this.addAnother) {
          this.$redirect("Grupy")
        }
        else {
          this.$router.go(0)
        }
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.status) == 422) {
          this.error = "Nazwa grupy jest już zajęta!"
        }
      })
    },

    // Funkcja pobierająca wszystkich użytkowników z API
    getUsers() {
      UserDataService.getAll("?deleted=false")
      .then(res => {
        res.data["hydra:member"].forEach(item => {
          let add = true

          if (item.group) {
            add = false
          }

          if (add) {
            this.users.push(item)
            this.usersAC.push(item.fullName)
          }
        })
      })
      .catch(error => {
        console.log(error)

        if(JSON.stringify(error.response.data.code) == 401) {
          this.$logout()
        }
      })
    },

    // Funkcja pobierająca dane o użytkowniku z listy rozwijanej
    selectItem(user) {
      this.users.forEach((item, index) => {
        if(item.fullName === user) {
          this.group.members.push(item)
          this.users.splice(index, 1)
          this.usersAC.splice(index, 1)
        }
      })

      this.$refs.usersAC.clearInput()
    }
  }
}
</script>

<style scoped>
* {
  opacity: 1 !important;
}

select {
  color: #7b809a;
  background: none;
  border: none;
  outline: none;
  border-bottom: 1px solid #eeeeee;
}

.simple-typeahead {
  color: #000;
}
</style>